import root from 'window-or-global';
import {generateRoutePath} from '@epic-core/common';
import {FeedItemProps} from '../types';
import {AltLocPageData, getMessageFnType} from 'epic-ue-shared';
import {MultiCardOptionsArray} from 'epic-ue-ui';

export function getImageUrl(item: FeedItemProps): string {
    return item.imageUrl || (item as any).article?.feedImg || (item as any)._images?.[0]; // support legacy feed props
}

interface ParsedUrl {
    query: {
        page?: number | string;
    };
    pathname: string;
}

export function getPageFromParsedUrl(parsedUrl: ParsedUrl): number {
    const query = parsedUrl.query || {};
    return +(query.page || 1);
}

export function getAltLocPageDataCards(
    feedItem: AltLocPageData,
    pageSlug: string,
    getMessage: getMessageFnType
): MultiCardOptionsArray {
    const {altLocPageData} = feedItem;
    const altLocPageCards: MultiCardOptionsArray = [];
    if (!altLocPageData) return altLocPageCards;

    for (let i = 0; i < altLocPageData.length; i++) {
        const data = altLocPageData[i];
        if (!data) continue;

        const handleClick = (e) => {
            e.preventDefault();
            root.location.href = generateRoutePath(
                `${pageSlug}?lang=${data && data._locale}`,
                true
            );
        };

        altLocPageCards.push({
            text: {
                eyebrow: {
                    text: getMessage('epic.enterprise.landing.news.text')
                },
                title: data._title,
                link: {
                    href: generateRoutePath(`${pageSlug}?lang=${data._locale}`, true),
                    label: getMessage(`epic.error.alt.404.read.locale.${data._locale}`)
                },
                onClick: handleClick
            }
        });
    }
    return altLocPageCards;
}
