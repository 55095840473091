import styled, {css} from 'styled-components';

export const BaseButtonStyles = css`
    &.btn {
        text-decoration: none;
        line-height: 1;
        &:not(.btn-blank) {
            text-align: center;
            font-size: 1.1em;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: ${(p) => p.theme.ue.fonts.inter.regular};
            span {
                text-transform: uppercase;
                letter-spacing: 1px;
                font-family: ${(p) => p.theme.ue.fonts.inter.regular};
            }
            max-width: 450px;
            padding: 15px 35px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding: 10px 15px;
                max-width: 300px;
            }
            margin: 5px;
            border-radius: 2px;
            border: 2px solid #fff;
            transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
            ${(p) => p.theme.breakpoints.up('sm')} {
                &:not(.btn-showcase) {
                    min-width: 240px;
                }
            }
            @media (max-width: 850px) and (orientation: landscape) {
                &:not(.btn-showcase) {
                    min-width: 100px;
                    padding: 10px 15px;
                }
            }
        }
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        &:not([disabled]) {
            cursor: pointer;
        }
        &.btn-blank {
            background: transparent;
            border: 0;
            &:hover,
            &:focus,
            &:active {
                &:not([disabled]) {
                    color: ${(p) => p.theme.ue.colors.buttons.primary.text.hover};
                }
            }
        }
        &[disabled] {
            cursor: not-allowed;
        }
        &.btn-primary {
            background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
            border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.default};
            color: ${(p) => p.theme.ue.colors.buttons.primary.text.default};
            &:hover,
            &:focus,
            &:active {
                &:not([disabled]) {
                    background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
                    border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.hover};
                    color: ${(p) => p.theme.ue.colors.buttons.primary.text.hover};
                }
            }
            &[disabled] {
                color: ${(p) => p.theme.ue.colors.buttons.primary.text.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.disabled};
                background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.disabled};
            }
        }
        &.btn-secondary {
            background-color: ${(p) => p.theme.ue.colors.buttons.secondary.background.default};
            border-color: ${(p) => p.theme.ue.colors.buttons.secondary.border.default};
            color: ${(p) => p.theme.ue.colors.buttons.secondary.text.default};
            &:hover,
            &:active {
                &:not([disabled]) {
                    background-color: ${(p) =>
                        p.theme.ue.colors.buttons.secondary.background.hover};
                    border-color: ${(p) => p.theme.ue.colors.buttons.secondary.border.hover};
                    color: ${(p) => p.theme.ue.colors.buttons.secondary.text.hover};
                }
            }
            &[disabled] {
                color: ${(p) => p.theme.ue.colors.buttons.secondary.text.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.secondary.border.disabled};
                background-color: ${(p) => p.theme.ue.colors.buttons.secondary.background.disabled};
            }
        }
        &.btn-tertiary {
            background-color: ${(p) => p.theme.ue.colors.buttons.tertiary.background.default};
            border-color: ${(p) => p.theme.ue.colors.buttons.tertiary.border.default};
            color: ${(p) => p.theme.ue.colors.buttons.tertiary.text.default};
            &:hover,
            &:active {
                &:not([disabled]) {
                    background-color: ${(p) => p.theme.ue.colors.buttons.tertiary.background.hover};
                    border-color: ${(p) => p.theme.ue.colors.buttons.tertiary.border.hover};
                    color: ${(p) => p.theme.ue.colors.buttons.tertiary.text.hover};
                }
            }
            &[disabled] {
                background-color: ${(p) => p.theme.ue.colors.buttons.tertiary.background.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.tertiary.border.disabled};
                color: ${(p) => p.theme.ue.colors.buttons.tertiary.text.disabled};
            }
        }
        &.btn-black {
            background-color: ${(p) => p.theme.ue.colors.buttons.black.background.default};
            border-color: ${(p) => p.theme.ue.colors.buttons.black.border.default};
            color: ${(p) => p.theme.ue.colors.buttons.black.text.default};
            &:hover,
            &:active {
                &:not([disabled]) {
                    background-color: ${(p) => p.theme.ue.colors.buttons.black.background.hover};
                    border-color: ${(p) => p.theme.ue.colors.buttons.black.border.hover};
                    color: ${(p) => p.theme.ue.colors.buttons.black.text.hover};
                }
            }
            &[disabled] {
                background-color: ${(p) => p.theme.ue.colors.buttons.black.background.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.black.border.disabled};
                color: ${(p) => p.theme.ue.colors.buttons.black.text.disabled};
            }
        }
        &.btn-showcase:not([disabled]) {
            color: ${(p) => p.theme.palette.text.secondary};
            display: inline-block;
            margin: 0;
            cursor: pointer;
            padding: 10px 25px 10px 10px;
            position: relative;
            transition: color 0.2s ease, border-color 0.2s ease;
            background: none;
            text-transform: uppercase;
            border: 0;
            ${(p) => p.theme.breakpoints.down('sm')} {
                min-width: 100px;
                max-width: 180px;
                padding: 10px 15px 10px 10px;
                margin: 15px 0 0 0 !important;
            }
            &:hover,
            &:focus,
            &:active {
                color: ${(p) => p.theme.palette.text.primary};
                background: none;
                border: 0;
            }
            i {
                font-size: 1.2em;
                position: absolute;
                right: 0;
                top: 12px;
            }
            i.icon-play2 {
                top: 6px;
                right: -22px;
            }
        }
        i:not(.btn-showcase) {
            &:nth-child(1) {
                margin-right: 1em;
                float: left;
            }
            &:last-child {
                margin-left: 1em;
                float: right;
            }
        }
    }
`;

//div to be polymorphic
export const ButtonStyles = styled.div`
    ${BaseButtonStyles}
`;
