import styled from 'styled-components';

export const TagContainer = styled.div`
    border-radius: 99px;
    border: 1px solid rgba(255, 255, 255, 0.35);
    transition: background-color 0.2s ease-out;
    background-color: transparent;
    margin-bottom: 20px;
    height: 32px;
    color: white;
    max-width: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    text-transform: capitalize;

    &.has-link {
        cursor: pointer;
        transition: background-color 0.2s ease-out;
        &:hover {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }
`;

export const TagContent = styled.div`
    padding: 0 16px;
    display: flex;
    align-items: center;
    p,
    span {
        color: ${(p) =>
            p.theme.ifLight(p, 'theme.palette.text.primary', 'theme.palette.text.secondary')};
    }
`;
