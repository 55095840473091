import React from 'react';

import {FeedDetails, FeedDetailsInnerProperties} from '../components/feed/FeedDetails';
import {InitSharedValueResponse, AppSharedKeys, cmsApi, RouteMatchOrUrlParse} from 'epic-ue-shared';
import {getUrlPattern} from 'epic-ue-shared';
import {feedApi} from 'epic-ue-feed';

export const TechBlogPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="tech-blog" />;
};

export const SpotlightPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="spotlights" />;
};

export const DeveloperInterviewPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="developer-interviews" />;
};

export const feedPreloadSharedValues = async (
    urlPattern: string,
    preloadData: InitSharedValueResponse[]
): Promise<Array<InitSharedValueResponse>> => {
    const patternsToSkip = [urlPattern];

    const feedItems = (await feedApi.getFeedItems({patternsToSkip})) || {};
    preloadData.push({
        key: AppSharedKeys.FeedItemsStore,
        value: {
            ...feedItems,
            patternsToSkip
        }
    });

    const feedItem = (await feedApi.getFeedItem(urlPattern)) || {};
    preloadData.push({
        key: AppSharedKeys.FeedItemStore,
        value: {
            ...feedItem
        }
    });

    return preloadData;
};

const initialFeedPageSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const urlPattern = getUrlPattern(match);
    const pageData = await cmsApi.getPageData(urlPattern);
    delete pageData.pageSlugParam;
    delete pageData.cmsPageMap;

    const valid = !!(urlPattern && pageData && Object.keys(pageData).length !== 0);
    let altLocPageData;
    if (!valid) {
        altLocPageData = await cmsApi.getAltLocPageData(urlPattern);
    }

    const preloadData = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [urlPattern]: altLocPageData || pageData || {}
            }
        }
    ];

    return feedPreloadSharedValues(urlPattern, preloadData);
};

TechBlogPage.getInitialSharedValues = initialFeedPageSharedValues;
SpotlightPage.getInitialSharedValues = initialFeedPageSharedValues;
DeveloperInterviewPage.getInitialSharedValues = initialFeedPageSharedValues;
