import React, {useEffect, useState, useCallback} from 'react';
import {getHelmetForMetaTags, getHelmetStructuredData, NewsletterProps} from 'epic-ue-shared';
import {Page, Header, Sections, Interview, SimpleSocialSharing, Tag} from 'epic-ue-components';
import {Navigate} from 'react-router-dom';
import {generateRoutePath, getLocale} from '@epic-core/common';
import {SpotlightPageData, SpotlightBadge} from 'epic-ue-shared';
import {ArticleDetails} from '../components/ArticleDetails';
import root from 'window-or-global';
import {SpotlightWrapper, Lightbox, SpotlightPageContainer} from './SpotlightPage.styles';
import {getDataAttribute} from '@epic-core/common';
import {UeThemeProvider, ueDarkTheme, ueClassicLightTheme} from 'epic-ue-theme';
import {Helmet} from 'react-helmet';
import {UnrealLoading} from 'epic-ue-loading';
import {BlogTagFragment} from 'epic-ue-feed';
import {convertTagLabelToId} from 'epic-ue-shared';

const removeDups = (images: string[]) => {
    const unique = {};
    images.forEach((i) => {
        if (!unique[i]) {
            unique[i] = true;
        }
    });
    return Object.keys(unique);
};

const tagSort = (a, b) => {
    if (a.props.content < b.props.content) {
        return -1;
    }
    if (a.props.content > b.props.content) {
        return 1;
    }
    return 0;
};

interface Properties {
    pageData: SpotlightPageData;
    parentOrigin?: string;
    urlPattern: string;
    cmsLoading?: boolean;
    cmsLoadedEmpty?: boolean;
    disableTags?: boolean;
    disableContentTypeLabelClick?: boolean;
    publisherName?: string;
    publisherLogo?: string;
    feedItemTags: BlogTagFragment[];
}

export const SpotlightPage = ({
    urlPattern,
    pageData,
    cmsLoading,
    cmsLoadedEmpty,
    parentOrigin,
    disableTags,
    publisherName,
    publisherLogo,
    feedItemTags = []
}: Properties): JSX.Element => {
    const [imageList, setImageList] = useState<string[]>([]);
    const [activeImage, setActiveImage] = useState('');
    const [toggleLightbox, setToggleLightbox] = useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);

    const showLightbox = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
                const currentImage = getDataAttribute(e.currentTarget, 'image');
                setActiveImage(currentImage);
                setToggleLightbox(true);
            }
        },
        [activeImage, setActiveImage, setToggleLightbox]
    );

    const hideLightbox = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
                setActiveImage('');
                setToggleLightbox(!toggleLightbox);
            }
        },
        [setActiveImage, setToggleLightbox, toggleLightbox]
    );

    const arrowHandler = useCallback(
        (e) => {
            if (e && activeImage) {
                e.stopPropagation();
                const direction = getDataAttribute(e.currentTarget, 'direction');
                const imageListLength = imageList.length;
                const activeIndex = imageList.indexOf(activeImage);
                let current;

                if (direction === 'next') {
                    current = activeIndex + 1;
                    if (current >= imageListLength) current = 0;
                } else if (direction === 'prev') {
                    current = activeIndex - 1;
                    if (current < 0) current = imageListLength - 1;
                }

                const newImage = imageList[current];
                if (newImage) {
                    setActiveImage(newImage);
                }
            }
        },
        [activeImage, setActiveImage, imageList]
    );

    useEffect(() => {
        const imageArray: string[] = [];
        const mainId = root.document.getElementById('spotlight-article');

        if (mainId) {
            const sectionImages = mainId.querySelectorAll('.ue-lb-wrapper img');
            const bgImages = mainId.querySelectorAll('.ue-lb-wrapper .bgMedia[data-image]');

            //Gather all regular images in the various sections and add a specific data attr
            //Check for class "ue-lightbox-img-ignore" to ignore specific images
            if (sectionImages) {
                for (const imgEl of sectionImages) {
                    if (imgEl) {
                        const imageElem: string = imgEl.src || '';
                        const imageIgnoreClass = imgEl.classList.contains('ue-lightbox-img-ignore');
                        const noClickClass = imgEl.classList.contains('ue-lightbox-no-click');

                        if (!noClickClass) {
                            //Add timeout to make sure the page rendered currectly
                            setTimeout(() => {
                                imgEl.addEventListener('click', showLightbox);
                            }, 250);
                        }
                        if (!imageIgnoreClass) {
                            imgEl.setAttribute('data-image', imageElem);
                        }
                        if (imageElem) {
                            imageArray.push(imageElem);
                        }
                    }
                }
            }

            //Find all elements bgImg and attach click handler
            //check for class "ue-lightbox-no-click" to not pass down handler
            if (bgImages) {
                for (const bgEl of bgImages) {
                    if (bgEl) {
                        const imageElem = bgEl.dataset || '';
                        if (imageElem) {
                            const imageSource = imageElem.image;
                            const noClickClass = bgEl.classList.contains('ue-lightbox-no-click');
                            if (!noClickClass) {
                                bgEl.addEventListener('click', showLightbox);
                            }
                            if (imageSource) {
                                imageArray.push(imageSource);
                            }
                        }
                    }
                }
            }

            const uniqueImages: string[] = removeDups(imageArray);
            setImageList(uniqueImages);
        }

        setHasLoaded(true);

        return () => {
            const unmountImages = root.document.querySelectorAll('[data-image]');
            for (let i = 0; i < unmountImages.length; i++) {
                const el = unmountImages[i];
                el.removeEventListener('click', showLightbox);
            }
            setHasLoaded(false);
        };
    }, [setImageList, hasLoaded, setHasLoaded, pageData]);

    const header = pageData.header || {};
    const topSections = pageData.topSections || {};
    const topSectionsList = topSections.sections || [];
    const interview = pageData.interview || {};
    const bottomSections = pageData.bottomSections || {};
    const bottomSectionsList = bottomSections.sections || [];
    const article = pageData.article || {};
    const newsletterData: NewsletterProps = pageData.newsletterData || {};
    const showNewsletterCard = newsletterData.enable;
    const badge = pageData.badge || ({} as SpotlightBadge);
    const author = article.author || '';
    const short = article.short || '';
    const social = article.social || {};
    const tweet = social.tweet || short || '';
    const theme = pageData.theme;
    const themeToUse = theme === 'LIGHT' ? ueClassicLightTheme : ueDarkTheme;
    const locale = getLocale();

    let date: Date | string = '';
    if (typeof pageData._activeDate === 'string') {
        date = new Date(pageData._activeDate);
        if (date) {
            date = date.toLocaleDateString(locale, {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
        }
    }

    let tagList: (JSX.Element | undefined)[] = [<div key="related-news-items" />];
    if (feedItemTags && feedItemTags.length) {
        tagList = feedItemTags.map((tag: BlogTagFragment) => {
            if (tag && tag.tagId && tag.label) {
                return (
                    <Tag
                        key={`tag-${tag.tagId}`}
                        type="default"
                        content={tag.label || ''}
                        url={`/${locale}/feed?tags=${convertTagLabelToId(tag.tagId)}`}
                        rel="nofollow"
                    />
                );
            }
        });
    }

    let sortedTags: (JSX.Element | undefined)[] = [<div key="tagList" />];
    if (tagList) {
        sortedTags = tagList.sort(tagSort);
    }

    if (cmsLoading && parentOrigin !== 'feed') {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <Navigate to={generateRoutePath(`/error-404?e=${urlPattern}`)} />;
    }

    const type = header.type || 'default';

    let offsetHeight = '40vh';
    if (header.type === 'video') {
        offsetHeight = '85vh';
    }

    if (header.height && header.type !== 'video') {
        const hHeight = header.height;
        const heightText = hHeight.match(/\d/g);
        if (heightText) {
            const heightNum = heightText.join('');
            if (heightNum) {
                const value = parseInt(heightNum);
                const newOffset = value + 10;
                if (newOffset) {
                    offsetHeight = `${newOffset}vh`;
                }
            }
        }
    }

    const title = header.title || pageData._title || '';
    const structuredDataEl = getHelmetStructuredData({
        title,
        description: article.short,
        images: article.feedImg ? [article.feedImg] : pageData._images_,
        author,
        dateModified: pageData.lastModified,
        datePublished: pageData._activeDate,
        publisherName,
        publisherLogo
    });

    const metaDescription = article.metaTagDesc || article.short || '';
    const metaImage = article.shareImage || article.feedImg || '';

    const spotlightTags: {[key: string]: string}[] = [];

    if (metaDescription) {
        spotlightTags.push({
            property: 'og:description',
            content: metaDescription
        });
        spotlightTags.push({
            name: 'description',
            content: metaDescription
        });
        spotlightTags.push({
            name: 'twitter:description',
            content: metaDescription
        });
    }

    if (article.metaTagTitle) {
        spotlightTags.push({
            property: 'og:title',
            content: article.metaTagTitle
        });
        spotlightTags.push({
            name: 'twitter:title',
            content: article.metaTagTitle
        });
    }

    if (metaImage) {
        spotlightTags.push({
            property: 'og:image',
            content: metaImage
        });
        spotlightTags.push({
            name: 'twitter:image',
            content: metaImage
        });
    }

    //On non en-US locales, if the blog page isn't published it will default back to english
    //We don't want to index the page in this scenario
    const pageDefaultedToEnglish = pageData._locale === 'en-US' && locale && locale !== 'en-US';

    return (
        <>
            <UeThemeProvider theme={themeToUse}>
                <SpotlightPageContainer className="has-lightbox">
                    <Page>
                        {getHelmetForMetaTags(pageData, publisherName)}
                        {pageDefaultedToEnglish && (
                            <Helmet
                                meta={[
                                    {
                                        property: 'og:locale',
                                        content: pageData._locale
                                    },
                                    {
                                        name: 'robots',
                                        content: 'noindex, nofollow'
                                    }
                                ]}
                            />
                        )}
                        <Helmet meta={spotlightTags} />

                        {structuredDataEl}
                        <SpotlightWrapper
                            className={`header-type-${type} ${hasLoaded ? 'hasLoaded' : ''} ${
                                showNewsletterCard ? 'has-newsletter-card' : ''
                            }`}>
                            {parentOrigin !== 'cms' && (
                                <SimpleSocialSharing
                                    title={title}
                                    tweet={tweet}
                                    offsetHeight={offsetHeight}
                                />
                            )}
                            <div id="spotlight-article">
                                <Header
                                    {...header}
                                    title={title}
                                    height={header.height || 'auto'}
                                    subtitle={header.subtitle || ''}
                                    tag={header.tag}
                                    desc={header.desc || ''}
                                    type={header.type || 'default'}
                                    origin="spotlight"
                                    showreel={header.showreel}
                                    buttons={header.buttons || []}
                                    vcenter={header.vcenter || false}
                                    background={header.background || {}}
                                />
                                <div className="article-top-details">
                                    <div className="article-core">
                                        {author ? (
                                            <div>
                                                <span>{author}</span>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {date ? <span className="article-date">{date}</span> : ''}
                                    </div>

                                    {tagList && !disableTags ? (
                                        <div className="article-tags">{sortedTags}</div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <ArticleDetails
                                    offsetHeight={offsetHeight}
                                    content={badge.content || ''}
                                    logo={badge.logo || ''}
                                    isBio={badge.isBio || false}
                                />
                                <div>{topSections && <Sections sections={topSectionsList} />}</div>
                                <Interview
                                    title={interview.title || ''}
                                    interviewee={interview.interviewee || {}}
                                    questions={interview.questions || []}
                                />
                                <div>
                                    {bottomSections && <Sections sections={bottomSectionsList} />}
                                </div>
                            </div>
                        </SpotlightWrapper>

                        {toggleLightbox && activeImage ? (
                            <Lightbox>
                                <img src={activeImage} alt="" />
                                <button
                                    title="Close"
                                    className="close icon-cross"
                                    onClick={hideLightbox}
                                />
                                <button
                                    title="Previous image"
                                    className="prev icon-arrow-left"
                                    data-direction="prev"
                                    onClick={arrowHandler}
                                />
                                <button
                                    title="Next image"
                                    className="next icon-arrow-right"
                                    data-direction="next"
                                    onClick={arrowHandler}
                                />
                            </Lightbox>
                        ) : (
                            ''
                        )}
                    </Page>
                </SpotlightPageContainer>
            </UeThemeProvider>
        </>
    );
};
