import React from 'react';
import {generateRoutePath} from '@epic-core/common';
import {TagsWrapper} from './Tags.styles';
import {convertTagLabelToId} from 'epic-ue-shared';

interface TagsProps {
    feedItemTags: {
        tagId?: string;
        label?: string;
    }[];
}

const Tags = ({feedItemTags = []}: TagsProps): JSX.Element => {
    const renderTags = feedItemTags.map((tag) => {
        if (!tag || !tag.tagId || !tag.label) return null;
        const path = generateRoutePath(`/feed?tags=${convertTagLabelToId(tag.tagId)}`);

        return (
            <a className="tag-link" href={path} data-path={path} key={tag.tagId}>
                <p className="tag">{tag.label || ''}</p>
            </a>
        );
    });

    return <TagsWrapper className="col-xs-12">{renderTags}</TagsWrapper>;
};

export default Tags;
