import axios, {AxiosResponse} from 'axios';
import {isError} from '@epic-mw/error-types';
import {getOrigin} from '../utils';

const url = () => {
    const origin = getOrigin();

    return {
        FORM_OPT_IN: `${origin}/api/communication/opt-in?setting=email:ue`,
        FORM_SUBMIT: `${origin}/api/data-router`,
        METAHUMAN_APPLY: `${origin}/api/metahuman-creator-apply`
    };
};

class FormApi {
    public async getOptIn(): Promise<any> {
        const api = url().FORM_OPT_IN;

        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getOptIn() failed to load', isError(e) && e.message);
            throw e;
        }
    }

    public async optIn(): Promise<any> {
        const api = url().FORM_OPT_IN;
        try {
            const response: AxiosResponse<any> = await axios.post(api);
            return response.data;
        } catch (e) {
            console.error('optIn() failed to load', isError(e) && e.message);
            throw e;
        }
    }

    public async submitData(data): Promise<any> {
        const api = url().FORM_SUBMIT;
        try {
            const response: AxiosResponse<any> = await axios.post(api, data);
            return response.data;
        } catch (e) {
            console.error('submitData() failed to load', isError(e) && e.message);
            throw e;
        }
    }

    public async metahumanApply(): Promise<any> {
        const api = url().METAHUMAN_APPLY;
        try {
            const response: AxiosResponse<any> = await axios.post(api);
            return response.data;
        } catch (e) {
            console.error('metahumanApply() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const formApi = new FormApi();
