import React from 'react';
import {HighlightPanelProps, useMultiCmsData} from '../../shared';
import Header from '../../components/events/Header/Header';
import CustomerTalksPanel from '../../components/events/customerTalks/CustomerTalksPanel';
import SpeakersPanel from '../../components/events/Speakers/SpeakersPanel';
import PartnersPanel from '../../components/events/Partners/PartnersPanel';

import {convertCmsMultiToHighlights} from '../../utils';
import {Page, Tag} from 'epic-ue-components';
import {MosaicHighlights} from '../enterprise/Mosaic/MosaicLayout';
import {OldEvent} from './Event.styles';
import {UnrealLoading} from 'epic-ue-loading';

interface Properties {
    getMessage: any;
    locale: string;
    event: any;
}

const EventView = (props: Properties): JSX.Element => {
    const event = props.event || {};
    const highlightPanel = event.highlightPanel || {};
    const highlightList = highlightPanel.highlights || [];
    const highlightRefs: string[] = [];
    highlightList.forEach((highlightWrap) => {
        if (highlightWrap && highlightWrap.highlight) {
            highlightRefs.push(highlightWrap.highlight);
        }
    });
    const {pageData: allPageData, cmsLoading: highlightsLoading} = useMultiCmsData(
        [],
        highlightRefs
    );

    const highlights = convertCmsMultiToHighlights(allPageData as unknown as HighlightPanelProps);

    const schedule = event.schedule || {};
    const eventHeader = event.header || {};
    const {startDate, endDate} = eventHeader;
    const tagStr = event.tags || '';
    const tagArray = tagStr.split(',');

    const getMessage = props.getMessage;
    const locale = props.locale;
    return (
        <Page>
            <OldEvent className="eventview studio-features enterprise">
                <Header
                    backgroundImageURL={eventHeader.backgroundImageURL}
                    title={eventHeader.title}
                    body={eventHeader.short}
                    locationStr={eventHeader.location || ''}
                    buttonText={getMessage('epic.enterprise.header.register.button')}
                    targetURL={eventHeader.targetURL}
                    linkText={eventHeader.linkText}
                    targetIsExternal={eventHeader.targetIsExternal}
                    startDate={
                        startDate && startDate.toISOString ? startDate.toISOString() : startDate
                    }
                    endDate={endDate && endDate.toISOString ? endDate.toISOString() : endDate}
                    locale={locale}
                />

                <div className="events-tag-container container">
                    {tagArray.length &&
                        tagArray.map((tagItem, index) => {
                            if (tagItem) {
                                const lower = encodeURIComponent(tagItem.toLowerCase());
                                return (
                                    <Tag
                                        key={`tag-${index}`}
                                        type="default"
                                        content={tagItem}
                                        url={`/feed/all/${lower}`}
                                        rel="nofollow"
                                    />
                                );
                            }
                        })}
                </div>

                <div className="hightlights container">
                    {highlightsLoading ? (
                        <UnrealLoading fillViewHeight={false} />
                    ) : (
                        <MosaicHighlights highlights={highlights} />
                    )}
                </div>

                {event.customerTalks && event.customerTalks.showPanel ? (
                    <CustomerTalksPanel
                        headline={event.customerTalks.headline}
                        talks={event.customerTalks.talks || []}
                        locale={locale}
                    />
                ) : null}
                {schedule.showPanel ? <SpeakersPanel {...schedule} /> : null}

                {event.partnerInformation && event.partnerInformation.showPanel ? (
                    <PartnersPanel {...event.partnerInformation} />
                ) : null}
            </OldEvent>
        </Page>
    );
};

export default EventView;
