import axios, {AxiosResponse} from 'axios';
import {getLocale} from '@epic-core/common';
import {getOrigin} from 'epic-ue-shared';

const url = () => {
    const origin = getOrigin();

    const url = {
        FEEDITEMS_DATA: (locale) => `${origin}/api/v2/${locale}/feeditems`,
        FEEDITEM_DATA: (locale) => `${origin}/api/v2/${locale}/feeditem`
    };
    return url;
};

export interface FeedItemsReq {
    tags?: string[];
    patternsToSkip?: string[];
}

class FeedApi {
    public async getFeedItems(req?: FeedItemsReq): Promise<any> {
        const api = url().FEEDITEMS_DATA(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api, req);
            return response.data;
        } catch (e) {
            console.error('getFeedItems() failed to load');
            throw e;
        }
    }

    public async getFeedItem(slug: string): Promise<any> {
        const slashSlug = slug.charAt(0) === '/' ? slug.substring(1) : slug;
        const api = url().FEEDITEM_DATA(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api, {slug: slashSlug});
            return response.data;
        } catch (e) {
            console.error('getFeedItem() failed to load', slug, api);
            throw e;
        }
    }
}

export const feedApi = new FeedApi();
