import React from 'react';
import styled from 'styled-components';
import {Typography} from 'epic-ue-ui';

const Styled = styled.div`
    &&.center {
        text-align: center;
        h1,
        h1.big {
            width: 100%;
            text-align: center;
        }
    }

    && h1 {
        font-family: 'NeuePlakCompressedBlack', Inter Tight, Helvetica;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 80px;
        line-height: 65.6px;
        letter-spacing: 1px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 56px;
            line-height: 45.92px;
        }
        &.loc-ja {
            font-size: 64px;
            line-height: 68px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 26px;
                line-height: 32px;
            }
        }
        &.loc-ko {
            font-size: 64px;
            line-height: 72px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 36px;
                line-height: 40px;
            }
        }
        &.loc-zh-CN {
            font-size: 68px;
            line-height: 72px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 40px;
                line-height: 48px;
            }
        }
        &.big {
            width: max-content;

            &.loc-pt-BR,
            &.loc-ko {
                font-size: 88px;
                line-height: 96px;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 46px;
                    line-height: 48px;
                }
            }

            &.loc-zh-CN,
            &.loc-es-ES,
            &.loc-ja {
                font-size: 126px;
                line-height: 132px;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 64px;
                    line-height: 72px;
                }
            }
            font-size: 200px;
            line-height: 160px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 100px;
                line-height: 80px;
            }
        }
        &.accent {
            color: #e3c6fe;
        }
    }
`;

export interface ContrastHeadlinesProps {
    topHeadline?: string;
    bottomHeadline?: string;
    isAccentTop?: boolean;
    isBig?: boolean;
    isCentered?: boolean;
    isOffset?: boolean;
}

export const ContrastHeadlines = ({
    topHeadline,
    bottomHeadline,
    isAccentTop,
    isBig,
    isCentered,
    isOffset
}: ContrastHeadlinesProps): JSX.Element => {
    return (
        <Styled className={`contrast-headlines ${isCentered ? 'center' : ''}`}>
            <Typography
                variant="h1"
                className={`
                top
                ${isAccentTop ? 'accent' : ''}
                ${isBig ? 'big' : ''}
                ${isOffset ? 'offset' : ''}`}>
                {topHeadline}
            </Typography>
            <Typography
                variant="h1"
                className={`
                bottom
                ${isAccentTop ? '' : 'accent'}
                ${isBig ? 'big' : ''}
                ${isOffset ? 'offset' : ''}`}>
                {bottomHeadline}
            </Typography>
        </Styled>
    );
};
