import React, {useEffect, useState} from 'react';

import {getRoutingRules} from './routing';
import {RoutingRulesProps} from './route.types';

interface Props {
    children: React.ReactNode;
}

export const RoutingRulesContext = React.createContext<RoutingRulesProps[]>([]);

export const RoutingRules = ({children}: Props): JSX.Element => {
    const [routingRules, setRoutingRules] = useState<RoutingRulesProps[]>([]);

    useEffect(() => {
        async function fetchData(): Promise<void> {
            if (routingRules.length > 0) {
                return;
            }
            try {
                const data = await getRoutingRules();
                setRoutingRules(data);
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [routingRules, setRoutingRules]);

    return (
        <RoutingRulesContext.Provider value={routingRules || []}>
            {children}
        </RoutingRulesContext.Provider>
    );
};
