import {getBowserState} from '@epic-core/common';

interface Properties {
    url: string;
    width?: number;
    height?: number;
    quality?: 'low' | 'mediumLow' | 'medium' | 'mediumHigh' | 'high';
    aspect?: 'fill' | 'fit';
}

const MOBILE_WIDTH = 800;
const TABLET_WIDTH = 1024;
const DESKTOP_WIDTH = 1920;

const getDefaultWidth = () => {
    const {mobile, tablet} = getBowserState();
    if (mobile) {
        return MOBILE_WIDTH;
    }
    if (tablet) {
        return TABLET_WIDTH;
    }
    return DESKTOP_WIDTH;
};

/**
 * Will use the maximum width/height of the browser to request an optimized image.
 *
 * getOptImage({url, width: 1200})
 * getOptImage({url, height: 400})
 * getOptImage({url, width: 200, height: 200, quality: 'mediumLow', aspect: 'fit'})
 */
export const getOptImage = ({url, width, height, quality, aspect}: Properties): string => {
    if (!url || typeof url !== 'string') return '';
    if (url.indexOf('resize=') !== -1) {
        return url;
    }

    let w = width ? Math.floor(width) : undefined;
    const h = height ? Math.floor(height) : undefined;
    const defaultWidth = getDefaultWidth();

    if (w && w > defaultWidth) w = defaultWidth;

    let optimizedImageUrl = `${url}?resize=1`;

    if (width) {
        optimizedImageUrl += `&w=${w}`;
    }

    if (height) {
        optimizedImageUrl += `&h=${h}`;
    }

    if (!width && !height) {
        optimizedImageUrl += `&w=${defaultWidth}`;
    }

    if (quality) {
        optimizedImageUrl += `&quality=${quality}`;
    }

    if (aspect) {
        optimizedImageUrl += `&aspect=${aspect}`;
    }

    return optimizedImageUrl;
};
