import styled from 'styled-components';

import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: 16px;
        width: 100%;
        background-color: #2a2a2a;
    }

    body {
        * {
            text-rendering: optimizeLegibility;
            -webkit-font-feature-settings: "kern" 1;
            font-feature-settings: "kern" 1;
            -webkit-font-smoothing: subpixel-antialiased;
        }

        *, :after, :before {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        input, button, select, textarea, p {
            font-family: OpenSans, sans-serif, arial;
        }
        .blog-wrapper {
            font-size: 16px;
        }

        font-family:  OpenSans, sans-serif, arial;
        min-width: 320px;
        margin: 0 auto;
        padding: 0;
        font-size: 16px;
        line-height: 1.5;

        &.zh_CN {
            font-family: 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Helvetica, Arial, 'Microsoft Yahei', '微软雅黑', STXihei, '华文细黑', Verdana, sans-serif;

            #egh {
                .siteNavKids li a, .siteNav li a {
                    font-family: 'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Helvetica, Arial, 'Microsoft Yahei', '微软雅黑', STXihei, '华文细黑', Verdana, sans-serif;
                    p, span {
                        font-size: 14px ;
                    }
                }
                .right-col .rightNav>div a span, #locale ul li a span {
                    font-size: 12px;
                }
            }
        }

        &.ko {
            word-wrap: break-word;
            word-break: keep-all;

            a, .comment-tooltip {
                word-break: keep-all !important;
            }

            h1 {
                font-weight: 500;
            }
        }

        &.fixed,
        &.news-letter-modal-opened {
            overflow: hidden;
        }

        &[data-ios="true"] {
            .form {
                .checkbox-input {
                    border: 0
                }
            }
        }
        .slick-slider .slick-list, .slick-slider .slick-track {
            transform: translateZ(0);
        }

        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
            position: relative;
            display: block;
            .slick-track {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .MuiMenu-list{
            li.MuiMenuItem-root {
                font-size: 16px;
            }
        }

        .enterprise {
            font: 400 16px Brutal, Arial, sans-serif;
        }

        .enterprise-view {
            font: 400 16px Brutal, Arial, sans-serif;
        }

        .ue-react {
            a:hover, a:visited, a:active, a:focus {
                text-decoration: none;
            }
            a:focus { outline: 0; }

            .background-wrapper {
                background-color: black;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }

            .background-type {
                &.htmlVideo, &.youtubeVideo {
                    min-height: 66vh;

                    .container {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .overlay {
                    background: rgba(0, 0, 0, 0.4);
                }

                &.image {
                    min-height: 30vh;
                }

                position: relative;
                height: auto;

                > div > .row {
                    display: table;
                    height: auto;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;

                    > div {
                        float: none;
                        vertical-align: bottom;
                        display: table-cell;
                        padding: 5% 0 10%;
                        z-index: 2;
                    }
                }
            }

            .enterprise-intro-header-text * {
                text-align: center;
            }

            .enterprise-intro-header-text {
                .enterprise-intro-header-title {
                    color: #fff;
                    text-align: center;
                    font-family: Brutal, Brutal_Medium, Arial, sans-serif;
                    font-size: 48px;
                    text-transform: uppercase;
                    margin-top: 40px;

                    @media screen and (max-width: 768px) {
                        font-size: 42px;
                    }
                    @media screen and (max-width: 568px) {
                        font-size: 32px;
                    }
                }

                .enterprise-intro-header-content {
                    margin-top: 30px;
                    font-size: 16px;
                    line-height: 2;
                    text-align: center;
                    color: #fff;
                    padding: 0 12px;
                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                    }
                    @media screen and (max-width: 568px) {
                        font-size: 12px;
                    }
                }

                .enterprise-header-more {
                    margin-top: 40px;
                    a {
                        padding: 5px 20px;
                    }
                }
            }
        }
    }
`;

export const ClassicAppWrapper = styled.div`
    background: ${(p) => p.theme.palette.background.default};
    font-size: 16px;
    font-family: ${(p) => p.theme.ue.fonts.opensans};
    input,
    button,
    select,
    textarea,
    p {
        font-family: ${(p) => p.theme.ue.fonts.opensans};
    }

    .btn.active {
        box-shadow: none;
    }
    * {
        a:hover,
        a:visited,
        a:active,
        a:focus {
            text-decoration: none;
        }

        a:focus {
            outline: 0;
        }
    }

    .clickable,
    .cursor-pointer {
        cursor: pointer;
    }

    footer {
        .footer-cta-btn {
            a.accent-bg-color {
                padding: 1em;
                @media (max-width: 768px) {
                    padding: 1em 0.5em;
                    font-size: 0.7em;
                }
            }
        }
    }

    .footer-news-letter-modal .footer-news-letter-content div.privacy-link a {
        color: #333;
    }

    .desc-wrapper,
    p {
        a {
            color: ${(p) => p.theme.palette.accent.bright.sky};

            &:hover,
            &:focus,
            &:active {
                color: ${(p) => p.theme.ue.colors.buttons.link.hover};
            }
        }
    }

    .ue-secondary-button {
        border: 2px solid ${(p) => p.theme.ue.colors.buttons.primary.background.default};
        background: rgba(0, 0, 0, 0.3);
        &:hover,
        &:active {
            background: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
            color: ${(p) => p.theme.palette.text.primary};
            text-decoration: none;
        }
    }

    h1 {
        font-weight: 700;
    }
    h2,
    h3,
    h4,
    h5 {
        font-weight: 500;
    }

    .center,
    .text-center {
        text-align: center;
    }

    .center {
        margin-left: auto;
        margin-right: auto;
    }

    .html-video-embed {
        height: 100%;
        width: 100%;
    }

    a.btn,
    button.btn,
    .ue-secondary-button,
    .ue-cta-button,
    .studio-cta-button {
        font-size: 12px;
        padding: 17px 28px;
        color: ${(p) => p.theme.ue.colors.buttons.primary.text.default};
        display: inline-block;
        vertical-align: top;
        border-radius: 2px;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
        cursor: pointer;
        text-decoration: none;
    }

    @media only screen and (min-width: 1200px) {
        .container,
        .ue-react.container {
            max-width: 1200px;
        }
    }

    @media only screen and (min-width: 1200px) {
        .container,
        .ue-react.container {
            width: 1230px;
        }
    }

    a.btn,
    button.btn,
    .studio-cta-button,
    .ue-cta-button {
        background: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
        border: 0;
        transition: background 0.2s linear, border-color 0.2s linear, color 0.2s linear;
        &:active,
        &:hover {
            background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
            color: ${(p) => p.theme.ue.colors.buttons.primary.text.default};
            border-radius: 2px;
            text-decoration: none;
        }

        &.disabled {
            background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
            cursor: not-allowed;
            &:hover {
                background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
            }
        }

        &.button-lg {
            padding: 25px 50px;
            font-size: 1em;
            font-family: ${(p) => p.theme.ue.fonts.inter.regular};
            font-weight: 600;
            border-radius: 2px;
            ${(p) => p.theme.breakpoints.down('lg')} {
                padding: 12px 22px;
                margin: 0;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                padding: 17px 28px;
                font-size: 0.875em;
            }
        }

        &.trans {
            color: inherit;
            font-size: 16px;
            display: inline-block;
            vertical-align: top;
            border: 1px solid #c6c6c6;
            border-radius: 2px;
            text-transform: none;
            padding: 15px 20px;
            margin: 0;
            background: transparent;
            transition: background 0.2s linear, border-color 0.2s linear, color 0.2s linear;
            &:hover {
                text-decoration: none;
                color: ${(p) => p.theme.palette.text.primary};
                background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
                border-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
                border-color: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
            }

            &.white-hover {
                &:hover {
                    background: #fff;
                    color: #4e4e4e;
                    border: 1px solid #fff;
                }
            }
        }

        &.uppercase {
            text-transform: uppercase;
        }
    }

    .studio-cta-button.button-lg {
        color: #000;
        border-radius: 0;
        padding: 24px 40px;

        &:active,
        &:hover {
            background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
            color: #000;
        }
    }

    .aodc-dob-section {
        input {
            color: rgb(0, 0, 0);
            font-size: 16px;
        }
        button {
            div.underline.container {
                justify-content: center;
            }
        }
    }
    && .select.field-wrap div {
        height: unset;
        div {
            min-height: 60px;
            input {
                padding: 20px 16px 0px;
            }
        }
    }

    #webPurchaseContainer.webPurchaseContainer {
        z-index: 99998;
        height: 80vh;
        @media screen and (max-height: 1200px) {
            margin: auto;
            height: 94vh;
            max-height: 80em;
            margin-top: 3vh;
        }
        @media screen and (max-height: 961px) {
            height: 100vh;
            margin-top: 0;
        }
    }

    .form-group {
        margin-bottom: 15px;
    }

    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    img {
        vertical-align: top;
    }

    .block-gray {
        background: #edeff3;
        border-bottom: 1px solid #d7d7d7;
    }

    .block-dark-gray {
        background: #ececec;
        border-bottom: 1px solid #d7d7d7;
    }

    .block-white {
        border-bottom: 1px solid #d7d7d7;
    }

    .block-title {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 60px 20px;

        &.schedule {
            padding-bottom: 15px;
        }
    }

    .block-subtitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
    }
`;
