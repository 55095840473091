import axios, {AxiosResponse} from 'axios';
import root from 'window-or-global';
import {getOrigin} from '../utils';
import {isError} from '@epic-mw/error-types';

const url = () => {
    const origin = getOrigin();

    return {
        ENTERPRISE_CONTACT_DATA: () => `${origin}/api/v2/data/countries`
    };
};

class MiscApi {
    public async getCountries(): Promise<any> {
        if (root.__server_side_render) {
            return Promise.resolve();
        }

        const api = url().ENTERPRISE_CONTACT_DATA();
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getCountries() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const miscApi = new MiscApi();
