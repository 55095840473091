import React from 'react';
import styled from 'styled-components';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import VideoClass from '../components/video/VideoClass';
import {UnrealLoading} from 'epic-ue-loading';
import {useCmsData} from '../shared';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';
import {PageData} from 'epic-ue-shared';

const EducatorResources = styled.div`
    background: #edeff3;
    color: ${(p) => p.theme.palette.common.black};
    padding-bottom: 4em;

    h1 {
        margin: 0;
        padding: 0.8em 0.2em 0.2em 0.2em;
        font-size: 76px;
        font-family: ${(p) => p.theme.ue.fonts.inter.tight};
        font-weight: 700;
    }

    p.description {
        font-size: 20px;
        font-family: Brutal;
        font-weight: 400;
        padding: 1em;
        color: ${(p) => p.theme.palette.neutral.charcoal};
    }

    h2.light {
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
    }
`;

const cmsUrlPattern = '/education/educators/resources';

interface Resource {
    image: string;
    subCategory: string;
    author: string;
    name: string;
    description: string;
    vimeoLink: string;
    hoverLabel: string;
}

interface ResourcesPageData extends PageData {
    guides: {
        description: string;
        title: string;
        resourceList: Resource[];
    };
    marketoFormUrl: string;
    description: string;
    title: string;
    gatedMsg: string;
    form: {
        fieldGroups: any[];
        messages: any;
        loadingImg: string;
    };
}

const InstructorGuidesPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<ResourcesPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />;
            </>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const guides = pageData.guides || {};
    const resourceList = guides.resourceList || [];

    return (
        <EducatorResources>
            {getHelmetForMetaTags(pageData)}

            <div className="header-section container text-center">
                <h1>{pageData.title}</h1>
                <p className="description">{pageData.description}</p>
            </div>

            <div className="instructor-guides container">
                <h2>{guides.title}</h2>
                <p>{guides.description}</p>
                <div className="video-class-wrapper">
                    {resourceList.map((resource) => (
                        <VideoClass key={resource.name} {...resource} />
                    ))}
                </div>
            </div>
        </EducatorResources>
    );
};

InstructorGuidesPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default InstructorGuidesPage;
