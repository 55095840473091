import React from 'react';
import {getHelmetForMetaTags, getInitialCmsPageSharedValues, PageData} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import {getUrlPattern, useCmsData} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {UeThemeProvider, ueDarkTheme, ueLightTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {useLocation, useMatch} from 'react-router-dom';
import {
    TemplateThemeProps,
    SubnavSectionConfigProps,
    VerticalSpacer,
    SubnavSection,
    DownloadSection,
    NewsletterSection,
    AnimateOnViewWrap,
    ImageHero,
    Subnav,
    SubnavProps,
    DownloadSectionProps,
    NewsletterSectionProps,
    SubnavSectionProps,
    ImageHeroProps,
    CmsComponentMapper,
    ImmersiveHeaderSection,
    DynamicHeaderProps,
    GeneralLandingHeaderProps
} from 'epic-ue-ui';
import {GeneralLandingPageSections} from 'epic-ue-ui';
import {DynamicHubSections} from '../../components/hub/DynamicHubSections';
import {useLocalization} from '@epic-mw/localization';
import styled from 'styled-components';

export interface DownloadWithNewsletterSectionProps extends SubnavSectionProps {
    download?: DownloadSectionProps;
    newsletter?: NewsletterSectionProps;
}

interface ExplainerDetailLandingCardProps {
    heading?: string;
    eyebrow?: string;
    description?: string;
    image?: string;
    imagelabel?: string;
}

interface HubLandingPageData extends PageData {
    sectionConfig?: SubnavSectionConfigProps;
    header?: ImageHeroProps;
    subnav?: SubnavProps;
    landing?: ExplainerDetailLandingCardProps;
    sections?: GeneralLandingPageSections;
    themeConfig?: TemplateThemeProps;
    download: DownloadWithNewsletterSectionProps;
    tags?: string;
}

interface Properties {
    cmsUrlPattern?: string;
}

const HeaderStyled = styled.div`
    max-width: 100vw;
    overflow-x: hidden;
`;

const HubLandingPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<HubLandingPageData>(urlPattern);

    const {
        header = {} as GeneralLandingHeaderProps,
        themeConfig = {} as TemplateThemeProps,
        download = {} as DownloadWithNewsletterSectionProps,
        subnav = {} as SubnavProps,
        sections = {} as GeneralLandingPageSections
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);
    const getMessage = useLocalization();

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const {items = []} = sections;

    const headerImageProps = (header as ImageHeroProps) || {};
    const dynamicHeader = (header as DynamicHeaderProps) || {};
    const isDynamicHeader = Boolean(
        dynamicHeader._type === 'Stardust - Element - General Landing Header'
    );
    const {headerOptions} = dynamicHeader;

    return (
        <>
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                {getHelmetForMetaTags(pageData)}
                <>
                    <HeaderStyled>
                        {isDynamicHeader ? (
                            <CmsComponentMapper
                                dynamicPageDataArray={headerOptions}
                                elementMapping={{
                                    imageHero: <ImageHero accentColor={accentColor} />,
                                    immersiveHeader: (
                                        <ImmersiveHeaderSection accentColor={accentColor} />
                                    )
                                }}
                            />
                        ) : (
                            <ImageHero
                                {...(headerImageProps as ImageHeroProps)}
                                accentColor={accentColor}
                            />
                        )}
                    </HeaderStyled>
                    <Subnav {...subnav} accentColor={accentColor} />
                    <VerticalSpacer variant="section" />

                    <AnimateOnViewWrap delay={0.6} translateY={10}>
                        <div className="article-section">
                            <DynamicHubSections
                                theme={theme}
                                items={items}
                                accentColor={accentColor}
                            />
                        </div>
                    </AnimateOnViewWrap>
                    <VerticalSpacer variant="section" />
                    <SubnavSection
                        variant="gradient"
                        hideBottomSectionPadding
                        sectionConfig={downloadSectionConfig}>
                        <DownloadSection
                            {...downloadBlockProps}
                            accentColor={accentColor}
                            variant="standard"
                        />
                        <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                        <NewsletterSection
                            variant={download.newsletter?.variant || 'split'}
                            {...download?.newsletter}
                            privacyPolicyLinkName={
                                download?.newsletter?.privacyPolicyLinkName ||
                                getMessage('epic.privacy.policy')
                            }
                            title={
                                download?.newsletter?.title ||
                                getMessage('epic.ue.newsletter.title')
                            }
                            industryPlaceholder={
                                download?.newsletter?.industryPlaceholder ||
                                getMessage('epic.ue.newsletter.select.label')
                            }
                            accentColor={accentColor}
                        />
                    </SubnavSection>
                </>
            </UeThemeProvider>
        </>
    );
};

HubLandingPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default HubLandingPage;
