import React from 'react';
import {GlobalFooterWrapper} from './GlobalFooterWrapper';

interface Properties {
    disablePaths?: string[];
}

export const Footer = (props: Properties): JSX.Element | null => {
    return <GlobalFooterWrapper {...props} />;
};
