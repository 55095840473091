import React, {useState} from 'react';
import {useCmsData, useEulaContent, useEulaAcceptance, useIsLoggedIn} from '../shared';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, SimpleLink, Button} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {useLocalization} from '@epic-mw/localization';
import {determineEulaType} from '../utils';
import ErrorPage from './ErrorPage';
import {useParams} from 'react-router-dom';
import {EulaCheck, InnerWrap, EulaWrap, Content, AgreeWrap, LinkWrap} from './EulaCheckPage.styles';
import {getInitialCmsPageSharedValues, SimpleLinkProps, PageData} from 'epic-ue-shared';
import styled from 'styled-components';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';

const SuccessMsg = styled.h3`
    text-align: center;
    &&& {
        margin-bottom: 2em;
    }
`;

interface EulaPromptPageData extends PageData {
    agreeLabel: string;
    continueLink: SimpleLinkProps;
    desc: string;
    faqLink: SimpleLinkProps;
    title: string;
    bgImg: string;
    successMsg: string;
}

const EulaPromptPage = (): JSX.Element => {
    const isLoggedIn = useIsLoggedIn();
    const params = useParams();
    const locale = getLocale();
    const {cmsUrlPattern, eulaKey, isLocalized} = determineEulaType(params, 'eulaprompt');
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<EulaPromptPageData>(cmsUrlPattern);
    const eulaLocale = isLocalized ? locale : 'en';
    const {eula, loading: eulaLoading} = useEulaContent(eulaKey, eulaLocale);
    const {acceptCallback, accepted, accepting, acceptError} = useEulaAcceptance(
        eulaKey,
        eula && eula.version,
        eulaLocale
    );
    const [agreed, setAgreed] = useState(false);
    const [showError, setShowError] = useState(false);
    const getMessage = useLocalization();
    const agreeChange = React.useCallback(
        (e) => {
            const target = e.target || {};
            const tagName = target.tagName;
            if (tagName === 'INPUT') {
                //prevent hitting this onClick twice when click the span
                setAgreed(!agreed);
                setShowError(!showError && agreed);
            }
        },
        [agreed, showError]
    );
    const acceptCallbackValidator = React.useCallback(() => {
        if (agreed) acceptCallback();
    }, [agreed, acceptCallback]);

    if (!eulaKey || !cmsUrlPattern) {
        return <ErrorPage status={404} />;
    }

    if (!isLoggedIn || eulaLoading || cmsLoading) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />
            </>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {body} = eula;
    const {title, desc, agreeLabel, continueLink, faqLink, bgImg, successMsg} = pageData;

    if (accepted) {
        root.scrollTo(0, 0);
    }

    return (
        <Page>
            <EulaCheck bgImg={bgImg}>
                {getHelmetForMetaTags(pageData)}

                <div className="container">
                    <h3>{title}</h3>
                    <h5>
                        <div dangerouslySetInnerHTML={{__html: desc}} />
                    </h5>

                    {accepted ? (
                        <SuccessMsg>{successMsg}</SuccessMsg>
                    ) : (
                        <>
                            <EulaWrap>
                                <InnerWrap>
                                    <Content dangerouslySetInnerHTML={{__html: body}} />
                                </InnerWrap>
                            </EulaWrap>

                            <AgreeWrap>
                                <label
                                    htmlFor="accept"
                                    className="terms-check"
                                    onClick={agreeChange}>
                                    <input
                                        id="accept"
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={agreed}
                                    />
                                    <span className="input-label">{agreeLabel}</span>
                                </label>

                                {showError && !agreed ? (
                                    <small className="error-text">
                                        {getMessage(
                                            'errors.com.epicgames.validation.end_user_license_agreement'
                                        )}
                                    </small>
                                ) : (
                                    ''
                                )}

                                {acceptError && <small className="error-text">{acceptError}</small>}

                                {accepting ? (
                                    <UnrealLoading fillViewHeight={false} />
                                ) : (
                                    <Button
                                        id="eula-acceptance"
                                        className={`btn ${agreed ? 'active' : 'disabled'}`}
                                        type="primary"
                                        onClick={acceptCallbackValidator}>
                                        {getMessage('epic.ue.eula.check.accept')}
                                    </Button>
                                )}
                            </AgreeWrap>
                        </>
                    )}

                    <LinkWrap>
                        <SimpleLink {...continueLink} />
                        <SimpleLink {...faqLink} />
                    </LinkWrap>
                </div>
            </EulaCheck>
        </Page>
    );
};

EulaPromptPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default EulaPromptPage;
