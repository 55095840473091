import {useState, useEffect, useMemo} from 'react';
import {useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys} from 'epic-ue-shared';
import {feedApi, FeedItemsReq} from './feed.api';
import {isError} from '@epic-mw/error-types';
import {FeedControllerRes, FeedItem, FeedItemRes} from '../types/feed.types';

export interface FeedItemsResponse {
    items: FeedItem[];
    loading?: boolean;
    loaded: boolean;
    error?: string;
}

export const useFeedItems = (req: FeedItemsReq, contentLoading?: boolean): FeedItemsResponse => {
    const patternsToSkip = req.patternsToSkip || [];
    const tags = req.tags || [];

    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [feedItemsStore, setFeedItemsStore] = useSharedValue(AppSharedKeys.FeedItemsStore);
    const feedItems = feedItemsStore.items || [];
    const feedItemTags = feedItemsStore.tags || {};
    const feedItemPatternsToSkip = feedItemsStore.patternsToSkip || [];

    //the order of these keys must match to correctly compare the stringified object
    const stringifiedCurrent = JSON.stringify({tags, patternsToSkip});
    const stringifiedStore = JSON.stringify({
        tags: feedItemTags,
        patternsToSkip: feedItemPatternsToSkip
    });

    //reset
    if (stringifiedCurrent !== stringifiedStore && loadingState.loaded && !loadingState.error) {
        setFeedItemsStore(
            Object.assign({}, feedItemsStore, {
                items: [],
                tags: {},
                patternsToSkip: []
            })
        );
        setLoadingState({loading: false, loaded: false, error: ''});
    }

    useEffect(() => {
        if (
            stringifiedCurrent === stringifiedStore ||
            loadingState.loading ||
            loadingState.loaded ||
            contentLoading ||
            loadingState.error
        ) {
            return;
        }
        if (loadingState.loaded && !loadingState.error) {
            setFeedItemsStore(
                Object.assign({}, feedItemsStore, {
                    items: [],
                    tags: [],
                    patternsToSkip: []
                })
            );
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData: FeedControllerRes =
                    (await feedApi.getFeedItems({tags, patternsToSkip})) || {};

                setFeedItemsStore(
                    Object.assign({}, feedItemsStore, {
                        ...fetchedData,
                        tags,
                        patternsToSkip
                    })
                );
                setLoadingState({loading: false, loaded: true, error: fetchedData.error || ''});
            } catch (ex) {
                console.error('Failed to fetch data for', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [loadingState.loading, loadingState.loaded, contentLoading]);

    return useMemo(() => {
        return {
            ...feedItemsStore,
            loading: loadingState.loading || (!feedItems.length && !loadingState.loaded),
            loaded: loadingState.loaded,
            error: loadingState.error
        };
    }, [
        stringifiedStore,
        stringifiedCurrent,
        loadingState.loading,
        loadingState.loaded,
        contentLoading
    ]);
};

export interface FeedItemResponse {
    item: FeedItem;
    loading?: boolean;
    loaded: boolean;
    error?: string;
}

export const useFeedItem = (slug: string, contentLoading?: boolean): FeedItemResponse => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [feedItemStore, setFeedItemStore] = useSharedValue(AppSharedKeys.FeedItemStore);
    const feedItem = feedItemStore.item || {};

    const requestedSlugExists =
        slug === feedItem.url || `/${feedItem.locale}${slug}` === feedItem.url;

    //reset
    if (loadingState.loaded && !loadingState.error && !requestedSlugExists) {
        setFeedItemStore(
            Object.assign(
                {},
                {
                    item: {}
                }
            )
        );
        setLoadingState({loading: false, loaded: false, error: ''});
    }

    useEffect(() => {
        if (
            requestedSlugExists ||
            loadingState.loading ||
            loadingState.loaded ||
            contentLoading ||
            loadingState.error
        ) {
            return;
        }
        if (loadingState.loaded && !loadingState.error) {
            setFeedItemStore(
                Object.assign(
                    {},
                    {
                        item: {}
                    }
                )
            );
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData: FeedItemRes = (await feedApi.getFeedItem(slug)) || {};
                setFeedItemStore(
                    Object.assign(
                        {},
                        {
                            ...fetchedData,
                            error: fetchedData.item?.error,
                            message: fetchedData.item?.message,
                            statusCode: fetchedData.item?.statusCode
                        }
                    )
                );
                setLoadingState({
                    loading: false,
                    loaded: true,
                    error: fetchedData.item?.error || ''
                });
            } catch (ex) {
                console.error('Failed to fetch data for', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [loadingState.loading, loadingState.loaded, contentLoading]);

    return useMemo(() => {
        return {
            ...feedItemStore,
            loading: loadingState.loading || (!feedItem.slug && !loadingState.loaded),
            loaded: loadingState.loaded,
            error: loadingState.error
        };
    }, [loadingState.loading, loadingState.loaded, contentLoading]);
};
