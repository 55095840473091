import useSWRImmutable from 'swr/immutable';
import {isError} from '@epic-mw/error-types';
import axios from 'axios';
import {useNewsletterAccount} from './form.hooks';

const getAOMPolicy = async (id: string): Promise<{AOMFailed: boolean}> => {
    try {
        const response = await axios.get(`/api/aom/policy`, {
            params: {id}
        });
        return response.data;
    } catch (err) {
        console.error('getAOMPolicy() failed to load', isError(err) && err.message);
        throw err;
    }
};

export const useAccountAOMPolicy = () => {
    const {account} = useNewsletterAccount();
    const shouldFetch = Boolean(account?.id);

    const {data, error} = useSWRImmutable(shouldFetch ? account?.id : null, getAOMPolicy, {
        errorRetryCount: 0
    });

    return {
        result: data,
        isLoading: Boolean(!data && !error && shouldFetch),
        error
    };
};
