import React, {useCallback, useEffect} from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Button, SimpleLink, BgMedia} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData,
    BackgroundProps,
    useIsLoggedIn
} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';
import {useLocation, useNavigate} from 'react-router-dom';
import root from 'window-or-global';
import parse from 'url-parse';

const cmsUrlPattern = '/learn-auth';

const BgWrap = styled.div`
    position: relative;
    height: 300px;
    opacity: 0.5;
`;

const Auth = styled.div`
    background-color: black;
    margin: 0;
    padding: 6em;
    overflow-x: hidden;

    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 2em;
    }
`;

const Box = styled.div`
    max-width: 700px;
    position: relative;
    margin: 0 auto;
    min-height: 650px;
    background-color: ${(p) => p.theme.palette.background.default};
    color: white;
    padding: 0;
    text-align: center;

    h3 {
        position: absolute;
        top: 3em;
        color: ${(p) => p.theme.ue.colors.primary};
        width: 100%;
        text-transform: uppercase;
    }

    h1 {
        position: absolute;
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        line-height: 1.3em;
        font-weight: 600;
        margin-bottom: 1.4em;
        top: 2.6em;
        width: 100%;
        font-size: 38px;
    }

    p {
        color: rgba(244, 244, 244, 0.5);
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        font-size: 14px;
    }

    .desc {
        margin-top: 2em;
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        color: rgba(244, 244, 244, 0.8);
        padding: 0 2em;
    }

    &&& {
        a {
            color: white;
            font-size: 14px;
        }
        a.btn.btn-primary {
            background-color: #037bee;
            border-color: #037bee;
            font-size: 12px;
            font-family: ${(p) => p.theme.ue.fonts.inter.regular};
            text-transform: uppercase;
            &:hover {
                background-color: #037bee;
                border-color: #037bee;
            }
            margin: 3em 0;
        }
    }
`;

const loginHref = '/id/login';
const signupHref = '/id/register';

const loggedInDestination =
    'https://www.unrealengine.com/id/login?client_id=17ce2d2864834898ab71847859286c81&response_type=code';

interface LearnAuthPageData extends PageData {
    titlePrefix: string;
    title: string;
    desc: string;
    btnLabel: string;
    signUpDesc: string;
    signUpLink: string;
    goBackLabel: string;
    background: BackgroundProps;
}

const LearnAuthPage = (): JSX.Element => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<LearnAuthPageData>(cmsUrlPattern);
    const parsedUrl = parse(root.location.href, true);
    const origin = parsedUrl.origin || root.location.origin;
    const query = parsedUrl.query || {};
    const state = query.state || 'https://learn.unrealengine.com';

    const onGoBackClick = useCallback(
        (e) => {
            e.preventDefault();
            if (state) {
                navigate(-2);
            } else {
                root.location.href = origin;
            }
        },
        [navigate, state, origin]
    );

    useEffect(() => {
        if (isLoggedIn) {
            root.location.href = loggedInDestination;
        }
    }, [isLoggedIn]);

    if (isLoggedIn) {
        return <UnrealLoading />;
    }

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {title, desc, btnLabel, signUpLink, signUpDesc, goBackLabel, background, titlePrefix} =
        pageData;

    return (
        <Auth>
            {getHelmetForMetaTags(pageData)}
            <Box className="container">
                <BgWrap>
                    <BgMedia background={background} />
                </BgWrap>
                <h3>{titlePrefix}</h3>
                <h1>{title}</h1>
                <div className="desc" dangerouslySetInnerHTML={{__html: desc || ''}} />

                <Button
                    id="auth-login"
                    type="primary"
                    label={btnLabel}
                    as="a"
                    href={`${origin}${loginHref}${search}`}
                />

                <p>
                    <span>{signUpDesc}</span>{' '}
                    <span>
                        {' '}
                        <a href={`${origin}${signupHref}${search}`}>{signUpLink}</a>
                    </span>
                </p>

                <SimpleLink
                    id="go-back-link"
                    href={state}
                    onClick={onGoBackClick}
                    title={goBackLabel}
                />
            </Box>
        </Auth>
    );
};

LearnAuthPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default LearnAuthPage;
