import React from 'react';
import {MultiCard, SimilarBlogsWrap} from 'epic-ue-ui';
import {useFeedItems} from 'epic-ue-feed';

interface BlogTagFragment {
    tagId?: string;
    label?: string;
}

export interface SimilarBlogsLoadingProps {
    cmsLoading?: boolean;
}
export interface SimilarBlogsProps {
    headline?: string;
    seeAllLabel?: string;
    seeAllHref?: string;
    tags?: BlogTagFragment[];
    disable?: boolean;
    patternsToSkip: string[];
}

const SimilarBlogs = ({
    cmsLoading,
    headline = 'Similar blogs',
    seeAllLabel = 'See All',
    seeAllHref = '#',
    tags = [],
    patternsToSkip = []
}: SimilarBlogsProps & SimilarBlogsLoadingProps): JSX.Element => {
    const {items: feedItems = [], loading: loading} = useFeedItems(
        {tags: tags?.map((tag) => tag.tagId || '') || [], patternsToSkip},
        cmsLoading
    );

    const top3FeedItems = feedItems
        .filter((item) => item.url && !patternsToSkip.includes(item.url))
        .slice(0, 3);

    const top3FeedImages = top3FeedItems.map((feedItem) => {
        let eyebrow = feedItem?.tags?.length ? feedItem.tags[0].label : undefined;

        const tagIds = (feedItem?.tags || []).map((tag) => tag.tagId || '');

        const matchingTags = tags.filter((tag) => tag?.tagId && tagIds.includes(tag?.tagId));
        if (matchingTags && matchingTags.length) {
            eyebrow = matchingTags[0].label;
        }

        return {
            image: {
                title: feedItem.title,
                link: {
                    href: feedItem.url
                },
                description: feedItem.description,
                eyebrow: {
                    text: eyebrow
                },
                bottomEyebrow: {
                    text: ''
                },
                image: {
                    src: feedItem.image?.url || '',
                    alt: feedItem.image?.text || ''
                }
            }
        };
    });

    const seeAllButton = seeAllHref
        ? {
              label: seeAllLabel,
              href: seeAllHref
          }
        : {};

    return (
        <>
            {!loading && top3FeedImages?.length > 0 ? (
                <SimilarBlogsWrap>
                    <MultiCard
                        cards={[...top3FeedImages]}
                        lineTextBlock={{
                            headline,
                            button: seeAllButton
                        }}
                    />
                </SimilarBlogsWrap>
            ) : null}
        </>
    );
};

export default SimilarBlogs;
