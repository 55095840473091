import styled from 'styled-components';

export const Eula = styled.div`
    margin: 0 auto;
    ${(p) => p.theme.breakpoints.up('md')} {
        max-width: 1200px;
    }

    .loc-ja,
    .loc-ko,
    .loc-zh-CN {
        p b,
        p strong {
            font-weight: 700;
        }
    }

    h1 {
        font-family: ${(p) => p.theme.fonts.inter.regular};
        font-weight: 300;
        font-size: 54px;
        line-height: 66px;
        letter-spacing: -1px;

        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 48px;
            line-height: 56px;
        }
    }

    h2 {
        font-family: ${(p) => p.theme.fonts.inter.regular};
        font-weight: 300;
        font-size: 34px;
        line-height: 42px;
        letter-spacing: 0;

        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 30px;
            line-height: 38px;
        }
    }

    h3 {
        font-family: ${(p) => p.theme.fonts.inter.regular};
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0;
    }

    h4 {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 3px;
        text-transform: uppercase;

        &.loc-en-US {
            font-family: ${(p) => p.theme.fonts.fira.medium};
        }
        &.loc-ja {
            font-family: ${(p) => p.theme.fonts.notosans.japanese};
        }
        &.loc-ko {
            font-family: ${(p) => p.theme.fonts.notosans.korean};
        }
        &.loc-zh-CN {
            font-family: ${(p) => p.theme.fonts.notosans.chinese};
        }
        &.loc-ja,
        &.loc-zh-CN,
        &.loc-ko {
            font-size: 16px;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        color: rgb(255, 255, 255);
        margin: calc(12px + 1.05vw) 0px;
    }
`;
