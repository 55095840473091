import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    PageData,
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    getUrlPattern,
    useCmsData
} from 'epic-ue-shared';
import {
    CmsDynamicSectionProps,
    DynamicSections,
    Subnav,
    SubnavProps,
    TemplateThemeProps,
    VerticalSpacer,
    DoubleColumnHero,
    DoubleColumnHeroProps,
    LogoListProps,
    LogoList
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {UnrealLoading} from 'epic-ue-loading';
import ErrorPage from '../ErrorPage';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
    .paid-search-header-logos {
        .h2 {
            font-size: 26px;
            line-height: 34px;
            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 22px;
                line-height: 30px;
            }
        }
    }
`;

export interface PaidSearchPageSections {
    items?: CmsDynamicSectionProps[];
}

export interface PaidSearchPageData extends PageData {
    themeConfig: TemplateThemeProps;
    header: DoubleColumnHeroProps;
    logos: LogoListProps;
    subnav: SubnavProps;
    sections?: PaidSearchPageSections;
    bottomPageVerticalSpacer?: boolean;
}

interface Properties {
    cmsUrlPattern?: string;
}

const PaidSearchLandingPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<PaidSearchPageData>(urlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        header = {} as DoubleColumnHeroProps,
        logos = {} as LogoListProps,
        subnav = {} as SubnavProps,
        sections = {} as PaidSearchPageSections,
        bottomPageVerticalSpacer
    } = pageData;
    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const onClick = useCallback((): boolean => {
        emitEvent({
            interactionType: 'click',
            eventAction: 'link.click',
            eventLabel: header?.eventLabel || 'download',
            eventValue: 'undefined'
        });
        return true;
    }, [emitEvent]);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled className={theme}>
                {getHelmetForMetaTags(pageData)}
                <DoubleColumnHero {...header} onClick={onClick} accentColor={accentColor} />
                <VerticalSpacer variant="section" />
                {logos && (
                    <div className="paid-search-header-logos">
                        <LogoList {...logos} />
                        <VerticalSpacer variant="section" />
                    </div>
                )}
                <Subnav {...subnav} accentColor={accentColor} />
                <DynamicSections theme={theme} items={items} accentColor={accentColor} />
                {bottomPageVerticalSpacer && <VerticalSpacer variant="section" />}
            </Styled>
        </UeThemeProvider>
    );
};

PaidSearchLandingPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default PaidSearchLandingPage;
