import React, {useRef, useContext, useEffect} from 'react';

import {Page} from 'epic-ue-components';
import {useLocation, useParams} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import Message from '../components/message/Message';
import styled from 'styled-components';
import root from 'window-or-global';
import {Button} from 'epic-ue-ui';
import {getOptImage, useError404, useMiscStore} from 'epic-ue-shared';
import {HttpContext} from '../../server/utils/httpContext';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

const bgUniverseImg404 = 'https://cdn2.unrealengine.com/universe-png-3440x2274-15073ddb8dda.png';
const bgImg404 = '';

const Styled = styled.div`
    ${(p) => p.theme.breakpoints.up('md')} {
        background: url('${getOptImage({url: bgImg404, width: 1920})}') no-repeat;
        background-size: contain;
        background-position: center center;
        background-size: 1300px;
    }

    max-height: 100vh;
    overflow: hidden;
`;

const ErrorPageWrapper = styled.div`
    background-size: contain;
    background-position: center center;
    ${(p) => p.theme.breakpoints.down('md')} {
        background-size: cover;
    }
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: absolute;
    z-index: 2;

    ${(p) => p.theme.breakpoints.down('sm')} {
        background: url('${getOptImage({url: bgImg404, width: 800})}') no-repeat;
        height: 50vh;
        margin-top: 20px;
        min-height: 50vh;
        position: relative;
        background-position: center;
        background-size: cover;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
        background-size: 233vw;
        height: 63vh;
    }

    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        text-decoration: none;
    }

    .error-box.error-box-500 {
        h2 {
            font-size: 36px;
        }
    }

    #error-page-btn {
        margin-top: 2em;
        button {
            min-width: 50%;
            width: auto;
        }
        .underline.container {
            justify-content: center;
            width: auto;
        }
    }

    .error-box {
        width: 600px;
        margin: 0 auto;
        position: relative;
        flex: none;
        max-width: 50%;
        padding: 50px 55px 50px 35px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 330px;
            max-width: 100%;
            padding: 0;
        }

        .error-msg {
            padding: 2em 0 1em;
        }

        div {
            position: relative;
            text-align: center;

            h1 {
                font-size: 160px;
                color: ${(p) => p.theme.palette.text.secondary};
                font-weight: bold;
                display: inline-block;
                line-height: 70px;
                margin: 0;
                margin-bottom: 20px;
                font-family: Brutal, Brutal_Medium, Arial, sans-serif;
                text-align: left;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 100px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            h2 {
                margin-left: 10px;
                margin-bottom: 5px;
                font-size: 27px;
                color: ${(p) => p.theme.palette.text.secondary};
                font-weight: bold;
                display: inline-block;
                line-height: 40px;
                border-left: 1px solid ${(p) => p.theme.palette.text.secondary};
                padding-left: 10px;
                word-wrap: break-word;
                text-transform: uppercase;
                font-family: Brutal, Brutal_Medium, Arial, sans-serif;
                text-align: left;
            }
            h3 {
                font-size: 20px;
                color: ${(p) => p.theme.palette.text.secondary};
                font-weight: bold;
                line-height: 1;
                margin: 0;
                margin-bottom: 5px;
                text-align: left;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    text-align: center;
                    margin-bottom: 1em;
                }
            }

            p {
                color: ${(p) => p.theme.palette.text.secondary};
                font-size: 17px;
                margin: 0;
                padding: 0;
                text-align: center;
                line-height: 1.4em;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    line-height: 1.8em;
                }
            }
        }

        .btn.btn-primary,
        .ue-cta-button {
            margin: 20px 0;
            text-align: center;
            background: none;
            border: 2px solid;
            border-image: radial-gradient(100% 185.14% at 100% 100%, #34c3ff 0%, #338eee 100%) 5;
            border-image-width: 2px;
            transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99),
                margin 0.25s cubic-bezier(0, 0.42, 0.2, 0.99);
            &:hover,
            &:focus {
                border: 2px solid;
                border-image: radial-gradient(100% 185.14% at 100% 100%, #34c3ff 0%, #338eee 100%) 5;
                border-image-width: 2px;
                border-color: #00abf5;
                box-shadow: 0 0 18px #00abf5;
                background-color: transparent;
            }
        }
    }
`;

const UniverseBackgroundImages = styled.div`
    background: url('${getOptImage({url: bgUniverseImg404, width: 2600})}') no-repeat;
    background-size: contain;
    height: 10px;
    position: relative;
    background-position-x: center;
    background-position-y: center;
    min-width: 200vw;
    right: 50%;
    min-height: 200vh;
    top: -44vh;

    opacity: 0.7;

    right: 50%;
    ${(p) => p.theme.breakpoints.down('sm')} {
        background-size: 277vw;
        background-position-x: left;
        background-position-y: top;
        top: 50%;
        right: 1%;
    }
`;

interface ErrorPageProps {
    status?: number;
}

const ErrorPage = (props: ErrorPageProps): JSX.Element => {
    const {status} = props;
    const {pathname} = useLocation();
    const context = useContext(HttpContext);
    const params = useParams();
    const {referrer} = useMiscStore() as any;

    let error = status || +(params.error || 0);
    if (!error) {
        if (pathname.indexOf('error-500') !== -1 || pathname.indexOf('unexpectedError') !== -1) {
            error = 500;
        } else if (pathname.indexOf('error-404') !== -1) {
            error = 404;
        } else {
            error = 404;
        }
    }

    if (context) {
        context.status = error;
    }

    useEffect(() => {
        emitEvent({
            eventAction: 'error-page',
            eventLabel: error,
            interactionType: 'pageView',
            referringUrl: referrer
        } as any);
    }, [referrer, error]);

    useError404(error === 404 ? pathname : '');

    const errorWrapper = useRef<any>(null);

    const mouseMove = (e) => {
        if (errorWrapper?.current) {
            errorWrapper.current.style.transform = `translate(${
                (root.innerWidth - e.pageX) / 20
            }px,${(root.innerHeight - e.pageY) / 20}px)`;
        }
    };

    const title = <Message code={`epic.error.${error}.title1`} />;
    const subTitle = <Message code={`epic.error.${error}.title2`} />;
    const message = <Message code={`epic.error.${error}.msg`} />;
    const button = <Message code="epic.error.404.home" />;

    return (
        <Page>
            <Styled
                onMouseMove={(e) => {
                    mouseMove(e);
                }}>
                <ErrorPageWrapper>
                    <div id="ErrorPage" className="error-box">
                        <div>
                            <h1>{error}</h1>
                            <h2>
                                {title}
                                <br />
                                {subTitle}
                            </h2>
                            <div className="error-msg">
                                {error === 500 ? (
                                    <div>
                                        <h3>
                                            <Message code="epic.error.500.msg1" />
                                        </h3>
                                        <p>
                                            <Message code="epic.error.500.msg2" />
                                        </p>
                                    </div>
                                ) : (
                                    <p>{message}</p>
                                )}
                            </div>

                            <Button
                                href={generateRoutePath('/')}
                                type="primary"
                                id="error-page-btn"
                                color={'sky'}>
                                {button}
                            </Button>
                        </div>
                    </div>
                </ErrorPageWrapper>
                <UniverseBackgroundImages ref={errorWrapper} />
            </Styled>
        </Page>
    );
};

export default ErrorPage;
