import {useCallback, useEffect, useMemo, useState} from 'react';
import {isError} from '@epic-mw/error-types';
import {formApi} from './form.api';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys} from './sharedKeys';
import {AccountRes} from '../types';
import axios from 'axios';
import useSWRImmutable from 'swr/immutable';

export const newsletterAccountKey = createSharedValueKey<AccountRes>('newsletterAccount', {});
interface NewsletterAccountRes {
    account?: AccountRes;
    setAccount: (a: AccountRes) => void;
}
export const useNewsletterAccount = (): NewsletterAccountRes => {
    const [account, setAccount] = useSharedValue(newsletterAccountKey);

    return {
        account,
        setAccount
    };
};

export const useIsOptIn = (): {
    loaded: boolean;
    loading: boolean;
    error?: string;
    isOptIn?: boolean;
    optIn: (params?: {[p: string]: boolean | number | string}) => Promise<void>;
} => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [optInData, setOptInData] = useSharedValue(AppSharedKeys.OptInStore);
    const {account} = useNewsletterAccount();

    const optIn = useCallback(
        async (params: {[p: string]: boolean | number | string} = {}) => {
            try {
                setLoadingState({loading: true, loaded: false});

                await formApi.optIn();
                await formApi.submitData(params);
                setOptInData({
                    settingValue: true
                });
                setOptInData({settingValue: true});
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to subscribe to newsletter', ex);
                const error: string = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        },
        [optInData, loadingState, account]
    );

    useEffect(() => {
        if (
            typeof optInData.settingValue !== 'undefined' ||
            !account?.isLoggedIn ||
            account?.cabinedMode ||
            loadingState.error ||
            loadingState.loading
        ) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                setOptInData(await formApi.getOptIn());
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch opt in setting', ex);
                const error: string = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [loadingState, optIn, account]);

    return useMemo(() => {
        return {
            isOptIn: optInData.settingValue,
            optIn,
            ...loadingState
        };
    }, [loadingState.loading, loadingState.loaded]);
};

const getSelectData = async (url: string) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.error('getSelectData() failed to load', isError(err) && err.message);
        throw err;
    }
};

export const useSelectData = (url: string) => {
    const {data, error} = useSWRImmutable(url, getSelectData, {
        errorRetryCount: 0
    });

    return {
        data,
        isLoading: Boolean(!data && !error),
        error
    };
};
