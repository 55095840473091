import styled from 'styled-components';
import {PageData} from '@epic-mw/types';
import React from 'react';
import {
    ArticleContainer,
    ArticleSectionSelection,
    CmsDynamicMappingProps,
    GridContainer,
    HeroBasicProps,
    Typography,
    WithCaptionProps
} from 'epic-ue-ui';

import {TemplateThemeProps} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {
    CmsDynamicArray,
    DownloadSection,
    ImageProps,
    NewsletterSection,
    SubnavSection,
    SubnavSectionConfigProps,
    VerticalSpacer,
    ArticleSectionSelectionProps
} from 'epic-ue-ui';
import {AnimateOnViewWrap, ImageHero} from 'epic-ue-ui';
import {convertTagLabelToId, getHelmetForMetaTags} from 'epic-ue-shared';
import Helmet from 'react-helmet';
import SimilarBlogs, {SimilarBlogsProps} from '../similarblogs/SimilarBlogs';
import {DownloadWithNewsletterSectionProps} from '../../pages/stardust/ExplainersStardustPage';
import {useLocalization} from '@epic-mw/localization';
import {ImageAndTextCardLogoProps} from 'epic-ue-ui';
import {ArticleTags} from 'epic-ue-ui';
import {BlogTagFragment} from 'epic-ue-feed';

export interface NewsLandingConfigLargeProps {
    sticky?: boolean;
    image?: ImageProps;
    title?: string;
    buttonLabel?: string;
    logo?: ImageAndTextCardLogoProps;
    caption?: WithCaptionProps;
}

export interface NewsLandingConfigSmallProps extends NewsLandingConfigLargeProps {
    description?: string;
}

export type NewsLandingConfigOptions =
    | {large: NewsLandingConfigLargeProps}
    | {small: NewsLandingConfigSmallProps};

export type NewsLandingConfigOptionsArray = CmsDynamicArray<NewsLandingConfigOptions>;

export interface ArticleDetailPageData extends PageData {
    header?: HeroBasicProps;
    newsLandingConfig?: {
        options?: NewsLandingConfigOptionsArray;
    };
    content?: ArticleSectionSelectionProps;
    themeConfig?: TemplateThemeProps;
    title?: string;
    download: DownloadWithNewsletterSectionProps;
    similarBlogs?: SimilarBlogsProps;
    tags?: string;
    disableTagComponent?: boolean;
}

function getNewsLandingConfigProps(
    option?: CmsDynamicMappingProps<NewsLandingConfigOptions>
): NewsLandingConfigLargeProps | NewsLandingConfigSmallProps {
    if (!option) return {};
    if ('large' in option) {
        return option['large'] as NewsLandingConfigLargeProps;
    } else {
        return option['small'] as NewsLandingConfigSmallProps;
    }
}

const ArticleDetailContent = styled.div`
    .article-section {
        z-index: 2;
        position: relative;
    }
`;

const BlogDate = styled.div`
    color: rgba(255, 255, 255, 0.65);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    padding-block: 4px;
    display: block;
`;

interface Props {
    pageData: ArticleDetailPageData;
    locale: string;
    feedItemTags: BlogTagFragment[];
}

export default function ArticleDetailStardust({
    pageData,
    locale,
    feedItemTags
}: Props): JSX.Element {
    const pageDefaultedToEnglish = pageData._locale === 'en-US' && locale && locale !== 'en-US';
    const getMessage = useLocalization();

    const {
        themeConfig = {} as TemplateThemeProps,
        download = {} as DownloadWithNewsletterSectionProps,
        similarBlogs = {} as SimilarBlogsProps,
        disableTagComponent
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const ueTheme = theme === 'light' ? ueLightTheme : ueDarkTheme;
    const header = pageData?.header || {};

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const isBlankDownload = !downloadBlockProps?.cards || !downloadBlockProps?.cards?.length;

    const firstTag = feedItemTags[0] || {};
    let defaultSeeAll = '';
    if (firstTag && firstTag.tagId) {
        defaultSeeAll = `/${locale}/feed?tags=${convertTagLabelToId(firstTag.tagId)}`;
    }

    let captionText: string | undefined = undefined;
    const configOptions = pageData.newsLandingConfig?.options;
    if (configOptions && configOptions.length > 0) {
        const option = configOptions[0];
        const landingConfig = getNewsLandingConfigProps(option);
        captionText = landingConfig?.caption?.text;
    }

    const newsletterEl = (
        <NewsletterSection
            variant={download.newsletter?.variant || 'small-split'}
            {...download?.newsletter}
            privacyPolicyLinkName={
                download?.newsletter?.privacyPolicyLinkName || getMessage('epic.privacy.policy')
            }
            title={download?.newsletter?.title || getMessage('epic.ue.newsletter.title')}
            industryPlaceholder={
                download?.newsletter?.industryPlaceholder ||
                getMessage('epic.ue.newsletter.select.label')
            }
            accentColor={accentColor}
        />
    );

    const showTags = !disableTagComponent && feedItemTags && feedItemTags.length;
    const showSimilarBlogs = !similarBlogs?.disable;

    let date: Date | string = '';
    if (typeof pageData._activeDate === 'string') {
        date = new Date(pageData._activeDate);
        if (date) {
            date = date.toLocaleDateString(locale, {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
        }
    }

    return (
        <>
            {getHelmetForMetaTags(pageData)}
            {pageDefaultedToEnglish && (
                <Helmet
                    meta={[
                        {
                            property: 'og:locale',
                            content: pageData._locale
                        },
                        {
                            name: 'robots',
                            content: 'noindex, nofollow'
                        }
                    ]}
                />
            )}
            <UeThemeProvider theme={ueTheme}>
                <ArticleDetailContent className={`article-detail ${theme}`}>
                    <ImageHero
                        smallDescription={header?.dateStr}
                        showScrollIndicator
                        showSocialIcons
                        thin
                        alignment="centerXY"
                        image={header?.image?.src}
                        eyebrow={header?.eyebrow}
                        title={header?.title}
                        description={header?.description}
                        caption={captionText}
                        buttonText={header?.button1?.label}
                        href={header?.button1?.href}
                        secondaryButtonText={header?.button2?.label}
                        secondaryHref={header?.button2?.href}
                    />
                    <VerticalSpacer variant="eyebrow" />
                    <GridContainer>
                        <ArticleContainer>
                            <AnimateOnViewWrap delay={0.3} translateY={10}>
                                <Typography variant="p" caption color="secondary">
                                    <BlogDate>{date}</BlogDate>
                                </Typography>
                            </AnimateOnViewWrap>
                        </ArticleContainer>
                    </GridContainer>
                    <VerticalSpacer variant="eyebrow" />

                    {showTags ? (
                        <>
                            <AnimateOnViewWrap delay={0.3} translateY={10}>
                                <ArticleTags tags={feedItemTags} />
                            </AnimateOnViewWrap>
                            <VerticalSpacer variant="eyebrow" />
                        </>
                    ) : null}

                    <AnimateOnViewWrap delay={0.3} translateY={10}>
                        <div className="article-section">
                            <ArticleSectionSelection
                                {...pageData.content}
                                accentColor={accentColor}
                            />
                        </div>
                    </AnimateOnViewWrap>

                    {showSimilarBlogs ? (
                        <>
                            <VerticalSpacer variant="feature" />
                            <SimilarBlogs
                                {...similarBlogs}
                                tags={feedItemTags}
                                patternsToSkip={[pageData._urlPattern || '']}
                                seeAllHref={similarBlogs?.seeAllHref || defaultSeeAll}
                            />
                        </>
                    ) : null}

                    <VerticalSpacer variant="feature" />

                    {isBlankDownload ? (
                        newsletterEl
                    ) : (
                        <SubnavSection
                            variant="gradient"
                            hideBottomSectionPadding
                            sectionConfig={downloadSectionConfig}>
                            <DownloadSection
                                {...downloadBlockProps}
                                accentColor={accentColor}
                                variant="standard"
                            />
                            <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                            {newsletterEl}
                        </SubnavSection>
                    )}
                </ArticleDetailContent>
            </UeThemeProvider>
        </>
    );
}
