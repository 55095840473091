import React from 'react';
import styled from 'styled-components';
import {useBreakpointDown} from 'epic-ue-theme';
import {getOptImage} from 'epic-ue-shared';
import {CaretDown, GridContainer, Image, Typography, VerticalSpacer} from 'epic-ue-ui';
import {ContrastHeadlines} from './ContrastHeadlines';

const Styled = styled.div`
    width: 100vw;
    position: relative;
    min-height: 640px;
    height: calc(100dvh - 72px);
    &.has-scroll-hint {
        height: calc(100dvh - 8px);
    }

    > video {
        height: calc(100dvh - 72px);
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }

    > img {
        height: calc(100dvh - 72px);
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }

    .hero-gradient {
        position: absolute;
        bottom: 0;
        width: 100%;

        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 66dvh;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #150d20 100%);
        }
        height: 75dvh;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #150d20 100%);
    }

    .ue-grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 768px;
        ${(p) => p.theme.breakpoints.down('md')} {
            max-width: 720px;
        }
        height: calc(100% - 72px);
        height: calc(100dvh - 72px);
        min-height: 640px;
        position: relative;
    }

    .icon-row {
        display: flex;
        column-gap: 16px;
        justify-content: center;
    }

    .heading,
    .paragraph {
        position: relative;
    }

    h1 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 112px;
        line-height: 104px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 80px;
            line-height: 64px;
        }
    }

    h3 {
        text-align: center;
    }

    .ue-icon-caret-down {
        width: 38px;
        height: 38px;
        color: #e3c6fe;
    }
`;

export interface OffsetTitlesVideoHeroProps {
    topTitle?: string;
    bottomTitle?: string;
    dates?: string;
    description?: string;
    logo1?: string;
    logo2?: string;
    logo3?: string;
    desktopVideoBg?: string;
    desktopImageBg?: string;
    mobileImageBg?: string;
    disableScrollHint?: boolean;
}

export const OffsetTitlesVideoHero = ({
    topTitle,
    bottomTitle,
    dates,
    description,
    logo1,
    logo2,
    logo3,
    desktopVideoBg,
    desktopImageBg,
    mobileImageBg,
    disableScrollHint
}: OffsetTitlesVideoHeroProps): JSX.Element => {
    const isSm = useBreakpointDown('sm');
    return (
        <Styled
            className={`offset-titles-video-hero ${disableScrollHint ? '' : 'has-scroll-hint'}`}>
            {isSm ? (
                <img
                    src={mobileImageBg && getOptImage({url: mobileImageBg, width: 960})}
                    className={`bcfs-image`}
                    title={`${topTitle} ${bottomTitle}`}
                    alt={`${description ? description : ''}`}
                />
            ) : !desktopVideoBg ? (
                <img
                    src={desktopImageBg && getOptImage({url: desktopImageBg, width: 1920})}
                    className={`bcfs-image`}
                    title={`${topTitle} ${bottomTitle}`}
                    alt={`${description ? description : ''}`}
                />
            ) : (
                <video
                    id="bcfs-video"
                    title={`${topTitle} ${bottomTitle}`}
                    src={desktopVideoBg}
                    muted
                    autoPlay
                    loop
                    playsInline
                    {...{fetchpriority: 'high'}}
                />
            )}
            <div className="hero-gradient" />
            <GridContainer>
                <div className="icon-row">
                    <Image
                        height={isSm ? 32 : 40}
                        width={isSm ? 32 : 40}
                        fit={'fill'}
                        src={logo1}
                        disableRatioHelper={true}
                    />
                    <Image
                        height={isSm ? 32 : 40}
                        width={isSm ? 32 : 40}
                        fit={'fill'}
                        src={logo2}
                        disableRatioHelper={true}
                    />
                    <Image
                        height={isSm ? 32 : 40}
                        width={isSm ? 32 : 40}
                        fit={'fill'}
                        src={logo3}
                        disableRatioHelper={true}
                    />
                </div>
                <VerticalSpacer variant="custom" spacing={1} downSmSpacing={4} />
                <ContrastHeadlines
                    topHeadline={topTitle}
                    bottomHeadline={bottomTitle}
                    isAccentTop
                    isBig
                    isCentered
                />
                <VerticalSpacer variant="custom" spacing={8} downSmSpacing={7} />
                <Typography variant="h3" big>
                    {dates}
                </Typography>
                <VerticalSpacer variant="custom" spacing={2} downSmSpacing={2} />
                <Typography variant="h3">{description}</Typography>
                {!disableScrollHint ? (
                    <>
                        <VerticalSpacer variant="custom" spacing={5} downSmSpacing={2} />
                        <CaretDown />
                    </>
                ) : (
                    ''
                )}
            </GridContainer>
        </Styled>
    );
};
