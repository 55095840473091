import React from 'react';
import ErrorPage from './ErrorPage';
import MegaHeader from '../components/grants/MegaHeader';
import ProjectSlide from '../components/grants/ProjectSlide';
import {getBowserState} from '@epic-core/common';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';

import styled from 'styled-components';

const cmsUrlPattern = '/fast-track';

export const FastTrack = styled.div`
    color: ${(p) => p.theme.palette.text.primary};

    .paragraph {
        text-align: left;
        ${(p) => p.theme.breakpoints.down('sm')} {
            line-height: 1.7em;
        }
    }

    .grant-header {
        h1.title {
            width: 100%;
            max-width: initial;
            text-align: center;
        }
        h2.sub-title {
            text-align: center;
        }
        .intro-links-wrapper {
            ${(p) => p.theme.breakpoints.up('md')} {
                position: static;
                top: initial;
                bottom: initial;
            }
        }
        .intro-desc-section {
            ${(p) => p.theme.breakpoints.up('md')} {
                position: static;
            }

            div.intro-links-wrapper {
                display: block;
                text-align: center;
                margin: 0 auto;
                width: 100%;
            }
        }
    }

    .intro {
        padding: 50px 0 75px;
        text-align: center;
    }

    h1,
    h2 {
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        font-size: 2.7rem;
        margin-bottom: 2rem;
        text-align: center;
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 2em;
        }
    }

    .project-slide h3 {
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        margin-bottom: 2rem;
        font-size: 2em;
        text-transform: uppercase;
    }

    div.div {
        line-height: 1.8em;
        font-size: 1em;
    }

    .project-section {
        background: ${(p) => p.theme.palette.background.default};
        background-size: 100% auto;
        padding: 50px 0;
        overflow: hidden;
        p {
            max-width: 300px;
        }
    }

    .form-section {
        padding-top: 50px;
        padding-bottom: 4em;
    }

    .callout {
        text-align: center;
        margin: 50px 0;
    }

    .mkt-btn {
        display: inline-block;
    }
`;

export interface FastTrackPageData extends PageData {
    anchor: string;
    introTitle: string;
    introDesc: string;
    header: any;
    projectHeading: string;
    nextHeading: string;
    otherDesc: string;
    nextDescription: string;
    projectSlides: any;
}

const FastTrackPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<FastTrackPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const data = pageData || {};
    const introTitle = data.introTitle || '';
    const introDesc = data.introDesc || '';
    const header = data.header || {};
    const projectHeading = data.projectHeading || '';
    const otherDesc = data.otherDesc || '';
    const background = header.background || {};
    const projectSlidesWrap = data.projectSlides || {};
    const projectSlides = projectSlidesWrap.slides || {};
    const {mobile, tablet} = getBowserState();

    return (
        <Page>
            <FastTrack className="mega-grants">
                {getHelmetForMetaTags(pageData)}
                <div className="GP bootstrap-styles">
                    <MegaHeader
                        data={header}
                        mobile={mobile}
                        tablet={tablet}
                        background={background}
                    />
                    <div className="intro">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1>{introTitle}</h1>
                                    <div
                                        className="paragraph"
                                        dangerouslySetInnerHTML={{__html: introDesc}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="project-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h2>{projectHeading}</h2>
                                    {projectSlides !== undefined && projectSlides.length > 0 ? (
                                        projectSlides.map((projectSlide, index) => {
                                            const i = index;
                                            return (
                                                <ProjectSlide
                                                    key={`projectSlide-${i}`}
                                                    slide={projectSlide}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div />
                                    )}
                                    <div className="callout">{otherDesc}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FastTrack>
        </Page>
    );
};

FastTrackPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default FastTrackPage;
