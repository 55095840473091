import React from 'react';
import styled from 'styled-components';
import {CollapseProps} from 'epic-ue-shared';

const ListItem = styled.li`
    border: 1px solid #676767;
    border-radius: 4px;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
    .content {
        opacity: 0;
        transform: translateY(500px);
        transition: all 0.3s ease;
    }

    .description {
        height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    &.active {
        .content {
            opacity: 1;
            transform: translateY(0);
        }
        .description {
            height: auto;
            overflow: hidden;
        }
    }
`;

const Title = styled.div`
    padding: 1rem 0;
    border-bottom: 1px solid #676767;
    cursor: pointer;
    ${(p) => p.theme.breakpoints.down('sm')} {
        .col-1 {
            padding-left: 0;
        }
    }

    p {
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        font-size: 1.1em;
        color: #fff;
        margin: 0;
    }

    i {
        color: #fff;
        font-size: 1.5em;
        text-align: center;
        display: inline-block;
        transform: rotate(0);
        transition: transform 0.3s ease 0.2s;
        &.active {
            transform: rotate(180deg);
        }
    }
`;

const Description = styled.div`
    background-color: #161616;

    .content {
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        font-size: 18px;
        color: #fff;
        margin: 0;
        line-height: 1.5;
        padding: 1rem;
        opacity: 0;
        transform: translateY(-50px);
        transition: all 0.5s ease;

        a {
            color: ${(p) => p.theme.ue.colors.primaryBlue};
            text-decoration: none;
            outline: none;
            transition: color ease-out 100ms;
            font-weight: 600;

            &:hover {
                text-decoration: none;
                color: #28c1ff;
            }

            &:focus,
            &:active {
                outline: 0;
            }
        }
    }
`;

export const CollapseItem = ({title, desc, active, id, onClick}: CollapseProps): JSX.Element => {
    return (
        <ListItem className={`${active ? 'active' : ''}`}>
            <Title id={id} onClick={onClick}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xs-11 col-11">
                            <p>{title}</p>
                        </div>
                        <div className="col-xs-1 col-1">
                            <i className={`icon-chevron-down ${active ? 'active' : ''}`} />
                        </div>
                    </div>
                </div>
            </Title>
            <Description className="description">
                <div className="content" dangerouslySetInnerHTML={{__html: desc}} />
            </Description>
        </ListItem>
    );
};
