import React from 'react';
import styled from 'styled-components';
import {
    useCmsData,
    useEulaContent,
    InitSharedValueResponse,
    eulaApi,
    cmsApi,
    EulaContentResponse
} from '../shared';
import {getHelmetForMetaTags, RouteMatchOrUrlParse} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import {PathMatch, useParams} from 'react-router-dom';
import {AppSharedKeys} from 'epic-ue-shared';
import {PageData} from 'epic-ue-shared';
import {GridContainer, Typography, VerticalSpacer} from 'epic-ue-ui';
import {Eula} from './Eula.styles';
import {determineEulaType, determineEulaRefLocRedirect} from '../utils/eulaUtils';
import ErrorPage from './ErrorPage';
import {extractSlugLocale} from '@epic-mw/localization';
import {getLocale} from '@epic-core/common';
import {Redirect} from '../routes/Redirect';

const Header = styled.div`
    text-align: center;
    margin-bottom: 3em;
`;

export interface EulaPageData extends PageData {
    changeLog: string;
    disclaimer: string;
    content?: string;
}

const EulaPage = (): JSX.Element => {
    const params = useParams();
    const locale = getLocale();
    const {redirectUrlPattern} = determineEulaRefLocRedirect(params);
    const {cmsUrlPattern, eulaKey, outsideOfEulaService, isLocalized} = determineEulaType(
        params,
        'eula'
    );
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<EulaPageData>(cmsUrlPattern);
    const eulaData = useEulaContent(eulaKey, isLocalized ? locale : 'en');
    const {loading: eulaLoading, eula = {} as EulaContentResponse} = eulaData;

    const {pageSlug} = extractSlugLocale(location.pathname, locale);

    if (redirectUrlPattern && redirectUrlPattern !== pageSlug) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />
                <Redirect to={redirectUrlPattern} replace />
            </>
        );
    }

    if (!eulaKey || !cmsUrlPattern) {
        return <ErrorPage status={404} />;
    }

    if ((!outsideOfEulaService && eulaLoading) || cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {title, body} = eula;
    const {disclaimer, changeLog} = pageData;

    return (
        <Eula>
            {getHelmetForMetaTags(pageData)}
            <GridContainer>
                <Header>
                    {disclaimer ? (
                        <>
                            <VerticalSpacer variant="heading" />
                            <Typography variant="p" color="secondary" caption>
                                {disclaimer}
                            </Typography>
                        </>
                    ) : (
                        ''
                    )}
                    <Typography variant="h3" as="h1">
                        {title}
                    </Typography>
                    <Typography variant="p" color="hint" caption>
                        {changeLog}
                    </Typography>
                </Header>
                <Typography variant="p" color="secondary">
                    {outsideOfEulaService && pageData.content ? pageData.content : body}
                </Typography>
                <VerticalSpacer variant="section" />
            </GridContainer>
        </Eula>
    );
};

EulaPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const pathMatch = match as PathMatch;
    const defaultLocale = 'en-US';
    const locale = pathMatch.params?.locale || defaultLocale;

    const {cmsUrlPattern, eulaKey, outsideOfEulaService, isLocalized} = determineEulaType(
        pathMatch?.params,
        'eula'
    );

    const promises: Promise<any>[] = [];
    if (cmsUrlPattern) {
        promises.push(await cmsApi.getPageData(cmsUrlPattern));
    }
    if (eulaKey && !outsideOfEulaService) {
        promises.push(await eulaApi.getEulaData(eulaKey, isLocalized ? locale : defaultLocale));
    }

    const responses = await Promise.all(promises);

    return [
        {
            key: AppSharedKeys.EulaStore,
            value: {
                [eulaKey]: responses[1] || {}
            }
        },
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: responses[0] || {}
            }
        }
    ];
};

export default EulaPage;
