import {GraphQlService} from '@epic-node-services/graphql-public-service';
import {isError} from '@epic-mw/error-types';
import {AccountRes} from '../types/account.types';

const ACCOUNT_QUERY = `
{
    Account {
        myAccount(mask: true) {
            country
            displayName
            email
            id
            isLoggedIn
            preferredLanguage
            cabinedMode
            name
            lastName
        }
    }
}
`;

interface AccountQueryRes {
    data: {
        Account: {
            myAccount: AccountRes;
        };
    };
}

class AccountApi {
    public async getAccount(): Promise<AccountRes> {
        try {
            const response: AccountQueryRes = await GraphQlService.query(ACCOUNT_QUERY);
            const data = response.data || {};
            const account = data.Account || {};
            const myAccount = account.myAccount || {};
            return myAccount;
        } catch (e) {
            console.error('getAccount() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const accountApi = new AccountApi();
