import React from 'react';
import {RouteObject, useRoutes} from 'react-router';
import {getPath} from '@epic-core/common';
import Ue5LandingPage from '../pages/stardust/Ue5LandingPage';
import GeneralLandingStardustPage from '../pages/stardust/GeneralLandingStardustPage';
import EducatorsStardustPage from '../pages/stardust/EducatorsStardustPage';
import EulaPage from '../pages/EulaPage';
import EulaReferencePage from '../pages/EulaReferencePage';
import StudentsStardustPage from '../pages/stardust/StudentsStardustPage';
import FAQStardustPage from '../pages/stardust/FAQStardustPage';
import OnlineLearningStardustPage from '../pages/stardust/OnlineLearningStardustPage';
import PartnerApplyStardustPage from '../pages/stardust/PartnerApplyStardustPage';
import ReleaseNotesStardustPage from '../pages/stardust/ReleaseNotesStardustPage';
import PaidSearchLandingPage from '../pages/stardust/PaidSearchLandingPage';
import {AppSharedKeys, getSharedContext, RouteMatchOrUrlParse} from 'epic-ue-shared';
import URLParse from 'url-parse';
import {InitSharedValueResponse} from '../shared';
import {getAppRedirects} from './appRedirects';
import {getClassicRoutes} from './classicRoutes';
import MegagrantsStardustPage from '../pages/stardust/MegagrantsStardustPage';
import SearchPage from '../pages/stardust/SearchPage';
import {Redirect, RedirectProps} from './Redirect';
import LinuxPage from '../pages/stardust/LinuxStardustPage';
import ExplainersPage from '../pages/stardust/ExplainersStardustPage';
import ExplainersTopicPage from '../pages/stardust/ExplainersTopicStardustPage';
import ExplainersDetailPage from '../pages/stardust/ExplainersDetailStardustPage';
import {TechBlogPage, DeveloperInterviewPage, SpotlightPage} from '../pages/FeedDetailsPage';
import BlogPage from '../pages/BlogPage';
import EventPageContainer from '../pages/EventPage.container';
import NewsletterPage from '../pages/stardust/NewsletterPage';
import HubLandingPage from '../pages/stardust/HubLandingStardustPage';
import CreatorSalePage from '../pages/stardust/CreatorSalePage';

export type GetInitialSharedValues = (
    match: RouteMatchOrUrlParse,
    url?: URLParse<{[key: string]: string | undefined}>
) =>
    | Promise<InitSharedValueResponse[]>
    | ((match: RouteMatchOrUrlParse) => Promise<InitSharedValueResponse[]>)
    | (() => Promise<InitSharedValueResponse[]>);

export interface ServerRoute {
    getInitialSharedValues?: GetInitialSharedValues;
    path?: string;
    element?: React.ReactNode | null;
}

export interface ConnectedPage {
    (args?: any): JSX.Element;
    getInitialSharedValues?: GetInitialSharedValues;
}

const getStardustPages = (): ServerRoute[] => {
    const context = getSharedContext();
    if (!context) return [];
    const miscStore = context.get(AppSharedKeys.MiscStore);
    const stardustPages = miscStore?.stardustPages || [];
    const routes: ServerRoute[] = [];
    const path = getPath();

    stardustPages.forEach((page) => {
        if (!page) {
            return;
        }

        let getInitialSharedValues = GeneralLandingStardustPage.getInitialSharedValues;
        let element = <GeneralLandingStardustPage />;
        if (page.template === 'MEGAGRANTS') {
            element = <MegagrantsStardustPage />;
            getInitialSharedValues = MegagrantsStardustPage.getInitialSharedValues;
        } else if (page.template === 'HUB_LANDING') {
            element = <HubLandingPage />;
            getInitialSharedValues = HubLandingPage.getInitialSharedValues;
        }

        routes.push({
            path: `${path}:locale${page.urlPattern}`,
            element,
            getInitialSharedValues
        });
    });

    return routes;
};

const getCosmosRedirects = (): ServerRoute[] => {
    const context = getSharedContext();
    if (!context) return [];

    let routes: ServerRoute[] = [];

    const path = getPath();

    routes = [
        {
            path: `${path}:locale/educators`,
            element: <Redirect to="/students-and-schools" status={301} />
        },
        {
            path: `${path}:locale/students`,
            element: <Redirect to="/students-and-schools" status={301} />
        },
        {
            path: `${path}:locale/uefn-unreal-editor-for-fortnite`,
            element: <Redirect to="/uses/uefn-unreal-editor-for-fortnite" status={301} />
        },
        {
            path: `${path}:locale/learn`,
            element: (
                <Redirect
                    url="https://dev.epicgames.com/community/unreal-engine/learning"
                    status={301}
                />
            )
        }
    ];

    console.log('getCosmosRedirects() cosmos redirects appended!');

    return routes;
};

const getStardustRedirects = (): ServerRoute[] => {
    const context = getSharedContext();
    if (!context) return [];
    const miscStore = context.get(AppSharedKeys.MiscStore);
    const stardustRedirects = miscStore?.stardustRedirects || [];
    const routes: ServerRoute[] = [];
    const path = getPath();

    stardustRedirects.forEach((page) => {
        if (!page) {
            return;
        }

        let from = page.from || '';
        //prefix a slash if not found
        if (!/^\//.test(from)) {
            from = `/${from}`;
        }

        const to = page.to || '';
        const url = page.url || '';
        const permanent = page.permanent;

        if (!from || (!to && !url)) {
            return;
        }

        const props: RedirectProps = {};
        if (to) {
            props.to = to;
        }
        if (url) {
            props.url = url;
        }

        routes.push({
            path: `${path}:locale${page.from}`,
            element: <Redirect {...props} status={permanent ? 301 : 302} replace={props.replace} />
        });
    });

    return routes;
};

const getFirstPriorityRoutes = () => {
    const redirects = getAppRedirects();
    const stardustRedirects = getStardustRedirects();
    const stardustPages = getStardustPages();
    const cosmosRedirects = getCosmosRedirects();

    const allRedirects = redirects.concat(stardustRedirects).concat(cosmosRedirects);
    return allRedirects.concat(stardustPages);
};

/**
 * All app routes are defined here and have an additional property for determining
 * what data to load on the server and init into shared values
 */
export const getServerRoutes = (): ServerRoute[] => {
    const path = getPath();

    const classicRoutes = getClassicRoutes();
    const firstRoutes = getFirstPriorityRoutes();

    const stardustRoutes = firstRoutes.concat([
        {
            path: `${path}:locale/project-anywhere`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/ue-dynamic-qatest`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/ue-on-github`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/unreal-engine-5`,
            getInitialSharedValues: Ue5LandingPage.getInitialSharedValues,
            element: <Ue5LandingPage />
        },
        {
            path: `${path}:locale/unreal-futures`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/bridge`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/digital-humans`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/fashion`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/hmi`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/metahuman`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/connectors`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/nextgen`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/test-multicard-video-do-not-publish`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/fellowship`,
            getInitialSharedValues: MegagrantsStardustPage.getInitialSharedValues,
            element: <MegagrantsStardustPage />
        },
        {
            path: `${path}:locale/megagrants`,
            getInitialSharedValues: MegagrantsStardustPage.getInitialSharedValues,
            element: <MegagrantsStardustPage />
        },
        {
            path: `${path}:locale/animation-field-guide`,
            getInitialSharedValues: MegagrantsStardustPage.getInitialSharedValues,
            element: <MegagrantsStardustPage />
        },
        {
            path: `${path}ja/onlinelearning-courses`,
            getInitialSharedValues: OnlineLearningStardustPage.getInitialSharedValues,
            element: <OnlineLearningStardustPage />
        },
        {
            path: `${path}ko/onlinelearning-courses`,
            getInitialSharedValues: OnlineLearningStardustPage.getInitialSharedValues,
            element: <OnlineLearningStardustPage />
        },
        {
            path: `${path}zh-CN/onlinelearning-courses`,
            getInitialSharedValues: OnlineLearningStardustPage.getInitialSharedValues,
            element: <OnlineLearningStardustPage />
        },
        {
            path: `${path}:locale/virtual-production`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/creators`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/training-partners-apply`,
            getInitialSharedValues: PartnerApplyStardustPage.getInitialSharedValues,
            element: <PartnerApplyStardustPage />
        },
        {
            path: `${path}:locale/academic-partners-apply`,
            getInitialSharedValues: MegagrantsStardustPage.getInitialSharedValues,
            element: <MegagrantsStardustPage />
        },
        {
            path: `${path}:locale/authorized-instructor-apply`,
            getInitialSharedValues: PartnerApplyStardustPage.getInitialSharedValues,
            element: <PartnerApplyStardustPage />
        },
        {
            path: `${path}:locale/try-free/:slug`,
            getInitialSharedValues: PaidSearchLandingPage.getInitialSharedValues,
            element: <PaidSearchLandingPage />
        },
        {
            path: `${path}:locale/updates`,
            getInitialSharedValues: ReleaseNotesStardustPage.getInitialSharedValues,
            element: <ReleaseNotesStardustPage />
        },
        {
            path: `${path}:locale/updates/:slug`,
            getInitialSharedValues: ReleaseNotesStardustPage.getInitialSharedValues,
            element: <ReleaseNotesStardustPage />
        },
        {
            path: `${path}:locale/faq`,
            getInitialSharedValues: FAQStardustPage.getInitialSharedValues,
            element: <FAQStardustPage />
        },
        {
            path: `${path}:locale/service-partner-program`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/community`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/learn`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/unreal-indies`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/students`,
            getInitialSharedValues: StudentsStardustPage.getInitialSharedValues,
            element: <StudentsStardustPage />
        },
        {
            path: `${path}:locale/educators/accelerator`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/educators`,
            getInitialSharedValues: EducatorsStardustPage.getInitialSharedValues,
            element: <EducatorsStardustPage />
        },
        {
            path: `${path}:locale/eula/:eulaDesc`,
            getInitialSharedValues: EulaPage.getInitialSharedValues,
            element: <EulaPage />
        },
        {
            path: `${path}:locale/eula-reference/:refSlug`,
            getInitialSharedValues: EulaReferencePage.getInitialSharedValues,
            element: <EulaReferencePage />
        },
        {
            path: `${path}:locale/search`,
            getInitialSharedValues: SearchPage.getInitialSharedValues,
            element: <SearchPage />
        },
        {
            path: `${path}:locale/bing-search`,
            getInitialSharedValues: SearchPage.getInitialSharedValues,
            element: <SearchPage />
        },
        {
            path: `${path}:locale/linux`,
            getInitialSharedValues: LinuxPage.getInitialSharedValues,
            element: <LinuxPage />
        },
        {
            path: `${path}:locale/explainers`,
            getInitialSharedValues: ExplainersPage.getInitialSharedValues,
            element: <ExplainersPage />
        },
        {
            path: `${path}:locale/explainers/:topic`,
            getInitialSharedValues: ExplainersTopicPage.getInitialSharedValues,
            element: <ExplainersTopicPage />
        },
        {
            path: `${path}:locale/explainers/:topic/:slug`,
            getInitialSharedValues: ExplainersDetailPage.getInitialSharedValues,
            element: <ExplainersDetailPage />
        },
        {
            path: `${path}:locale/blog/:title`,
            getInitialSharedValues: BlogPage.getInitialSharedValues,
            element: <BlogPage />
        },
        {
            path: `${path}:locale/spotlights/:slug`,
            getInitialSharedValues: SpotlightPage.getInitialSharedValues,
            element: <SpotlightPage rootPageSlug="spotlights" />
        },
        {
            path: `${path}:locale/developer-interviews/:slug`,
            getInitialSharedValues: DeveloperInterviewPage.getInitialSharedValues,
            element: <DeveloperInterviewPage rootPageSlug="developer-interviews" />
        },
        {
            path: `${path}:locale/tech-blog/:slug`,
            getInitialSharedValues: TechBlogPage.getInitialSharedValues,
            element: <TechBlogPage rootPageSlug="tech-blog" />
        },
        {
            path: `${path}:locale/events/:title`,
            getInitialSharedValues: EventPageContainer.getInitialSharedValues,
            element: <EventPageContainer />
        },
        {
            path: `${path}:locale/newsletter`,
            getInitialSharedValues: NewsletterPage.getInitialSharedValues,
            element: <NewsletterPage />
        },
        {
            path: `${path}:locale/springcreatorsale`,
            getInitialSharedValues: CreatorSalePage.getInitialSharedValues,
            element: <CreatorSalePage />
        },
        {
            path: `${path}:locale/qa-test/faq`,
            getInitialSharedValues: FAQStardustPage.getInitialSharedValues,
            element: <FAQStardustPage />
        },
        {
            path: `${path}:locale/qa-test/blog/:title`,
            getInitialSharedValues: BlogPage.getInitialSharedValues,
            element: <BlogPage />
        },
        {
            path: `${path}:locale/qa-test/general-landing`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        },
        {
            path: `${path}:locale/qa-test/general-landing/:title`,
            getInitialSharedValues: GeneralLandingStardustPage.getInitialSharedValues,
            element: <GeneralLandingStardustPage />
        }
    ]);

    const serverRoutes = stardustRoutes.concat(classicRoutes);
    return serverRoutes;
};

/**
 * Returns routes in an array that react-router expects, dropping the extra fields used for the server render
 */
export const getRoutes = (): RouteObject[] => {
    const serverRoutes = getServerRoutes();
    const routes = serverRoutes.map((route) => {
        return {
            path: route.path,
            element: route.element
        };
    });

    return routes;
};

export const useRoutesElement = (): React.ReactElement | null => {
    return useRoutes(getRoutes());
};

export default getRoutes;
