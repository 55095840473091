import {getPath} from '@epic-core/common';
import React from 'react';
import {Redirect} from './Redirect';
import {ServerRoute} from './routes';
import {getOnlineLearningRedirects} from './onlineLearningRedirects';
import {getReleaseNotesRedirects} from './releaseNotesRedirects';
import {getTwinmotionRedirects} from './twinmotionRedirects';
import root from 'window-or-global';

export const getAppRedirects = (): ServerRoute[] => {
    const path = getPath();
    const onlineLearningRedirects = getOnlineLearningRedirects();
    const releaseNotesRedirects = onlineLearningRedirects.concat(getReleaseNotesRedirects());
    const twinmotionRedirects = releaseNotesRedirects.concat(getTwinmotionRedirects());

    const clientConfig = root.clientEnvConfig || {};
    const EPIC_SERVER_URL = clientConfig && clientConfig.EPIC_SERVER_URL;
    if (!EPIC_SERVER_URL) {
        throw new Error('routes missing EPIC_SERVER_URL');
    }
    const serverUrl = EPIC_SERVER_URL;

    return twinmotionRedirects.concat([
        {
            path: `${path}:locale/virtual-production`,
            element: <Redirect to="/uses/film-television" status={301} />
        },
        {
            path: `${path}:locale/solutions/games/indie-games`,
            element: <Redirect to="/uses/indie-games" status={301} />
        },
        {
            path: `${path}:locale/solutions/games/mobile-games`,
            element: <Redirect to="/uses/mobile-games" status={301} />
        },
        {
            path: `${path}:locale/uses/games/indie-games`,
            element: <Redirect to="/uses/indie-games" status={301} />
        },
        {
            path: `${path}:locale/uses/games/mobile-games`,
            element: <Redirect to="/uses/mobile-games" status={301} />
        },
        {
            path: `${path}:locale/uses/automotive-transportation`,
            element: <Redirect to="/uses/automotive" status={301} />
        },
        {
            path: `${path}:locale/solutions/more-uses`,
            element: <Redirect to="/" status={301} />
        },
        {
            path: `${path}:locale/uses/more-uses`,
            element: <Redirect to="/" status={301} />
        },
        {
            path: `${path}:locale/solutions/:title`,
            element: <Redirect to="/uses/:title" status={301} />
        },
        {
            path: `${path}:locale/metahuman-creator`,
            element: <Redirect to="/metahuman" status={301} replace />
        },
        {
            path: `${path}:locale/unreal`,
            element: <Redirect to="/unreal-engine-5" />
        },
        {
            path: `${path}:locale/login`,
            element: <Redirect url={`${serverUrl}/login`} replace />
        },
        {
            path: `${path}:locale/custom-license`,
            element: <Redirect to="/license#contact-us-form" status={301} replace omitSearch />
        },
        {
            path: `${path}:locale/custom-licensing`,
            element: <Redirect to="/license#contact-us-form" status={301} replace />
        },
        {
            path: `${path}:locale/blog/humber-college-draws-attention-to-real-issues-with-unreal-engine`,
            element: (
                <Redirect to="/blog/humber-college-uses-unreal-engine-to-shed-light-on-important-issues" />
            )
        },
        {
            path: `${path}:locale/blog/oodle-now-available-in-unreal-engine-4-27-through-github-access`,
            element: <Redirect to="/blog/oodle-now-free-to-use-in-unreal-engine-via-github" />
        },
        {
            path: `${path}:locale/blog/epic-online-services-launches-two-new-free-services`,
            element: (
                <Redirect
                    url="https://dev.epicgames.com/:locale/news/epic-online-services-launches-two-new-free-services"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/blog/epic-online-services-featuring-epic-account-and-game-services`,
            element: (
                <Redirect
                    url="https://dev.epicgames.com/:locale/news/epic-online-services-featuring-epic-account-and-game-services"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/creators-ru`,
            element: <Redirect to="/eula/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/publishing-ru`,
            element: <Redirect to="/eula/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/creators-pt-br`,
            element: <Redirect to="/eula/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/publishing-pt-br`,
            element: <Redirect to="/eula/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/creators-ja`,
            element: <Redirect to="/eula-reference/unreal-ja" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/publishing-ja`,
            element: <Redirect to="/eula-reference/unreal-ja" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/creators-ko`,
            element: <Redirect to="/eula-reference/unreal-ko" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/publishing-ko`,
            element: <Redirect to="/eula-reference/unreal-ko" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/creators-zh-cn`,
            element: <Redirect to="/eula-reference/unreal-zh-cn" status={301} />
        },
        {
            path: `${path}:locale/eula-reference/publishing-zh-cn`,
            element: <Redirect to="/eula-reference/unreal-zh-cn" status={301} />
        },
        {
            path: `${path}:locale/eulacheck/creators`,
            element: <Redirect to="/eulacheck/unreal" status={301} />
        },
        {
            path: `${path}:locale/eulacheck/publishing`,
            element: <Redirect to="/eulacheck/unreal" status={301} />
        },
        {
            path: `${path}:locale/eulaprompt/creators`,
            element: <Redirect to="/eulaprompt/unreal" status={301} />
        },
        {
            path: `${path}:locale/eulaprompt/publishing`,
            element: <Redirect to="/eulaprompt/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-change-log/publishing`,
            element: <Redirect to="/eula-change-log/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula-change-log/creators`,
            element: <Redirect to="/eula-change-log/unreal" status={301} />
        },
        {
            path: `${path}:locale/eula/publishing`,
            element: <Redirect to="/eula/unreal" status={301} replace />
        },
        {
            path: `${path}:locale/eula/creators`,
            element: <Redirect to="/eula/unreal" status={301} replace />
        },
        {
            path: `${path}:locale/animation`,
            element: <Redirect to="/uses/animation" status={301} />
        },
        {
            path: `${path}:locale/broadcast`,
            element: <Redirect to="/uses/broadcast-live-events" status={301} />
        },
        {
            path: `${path}:locale/uses/hmi`,
            element: <Redirect to="/hmi" status={301} />
        },
        {
            path: `${path}:locale/uses/project-anywhere`,
            element: <Redirect to="/project-anywhere" status={301} />
        },
        {
            path: `${path}:locale/games`,
            element: <Redirect to="/uses/games" />
        },
        {
            path: `${path}:locale/automotive`,
            element: <Redirect to="/uses/automotive-transportation" />
        },
        {
            path: `${path}:locale/media-entertainment`,
            element: <Redirect to="/uses/film-television" />
        },
        {
            path: `${path}:locale/film-tv`,
            element: <Redirect to="/uses/film-television" />
        },
        {
            path: `${path}:locale/simulations`,
            element: <Redirect to="/uses/simulation" />
        },
        {
            path: `${path}:locale/training-simulation`,
            element: <Redirect to="/uses/simulation" />
        },
        {
            path: `${path}:locale/uses/training-simulation`,
            element: <Redirect to="/uses/simulation" status={301} />
        },
        {
            path: `${path}:locale/architecture`,
            element: <Redirect to="/uses/architecture" />
        },
        {
            path: `${path}:locale/industry/digital-twins`,
            element: <Redirect to="/digital-twins" />
        },
        {
            path: `${path}:locale/digital-humans`,
            element: <Redirect to="/metahuman" status={301} replace />
        },
        {
            path: `${path}:locale/gdc2018`,
            element: <Redirect to="/events/gdc2018" status={301} />
        },
        {
            path: `${path}:locale/stateofunreal`,
            element: <Redirect to="/events/state-of-unreal" status={301} replace />
        },
        {
            path: `${path}:locale/state-of-unreal`,
            element: <Redirect to="/events/state-of-unreal" status={301} replace />
        },
        {
            path: `${path}:locale/thepulse`,
            element: <Redirect to="/events/the-pulse" status={301} />
        },
        {
            path: `${path}:locale/vp-week`,
            element: <Redirect to="/events/virtual-production-week-2022" />
        },
        {
            path: `${path}:locale/webinars`,
            element: <Redirect to="/events/webinar-series" />
        },
        {
            path: `${path}:locale/events/enterprise-webinar-series`,
            element: <Redirect to="/events/webinar-series" />
        },
        {
            path: `${path}:locale/BA21`,
            element: <Redirect to="/events/build-architecture-2021" status={301} />
        },
        {
            path: `${path}:locale/ba21`,
            element: <Redirect to="/events/build-architecture-2021" status={301} />
        },
        {
            path: `${path}:locale/future-of-animation`,
            element: <Redirect to="/events/future-of-animation" status={301} />
        },
        {
            path: `${path}:locale/viitsec2020`,
            element: <Redirect to="/events/viitsec2020" status={301} />
        },
        {
            path: `${path}:locale/account-help`,
            element: <Redirect url="http://help.epicgames.com" status={301} />
        },
        {
            path: `${path}:locale/register`,
            element: <Redirect to="/download" status={301} />
        },
        {
            path: `${path}:locale/download/creators`,
            element: <Redirect to="/download" status={301} />
        },
        {
            path: `${path}:locale/download/publishing`,
            element: <Redirect to="/download" status={301} />
        },
        {
            path: `${path}:locale/showcase/:title`,
            element: <Redirect to="/blog/:title" status={301} />
        },
        {
            path: `${path}:locale/showcase/:articleLocale/:title`,
            element: <Redirect to="/blog/:title" status={301} />
        },
        {
            path: `${path}:locale/enterprise/news`,
            element: <Redirect to="/feed?tags=news" status={301} />
        },
        {
            path: `${path}:locale/enterprise/news/:title`,
            element: <Redirect to="/blog/:title" status={301} />
        },
        {
            path: `${path}:locale/enterprise/blog/:title`,
            element: <Redirect to="/blog/:title" status={301} />
        },
        {
            path: `${path}:locale/enterprise/blog`,
            element: <Redirect to="/feed?tags=news" status={301} />
        },
        {
            path: `${path}:locale/home`,
            element: <Redirect to="/" status={301} />
        },
        {
            path: `${path}:locale/news`,
            element: <Redirect to="/feed?tags=news" />
        },
        {
            path: `${path}:locale/showcase`,
            element: <Redirect to="/feed" status={301} />
        },
        {
            path: `${path}:locale/blog/category/:category`,
            element: <Redirect to="/feed" status={301} />
        },
        {
            path: `${path}:locale/video-tutorials`,
            element: <Redirect url="https://learn.unrealengine.com" status={301} />
        },
        {
            path: `${path}:locale/console-request`,
            element: (
                <Redirect
                    url="https://epicgames.secure.force.com/Forms/FormConsoleAccessRequest"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/unrealdevgrants`,
            element: <Redirect to="/megagrants" status={301} />
        },
        {
            path: `${path}:locale/programs/virtual-production`,
            element: <Redirect to="/virtual-production" />
        },
        {
            path: `${path}:locale/vr`,
            element: <Redirect to="/xr" status={301} replace />
        },
        {
            path: `${path}:locale/ar`,
            element: <Redirect to="/xr" status={301} replace />
        },
        {
            path: `${path}:locale/student-showcase`,
            element: <Redirect to="/events/student-showcase" status={301} />
        },
        {
            path: `${path}:locale/blog`,
            element: <Redirect to="/feed" status={301} />
        },
        {
            path: `${path}:locale/feed/news`,
            element: <Redirect to="/feed?tags=news" status={301} />
        },
        {
            path: `${path}:locale/industries/automotive`,
            element: <Redirect to="/uses/automotive-transportation" status={301} replace />
        },
        {
            path: `${path}:locale/industries/media-entertainment`,
            element: <Redirect to="/uses/film-television" />
        },
        {
            path: `${path}:locale/industries/:industry`,
            element: <Redirect to="/uses/:industry" replace />
        },
        {
            path: `${path}:locale/industry/:industry`,
            element: <Redirect to="/uses/:industry" status={301} replace />
        },
        {
            path: `${path}:locale/industries`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/industry`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/eula-reference`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/eula-chn`,
            element: <Redirect to="/eula-reference/unreal-zh-cn" status={301} replace />
        },
        {
            path: `${path}:locale/eula-japanese`,
            element: <Redirect to="/eula-reference/unreal-ja" status={301} replace />
        },
        {
            path: `${path}:locale/eula-ko`,
            element: <Redirect to="/eula-reference/unreal-ko" status={301} replace />
        },
        {
            path: `${path}:locale/events/secondary-accelerator`,
            element: <Redirect to="/educators/accelerator" status={301} replace />
        },
        {
            path: `${path}:locale/korean-eula`,
            element: <Redirect to="/eula-reference/unreal-ko" status={301} replace />
        },
        {
            path: `${path}:locale/eula-portuguese-br`,
            element: <Redirect to="/eula-reference/publishing-pt-br" status={301} replace />
        },
        {
            path: `${path}:locale/eula-ru`,
            element: <Redirect to="/eula-reference/publishing-ru" status={301} replace />
        },
        {
            path: `${path}:locale/service-partners`,
            element: <Redirect to="/service-partner-program" status={301} replace />
        },
        {
            path: `${path}:locale/academic-partners`,
            element: <Redirect url="https://www.artstation.com/schools" status={301} />
        },
        {
            path: `${path}:locale/training-partners`,
            element: <Redirect url="https://www.artstation.com/schools" status={301} />
        },
        {
            path: `${path}:locale/showdown-eula`,
            element: <Redirect to="/eula/showdown" status={301} replace />
        },
        {
            path: `${path}:locale/bullet-train/eula`,
            element: <Redirect to="/eula/bullet-train" status={301} replace />
        },
        {
            path: `${path}:locale/studio/eula`,
            element: <Redirect to="/eula/creators" status={301} replace />
        },
        {
            path: `${path}:locale/studio/eula-chn`,
            element: <Redirect to="/eula-reference/creators-zh-cn" status={301} replace />
        },
        {
            path: `${path}:locale/architecture-solution`,
            element: <Redirect to="/uses/architecture" />
        },
        {
            path: `${path}:locale/uses/more`,
            element: <Redirect to="/uses/more-uses" status={301} replace />
        },
        {
            path: `${path}:locale/uses`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/ue4-on-github`,
            element: <Redirect to="/ue-on-github" status={301} />
        },
        {
            path: `${path}:locale/ue5`,
            element: <Redirect to="/unreal-engine-5" status={301} />
        },
        {
            path: `${path}:locale/what-is-unreal-engine-4`,
            element: <Redirect to="/unreal" status={301} />
        },
        {
            path: `${path}:locale/unreal-engine-4`,
            element: <Redirect to="/unreal" status={301} />
        },
        {
            path: `${path}:locale/products/unreal-engine-4`,
            element: <Redirect to="/unreal" status={301} />
        },
        {
            path: `${path}:locale/products/unreal-engine-3`,
            element: <Redirect to="/unreal" status={301} />
        },
        {
            path: `${path}:locale/products`,
            element: <Redirect to="/unreal" />
        },
        {
            path: `${path}:locale/user-groups`,
            element: <Redirect url="https://communities.unrealengine.com" status={301} />
        },
        {
            path: `${path}:locale/education`,
            element: <Redirect to="/learn" status={301} />
        },
        {
            path: `${path}:locale/academic-admins`,
            element: <Redirect to="/learn" status={301} />
        },
        {
            path: `${path}:locale/resources`,
            element: <Redirect to="/guides-and-white-papers" status={301} />
        },
        {
            path: `${path}:locale/get-now/*`,
            element: <Redirect to="/download" status={301} />
        },
        {
            path: `${path}:locale/get-now`,
            element: <Redirect to="/download" status={301} />
        },
        {
            path: `${path}:locale/eulacheck`,
            element: <Redirect to="/eulacheck/unreal" />
        },
        {
            path: `${path}:locale/eulaprompt`,
            element: <Redirect to="/eulaprompt/unreal" />
        },
        {
            path: `${path}:locale/eula`,
            element: <Redirect to="/eula/unreal" replace />
        },
        {
            path: `${path}:locale/stack-o-bot`,
            element: <Redirect url="https://www.unrealengine.com/marketplace/product/stack-o-bot" />
        },
        {
            path: `${path}en-US/marketplace-faq`,
            element: <Redirect url="https://marketplacehelp.epicgames.com/s/?language=en_US" />
        },
        {
            path: `${path}zh-CN/marketplace-faq`,
            element: <Redirect url="https://marketplacehelp.epicgames.com/s/?language=zh-CN" />
        },
        {
            path: `${path}ko/marketplace-faq`,
            element: <Redirect url="https://marketplacehelp.epicgames.com/s/?language=ko" />
        },
        {
            path: `${path}ja/marketplace-faq`,
            element: <Redirect url="https://marketplacehelp.epicgames.com/s/?language=ja" />
        },
        {
            path: `${path}:locale/support/contact-epic`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/support/contribute`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/support/custom-license`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/support/support-faq`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/support/support-resources`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/support/contact`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/contact`,
            element: <Redirect to="/support" status={301} />
        },
        {
            path: `${path}:locale/developer-interviews`,
            element: <Redirect to="/feed?tags=interviews" />
        },
        {
            path: `${path}:locale/tech-blog`,
            element: <Redirect to="/feed?tags=tech-blog" />
        },
        {
            path: `${path}:locale/spotlights`,
            element: <Redirect to="/feed?tags=spotlight" />
        },
        {
            path: `${path}:locale/enterprise/contact-us`,
            element: <Redirect to="/license" />
        },
        {
            path: `${path}:locale/programs`,
            element: <Redirect to="/feed" />
        },
        {
            path: `${path}:locale/events`,
            element: <Redirect to="/feed?tags=events" />
        },
        {
            path: `${path}:locale/enterprise`,
            element: <Redirect to="/license" />
        },
        {
            path: `${path}:locale/studio/features/:title`,
            element: <Redirect to="/features/:title" status={301} />
        },
        {
            path: `${path}:locale/studio/features`,
            element: <Redirect to="/features" status={301} />
        },
        {
            path: `${path}:locale/features/:title`,
            element: <Redirect to="/features" status={301} />
        },
        {
            path: `${path}:locale/studio`,
            element: <Redirect to="/datasmith" status={301} />
        },
        {
            path: `${path}:locale/studio/downloads`,
            element: <Redirect to="/datasmith/plugins" status={301} />
        },
        {
            path: `${path}:locale/eula-change-log`,
            element: <Redirect to="/eula-change-log/unreal" />
        },
        {
            path: `${path}:locale/academy`,
            element: <Redirect to="/learn" />
        },
        {
            path: `${path}:locale/classroom-training`,
            element: <Redirect to="/training" />
        },
        {
            path: `${path}:locale/programs/unreal-indies`,
            element: <Redirect to="/unreal-indies" status={301} />
        },
        {
            path: `${path}:locale/programs/unreal-indies/*`,
            element: <Redirect to="/unreal-indies" status={301} />
        },
        {
            path: `${path}:locale/indies`,
            element: <Redirect to="/unreal-indies" status={301} replace />
        },
        {
            path: `${path}:locale/indie`,
            element: <Redirect to="/unreal-indies" status={301} replace />
        },
        {
            path: `${path}:locale/gdc2020`,
            element: <Redirect to="/events/gdc2020" status={301} />
        },
        {
            path: `${path}:locale/gdc2019`,
            element: <Redirect to="/events/gdc2019" status={301} />
        },
        {
            path: `${path}:locale/gdc2018`,
            element: <Redirect to="/events/gdc2018" status={301} />
        },
        {
            path: `${path}:locale/siggraph2019`,
            element: <Redirect to="/events/siggraph-2019" status={301} />
        },
        {
            path: `${path}:locale/unrealfestonline`,
            element: <Redirect to="/events/unreal-fest-online-2020" status={301} />
        },
        {
            path: `${path}:locale/e32019`,
            element: <Redirect to="/events/e32019" status={301} />
        },
        {
            path: `${path}:locale/ufe2019`,
            element: <Redirect to="/events/unreal-fest-europe-2019" status={301} />
        },
        {
            path: `${path}:locale/au2019`,
            element: <Redirect to="/events/autodesk-university-2019" status={301} />
        },
        {
            path: `${path}:locale/html5`,
            element: <Redirect to="/" status={301} />
        },
        {
            path: `${path}:locale/products/unreal-engine-4`,
            element: <Redirect to="/previous-versions" status={301} />
        },
        {
            path: `${path}:locale/products/udk`,
            element: <Redirect to="/previous-versions" status={301} />
        },
        {
            path: `${path}:locale/udk`,
            element: <Redirect to="/previous-versions" status={301} />
        },
        {
            path: `${path}:locale/products`,
            element: <Redirect to="/previous-versions" status={301} />
        },
        {
            path: `${path}:locale/uesc`,
            element: <Redirect to="/fabfreecontent" status={301} />
        },
        {
            path: `${path}:locale/uesponsoredcontent`,
            element: <Redirect to="/fabfreecontent" status={301} />
        },
        {
            path: `${path}:locale/nvidiaedge`,
            element: <Redirect to="/programs/nvidia-edge" status={301} />
        },
        {
            path: `${path}:locale/programs/nvidia-edge-program`,
            element: <Redirect to="/programs/nvidia-edge" status={301} replace />
        },
        {
            path: `${path}:locale/awards-accolades`,
            element: <Redirect to="/awards" status={301} />
        },
        {
            path: `${path}:locale/education/students`,
            element: <Redirect to="/students" status={301} />
        },
        {
            path: `${path}:locale/education/academic-admins`,
            element: <Redirect to="/learn" status={301} />
        },
        {
            path: `${path}:locale/ja/blog`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/zh-CN/blog`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/ko/blog`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/ja`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/ko`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/zh-CN`,
            element: <Redirect to="/" />
        },
        {
            path: `${path}:locale/free-download/:slug`,
            element: <Redirect to="/try-free/:slug" status={301} />
        },
        {
            path: `${path}:locale/download/:slug`,
            element: <Redirect to="/download" status={301} />
        }
    ]);
};
