import React from 'react';
import root from 'window-or-global';
import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import Message from '../message/Message';
import Tags from './Tags';
import {getLocale} from '@epic-core/common';
import LinkNavigator from '../common/LinkNavigator';
import {getMetaTags, getUniqueMetaData, generateTitle, BlogTag} from '../../utils/metaUtils';
import {getBlogPost, getBlogField} from '../../utils/BlogDetailUtils';
import {BlogDetailProps, BlogPostProps, titleAppendUnrealEngine} from 'epic-ue-shared';
import parse from 'url-parse';
import {BlogArticleWrapper, BlogDetailWrapper} from './BlogDetail.styles';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

interface BlogDetailProperties {
    blog: BlogDetailProps;
    recent: [];
    routerRootUrl: string;
    config: {
        twitterDefaultTags: string;
    };
    rootPageSlug: string;
    overrideMetaTags: BlogTag[];
    overrideMetaTitle?: string;
    linkNavigatorRootPageSlug?: boolean;
    feedItemTags: {
        tagId?: string;
        label?: string;
    }[];
}

interface BlogDetailState {
    bottomAlign: boolean;
    topAlign: boolean;
    current: number;
}

export default class BlogDetail extends React.Component<BlogDetailProperties, BlogDetailState> {
    constructor(props: BlogDetailProperties) {
        super(props);
        this.state = {
            current: -1,
            topAlign: false,
            bottomAlign: false
        };
    }

    /**
     * Generated Meta Tags for sharing
     * @param  {object} blog     post data
     * @param  {config} hashTags example: "ue4, epicGames", optional
     * @return {array}  meta
     */
    generateMetaTags(blog: BlogDetailProps, config: {twitterDefaultTags: string}): BlogTag[] {
        let meta = getMetaTags(
            getLocale(),
            blog,
            config.twitterDefaultTags,
            this.props.overrideMetaTitle
        );
        meta = getUniqueMetaData(meta);
        return meta.concat(this.props.overrideMetaTags || []);
    }

    render(): JSX.Element {
        const config = this.props.config || {};
        const blog = this.props.blog || {};
        const post = getBlogPost(blog) as BlogPostProps;
        const alt = post.trendingImageAlt || '';
        const noTopImage = post.noTopImage === true || !post.trendingImage;
        const showTranslateMessage = blog.showTranslateMessage;
        const html = sanitizeCopy(post.content || post.short);
        const meta = this.generateMetaTags(blog, config);
        const title = titleAppendUnrealEngine(generateTitle(blog, this.props.overrideMetaTitle));
        const style = !noTopImage
            ? {
                  backgroundImage: `url("${post.trendingImage}")`,
                  backgroundPosition: 'top'
              }
            : {};
        const containerClass = classNames('blog-container', {'no-image': noTopImage});
        const enableLightbox = getBlogField(blog, 'enableLightbox') || false;

        let date: Date | string = '';
        if (blog._activeDate) {
            if (typeof blog._activeDate === 'string') {
                date = new Date(blog._activeDate);
                if (date) {
                    date = date.toLocaleDateString(getLocale(), {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    });
                }
            } else if ((blog._activeDate as any) instanceof Date) {
                date = (blog._activeDate as unknown as Date).toLocaleDateString(getLocale(), {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        }

        const currentUrl = parse(root.location.href);
        currentUrl.set('hash', '');
        currentUrl.set('query', '');

        const links = [
            {
                rel: 'canonical',
                href: currentUrl.toString()
            }
        ];

        meta.push({
            property: 'og:url',
            content: currentUrl.toString()
        });

        return (
            <BlogDetailWrapper className={classNames(containerClass, `loc-${getLocale()}`)}>
                <Helmet link={links} meta={meta} title={title} />
                {showTranslateMessage ? (
                    <div className="blog-translate-message">
                        <Message code="epic.blog.translateMessage" />
                    </div>
                ) : (
                    ''
                )}
                <div className={`blog-header ${noTopImage ? 'no-image' : 'image'}`} style={style}>
                    {!noTopImage ? (
                        <img className="invisible-image" alt={alt} src={post.trendingImage} />
                    ) : null}
                </div>
                <BlogArticleWrapper className="blog-article">
                    <div className="blog-content container-fluid">
                        <article>
                            <div className="cmsContainer">
                                <div className="blog-header-info">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {post.subtitle && (
                                                <span className="blog-header-subtitle">
                                                    {post.subtitle}
                                                </span>
                                            )}
                                            <span className="blog-header-date">{date}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <h1 className="blog-header-title">
                                                {post.title || blog._title}
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Tags feedItemTags={this.props.feedItemTags} />
                                    </div>
                                    <div className="row">
                                        {post.author && (
                                            <div className="blog-header-author col-xs-12">
                                                <Message
                                                    code="epic.blog.article.author"
                                                    args={[post.author]}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <hr />
                                </div>
                                <section
                                    id="cmsSection"
                                    className={`cms ${enableLightbox && 'lightbox-enabled'}`}
                                    dangerouslySetInnerHTML={{__html: html}}
                                />
                            </div>
                        </article>
                    </div>
                    <LinkNavigator
                        linkNavigatorRootPageSlug={this.props.linkNavigatorRootPageSlug}
                        rootPageSlug={this.props.rootPageSlug}
                    />
                </BlogArticleWrapper>
            </BlogDetailWrapper>
        );
    }
}
