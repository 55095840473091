import {FooterLinkClickEvent} from '@wf-components/footer-consumer';
import {UnrealEngineFooter} from '@wf-components/footer-react';
import React, {useCallback} from 'react';
import {isPathDisabled} from '../shared/navUtils';
import {generateRoutePath, getLocale} from '@epic-core/common';
import {useNavigate} from 'react-router';
import root from 'window-or-global';
import {useAccountCountry} from 'epic-ue-shared';

interface Props {
    disablePaths?: string[];
    disableClickHandler?: boolean;
}

export const GlobalFooterWrapper = ({
    disablePaths,
    disableClickHandler
}: Props): JSX.Element | null => {
    const locale = getLocale();
    const navigate = useNavigate();
    const accountCountry = useAccountCountry();

    const clientConfig = root.clientEnvConfig || {};
    const serverUrl = clientConfig.EPIC_SERVER_URL;

    const footerLinkOnClick = useCallback(
        (e: CustomEvent<FooterLinkClickEvent>) => {
            if (disableClickHandler) return;

            const link = e.detail?.link;
            if (link && link?.owner === 'mw-ue' && link.relativePath) {
                e.detail.originalEvent.preventDefault();
                navigate(generateRoutePath(link.relativePath));
            }
        },
        [navigate, generateRoutePath, disableClickHandler]
    );

    const disable = isPathDisabled(location.pathname, disablePaths);
    if (disable) return null;

    return (
        <UnrealEngineFooter
            domain={serverUrl}
            locale={locale}
            disableNewsletter
            countryCode={accountCountry}
            footerLinkOnClick={footerLinkOnClick}
        />
    );
};
