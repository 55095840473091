import React, {useState, useCallback} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {ParagonSectionWrapper} from './ParagonSection.styles';

interface Properties {
    image: string;
    mobileImage?: string;
    imgOpacity?: number;
    title: string;
    desc: string;
    btnLabel?: string;
    btnUrl?: string;
    sectionName: string;
    offset: number;
    showSignup?: boolean;
    formData?: any;
}

const ParagonSection = (props: Properties): JSX.Element | null => {
    const [inViewport, setInViewport] = useState(false);
    const elIsVisible = useCallback(
        (event) => {
            if (event) {
                setInViewport(true);
            }
        },
        [setInViewport]
    );

    const {
        image,
        mobileImage,
        imgOpacity,
        title,
        desc,
        btnLabel,
        btnUrl,
        sectionName,
        offset
    } = props;

    if (!image && !title && !desc) return null;

    let titleElem = <div className="h2">{title}</div>;
    if (sectionName === 'top') {
        titleElem = <h1>{title}</h1>;
    }

    let btnElem = '' as unknown;
    if (btnLabel && btnUrl) {
        btnElem = (
            <a
                id="paragonAssets"
                href={btnUrl}
                className="btn btn-primary ue-cta-button"
                target="_blank"
                rel="noopener noreferrer">
                {btnLabel}
            </a>
        );
    }

    const style = {} as {backgroundImage: string};
    const mobileStyle = {} as {backgroundImage: string};
    if (image) {
        style.backgroundImage = `url(${image})`;
    }

    if (mobileImage) {
        mobileStyle.backgroundImage = `url(${mobileImage})`;
    } else {
        mobileStyle.backgroundImage = `url(${image || ''})`;
    }

    return (
        <VisibilitySensor
            onChange={elIsVisible}
            offset={{top: offset}}
            partialVisibility
            scrollCheck={false}
            scrollThrottle={10}>
            <ParagonSectionWrapper
                imgOpacity={imgOpacity}
                className={`paragon-section ${
                    inViewport ? 'inViewport' : ''
                } section-${sectionName}`}>
                <div className="background-mobile hidden-md hidden-lg" style={mobileStyle} />
                <div className="background hidden-xs hidden-sm" style={style} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="content">
                                {titleElem}
                                {desc && (
                                    <div
                                        className="desc"
                                        dangerouslySetInnerHTML={{__html: desc}}
                                    />
                                )}
                                {btnElem}
                            </div>
                        </div>
                    </div>
                </div>
            </ParagonSectionWrapper>
        </VisibilitySensor>
    );
};

export default ParagonSection;
