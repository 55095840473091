import styled from 'styled-components';

export const EulaCheck = styled.div<{bgImg: string}>`
    padding: 1em 0 5em;
    min-height: 90vh;
    h3 {
        text-align: center;
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        margin: 1em 0 0.5em;
        color: white;
    }
    h5 {
        text-align: center;
        font-family: ${(p) => p.theme.ue.fonts.inter.regular};
        color: #cacaca;
    }
`;

export const EulaWrap = styled.div`
    height: 80vh;
    border-radius: 5px;
    padding: 2em;
    background: white;
    color: black;
`;

export const InnerWrap = styled.div`
    height: 100%;
    padding: 0;
    overflow-y: auto;
`;

export const Content = styled.div`
    padding: 1em;
`;

export const AgreeWrap = styled.div`
    padding: 2em;
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 2em 0.75em;
    }
    text-align: center;
    max-width: 850px;
    margin: 0 auto;

    label {
        font-size: 18px;
        text-align: left;
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 16px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 13px;
            white-space: normal;
        }
        font-weight: normal;
        white-space: nowrap;
        display: inline-block;
        position: relative;
        overflow: hidden;
        height: auto;

        input.form-check-input {
            position: absolute;
            left: 0;
            right: 0;
        }

        .input-label {
            display: block;
            width: 100%;
            margin-left: 25px;
            position: relative;
            height: auto;
            overflow: hidden;
            white-space: normal;
        }
    }

    label,
    input {
        cursor: pointer;
    }

    a,
    label,
    small {
        display: block;
        margin-bottom: 0.5em;
    }
    input {
        position: relative;
        top: 1px;
        margin-right: 10px;
        height: 16px;
        width: 16px;
    }
    .error-text {
        color: red;
    }

    #eula-acceptance {
        margin: 2em auto 1em;
        &.disabled {
            cursor: not-allowed;
        }
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        label {
            position: relative;
            .input-label {
                padding-left: 24px;
                font-size: 12px;
                text-align: left;
            }
        }
        input {
            position: absolute;
            top: -3px;
            margin-right: 0px;
        }
    }
`;

export const LinkWrap = styled.div`
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    a {
        margin: 0 1em;
        padding: 1em;
    }
`;
