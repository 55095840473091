import {Feed, FeedProps, GridContainer} from 'epic-ue-ui';
import {useFeedItems} from 'epic-ue-feed';
import React from 'react';

export interface FeedLoadingProps {
    cmsLoading: boolean;
}

export interface FeedWrapProps {
    feedTag?: string;
    contentType?: string;
    urlPattern?: string;
}

const FeedWrap = ({
    cmsLoading,
    showCarousel,
    button,
    urlPattern = ''
}: FeedProps & FeedWrapProps & FeedLoadingProps): JSX.Element => {
    const tags = [];

    const {items: feedItems = [], loading: loading} = useFeedItems(
        {tags, patternsToSkip: [urlPattern]},
        cmsLoading
    );
    const topFeedItems = feedItems.slice(0, 12);

    const topFeedImages = topFeedItems.map((feedItem) => {
        const tags = feedItem?.tags || [];
        return {
            image: {
                src: feedItem.image?.url || '',
                alt: feedItem.image?.text || ''
            },
            title: feedItem.title,
            caption: {text: ''},
            eyebrow: {text: tags[0]?.label || ''},
            description: feedItem.description,
            link: {
                href: feedItem.url
            }
        };
    });

    return (
        <>
            <GridContainer>
                {!loading && topFeedImages?.length > 0 && (
                    <Feed showCarousel={showCarousel} cards={topFeedImages} button={button} />
                )}
            </GridContainer>
        </>
    );
};

export default FeedWrap;
