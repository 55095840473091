import React, {useCallback, useState, useEffect} from 'react';

import {
    getHelmetForMetaTags,
    getOptImage,
    getUrlPattern,
    RouteMatchOrUrlParse
} from 'epic-ue-shared';
import {generateRoutePath, getBowserState} from '@epic-core/common';
import CourseCard from '../../components/training/CourseCard';

import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {useCmsData, useCmsTreeData, InitSharedValueResponse, cmsApi} from 'epic-ue-shared';
import {AppSharedKeys, PageData, FeedImagesProps} from 'epic-ue-shared';
import Message from '../../components/message/Message';
import SlickWrapper from 'epic-react-common/src/components/SlickWrapper';
import {Badge} from '../../components/training/CourseCard';
import root from 'window-or-global';
import Select from 'react-select';
import {
    CmsComponentMapper,
    GridContainer,
    ImageHero,
    ImageHeroProps,
    ImmersiveHeaderSection,
    TemplateThemeProps,
    VerticalSpacer
} from 'epic-ue-ui';
import {
    AccentColorOptions,
    ueDarkTheme,
    ueLightTheme,
    UeThemeProvider,
    useBreakpointUp,
    useSetCurrentAccentColor
} from 'epic-ue-theme';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {UnrealLoading} from 'epic-ue-loading';
import ErrorPage from '../ErrorPage';
import {
    GeneralLandingHeaderProps,
    DynamicHeaderProps,
    isDynamicHeaderType
} from 'epic-ue-ui/dist/types/general-landing.types';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;

const Filters = styled.div<{accentColor: AccentColorOptions}>`
    padding: 0.5em;
    color: black;
    text-align: left;
    margin: 50px 0 0;
    background-color: ${(p) => p.theme.palette.neutral.concrete};
    input {
        background: ${(p) => p.theme.palette.neutral.steel};
        color: white;
        outline: 0;
        border: 0;
        padding: 10px 8px;
        height: 3em;
        height: 100%;
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
    }
    .search-box,
    .select-box {
        display: inline-block;
        width: 100%;
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
        margin-right: 20px;
    }
    .select-box input {
        background: ${(p) => p.theme.palette.neutral.steel};
        border: 0px;
    }
    .search-box {
        text-align: right;
        margin-right: 0;
        width: 100%;
        vertical-align: top;
        display: flex;
        width: 100%;
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
        .eg-header-icon-search {
            display: inline-block;
            background: ${(p) => p.theme.palette.neutral.steel};
            color: ${(p) => p.theme.palette.accent[p.accentColor]};
            justify-content: center;
            display: flex;
            font-size: 12px;
        }
        .search-input-field {
            width: 100%;
            padding: 10px;
            outline: none;
            font-size: 16px;
        }
        .icon {
            padding: 10px;
            padding-right: 0px;
            background: ${(p) => p.theme.palette.neutral.steel};
            color: ${(p) => p.theme.palette.accent[p.accentColor]};
            min-width: 20px;
            text-align: center;
            font-weight: 700;
        }
    }
    .tag-filters {
        min-width: 180px;
        cursor: pointer;
    }
    .tag-filters div,
    .tag-filters div span {
        border: 0px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .tag-filters svg {
        color: ${(p) => p.theme.palette.accent[p.accentColor]};
    }
    .select-box-wrapper {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 2px;
        .select-box.filter-by-tags {
            width: 100%;
            ${(p) => p.theme.breakpoints.down('md')} {
                width: 100%;
            }
        }
        .select-box.filter-by-tags div {
            border: 0px;
        }
    }
`;

const LearningCards = styled.div<{accentColor: AccentColorOptions}>`
    .course-card div:first-child {
        background-color: ${(p) => p.theme.palette.neutral.concrete};
    }
    .course-card div:first-child:hover {
        background-color: ${(p) => p.theme.palette.background.paperpop};
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    ${(p) => p.theme.breakpoints.up('md')} {
        .course-card {
            flex-basis: 50%;
        }
    }
    ${(p) => p.theme.breakpoints.up('lg')} {
        .course-card {
            flex-basis: 33%;
        }
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        .course-card {
            flex-basis: 100%;
        }
    }
    .course-card a {
        text-decoration: none;
    }
`;

const Slider = styled.div<{accentColor: AccentColorOptions}>`
    background-color: ${(p) => p.theme.palette.background.default};
    color: #e2e2e2;
    h2 {
        margin-top: 1em;
        text-align: center;
    }
    .header {
        ${(p) => p.theme.breakpoints.up('md')} {
            min-height: 40vh !important;
            height: auto !important;
            max-height: 80vh !important;
        }
        @media screen and (min-height: 400px) and (min-width: 700px) {
            min-height: 500px !important;
            max-height: 500px !important;
            height: 500px;
        }
    }
    .course-list {
        margin-top: 2.5em;
    }
    padding-bottom: 5em;
    .courses-showcase {
        margin: 40px auto 0;
        height: 500px;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 275px;
        }
        width: 100%;
        > div {
            height: 100%;
        }
        .slick-slider {
            width: 100%;
            height: 100%;
            .pagination {
                margin-top: 20px;
                padding: 0;
                ${(p) => p.theme.breakpoints.down('md')} {
                    margin-top: 6px;
                }
                button {
                    outline: none;
                    margin: 0.5em;
                    padding: 0;
                    background: ${(p) => p.theme.palette.neutral.steel};
                }
                li.slick-active {
                    button {
                        background: ${(p) => p.theme.palette.accent[p.accentColor]} !important;
                    }
                }
            }
            .slick-arrow {
                cursor: pointer;
                &.slick-disabled {
                    display: block !important;
                }
                top: 45%;
                height: 50px;
                width: 50px;
                background: none;
                border-radius: 9999px;
                border: 0;
                font-size: 1px;
                color: #fff;
                outline: none;
                color: rgba(0, 0, 0, 0);
                padding: 0;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    top: 50%;
                    height: 35px;
                    width: 35px;
                }
                &:before {
                    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='54' width='54' focusable='false' viewBox='0 0 24 24' aria-hidden='true' ><path fill-rule='evenodd' fill='white' clip-rule='evenodd' d='M10.2925 1.20749L0.548728 10.9513L10.2925 20.6951L11.5917 19.3959L4.06572 11.8699L19.7826 11.8699L19.7826 10.0326L4.06572 10.0326L11.5917 2.50666L10.2925 1.20749Z'></path></svg>");
                    font-family: ${(p) => p.theme.ue.fonts.icomoon};
                    font-size: 30em;
                    color: #fff;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    text-shadow: 0 0 5px #333;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 20em;
                        left: 6px;
                    }
                }
                &.slick-prev {
                    left: 15px;
                    &:before {
                        left: 10px;
                        ${(p) => p.theme.breakpoints.down('sm')} {
                            left: 8px;
                        }
                    }
                }
                &.slick-next {
                    right: 38px;
                    &:before {
                        content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='54' width='54' focusable='false' viewBox='0 0 24 24' aria-hidden='true' ><path fill-rule='evenodd' fill='white' clip-rule='evenodd' d='M10.9575 1.20749L20.7013 10.9513L10.9575 20.6951L9.65832 19.3959L17.1843 11.8699L1.46738 11.8699L1.46738 10.0326L17.1843 10.0326L9.65832 2.50666L10.9575 1.20749Z'></path></svg>");
                        left: 15px;
                        ${(p) => p.theme.breakpoints.down('sm')} {
                            left: 8px;
                        }
                    }
                }
            }
            .course-showcase {
                height: 500px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: 275px;
                }
            }
        }
        .course-showcase {
            height: 100%;
            width: 85%;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40%;
                z-index: 0;
                background: linear-gradient(
                    180deg,
                    rgba(0, 212, 255, 0) 0%,
                    ${(p) => p.theme.palette.background.default} 100%
                );
            }
            .course-feature-content {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 10px 20px;
                margin-left: 10%;
                .course-title {
                    font-size: 2em;
                    font-family: ${(p) => p.theme.ue.fonts.inter.regular};
                    display: block;
                    color: #fff;
                    z-index: 3;
                    position: absolute;
                    bottom: 50px;
                    left: 20px;
                    width: 52%;
                    &.has-badge {
                        padding-left: 100px;
                        text-decoration: none;
                        font-size: 34px;
                        font-family: 'Brutal';
                        font-weight: 100;
                        letter-spacing: -1px;
                    }
                    ${(p) => p.theme.breakpoints.down('md')} {
                        font-size: 1.5em;
                        left: 20px;
                        bottom: 20px;
                        padding: 0;
                        line-height: 1.5em;
                        &.has-badge {
                            padding-left: 60px;
                        }
                    }
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 1.25em;
                        bottom: 23px;
                    }
                }
                .course-feature-badge {
                    position: absolute;
                    bottom: 40px;
                    left: 60px;
                    z-index: 3;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        bottom: 25px;
                        left: 40px;
                        > div {
                            height: 50px;
                            width: 50px;
                        }
                    }
                }
            }
        }
        .slick-slider {
            position: relative;
            .pagination {
                margin-top: 20px;
                padding: 0px;
                text-align: center;
                li {
                    display: inline-block;
                    &.slick-active button {
                        background: ${(p) => p.theme.palette.accent[p.accentColor]};
                    }
                    button {
                        outline: none;
                        margin: 0.5em;
                        padding: 0px;
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        border: 1px solid #333;
                        margin: 1em;
                        border-radius: 50%;
                        background: ${(p) => p.theme.palette.neutral.steel};
                        color: transparent;
                    }
                }
            }
            .slick-arrow {
                cursor: pointer;
                top: 45%;
                height: 50px;
                width: 50px;
                background: none;
                border-radius: 9999px;
                border: 0px;
                font-size: 0.2em;
                outline: none;
                color: rgba(0, 0, 0, 0);
                padding: 0px;
                position: absolute;
                z-index: 99;
                &:before {
                    font-size: 30em;
                    color: rgb(255, 255, 255);
                    position: absolute;
                    top: 10px;
                    left: 0px;
                    text-shadow: rgb(51, 51, 51) 0px 0px 5px;
                }
                &.slick-next {
                    &:before {
                        left: 15px;
                    }
                }
            }
            .slick-list {
                width: 100%;
                .slick-slide {
                    float: left;
                    height: 100%;
                    min-height: 1px;
                    .course-showcase {
                        height: 500px;
                        width: 100%;
                        position: relative;
                        ${(p) => p.theme.breakpoints.down('md')} {
                            height: 275px;
                        }
                        .course-feature-img {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            inset: 0px;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            display: flex;
                            justify-content: center;
                            background-color: ${(p) => p.theme.palette.background.default};
                            div {
                                width: 85%;
                            }
                        }
                    }
                }
            }
        }
    }
    .courses-overview {
        h2 {
            font-family: Brutal_Light, Open Sans, arial;
            text-transform: uppercase;
        }
    }
    .title-wrap {
        min-height: 200px;
    }
    &&& {
        .header {
            @media screen and (max-height: 1000px) and (min-width: 1000px) {
                min-height: 600px;
                height: 600px;
            }
            @media screen and (min-height: 1200px) and (max-width: 1100px) {
                min-height: 600px;
                height: 600px;
            }
            h1 {
                margin-top: 1em;
            }
            h2 {
                text-transform: initial;
                font-size: 2em;
                margin: 1em 0.3em;
            }
        }
    }
`;

const SortLabel = styled.div`
    float: left;
    color: ${(p) => p.theme.palette.neutral.stone};
    vertical-align: bottom;
    padding: 0.4em 0.5em;
    font-size: 16px;
    letter-spacing: initial;
    line-height: 30px;
`;

const ShowcaseCardImg = styled.div<{img: string}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url(${(p) => getOptImage({url: p.img, width: 1300, quality: 'high'})});
    height: 100%;
    width: 100%;
`;

export const getCmsUrlPattern = (match: {path?: string}): string => {
    const path = match.path || '';
    if (path.indexOf('onlinelearning') > -1) {
        return '/onlinelearning-courses';
    } else if (path.indexOf('lesson-plans') > -1) {
        return '/lesson-plans';
    }

    return '/classroom-training';
};

function uniq(a) {
    if (a && a.length > 0) {
        return a.sort().filter((item, pos, ary) => {
            return !pos || item !== ary[pos - 1];
        });
    }
}

function flatten(a) {
    if (a && a.length > 0) {
        return Array.isArray(a) ? [].concat(...a.map(flatten)) : a;
    }
}

const defaultSortOptions = [
    {
        label: 'Title',
        value: 'title'
    },
    {
        label: 'Release',
        value: 'release'
    },
    {
        label: 'Length',
        value: 'length'
    }
];

interface SortingProps {
    sortOptions: {
        label: string;
        value: string;
    }[];
}

export interface OnlineLearningStardustPageData extends PageData {
    courseTitle: string;
    courseDesc: string;
    placeholder: string;
    sortPlaceholder: string;
    tagsPlaceholder: string;
    quickRegisterBtn: string;
    sorting: SortingProps;
    header: GeneralLandingHeaderProps;
    themeConfig: TemplateThemeProps;
}

interface ClassData extends PageData {
    class: {
        badge: string;
        content: string;
        length: number;
        author: string;
        release: Date;
        tag: string;
        id: string;
        inShowcase: boolean;
        label: string;
        short: string;
        title: string;
        url: string;
        image: FeedImagesProps;
        showcaseImg: string;
    };
}

interface Properties {
    cmsUrlPattern?: string;
}

const OnlineLearningStardustPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const navigate = useNavigate();
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} =
        useCmsData<OnlineLearningStardustPageData>(urlPattern);
    const {cmsLoading: cmsTreeLoading, children} = useCmsTreeData<ClassData>(urlPattern);

    const [hasLoaded, setHasLoaded] = React.useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, [hasLoaded, setHasLoaded]);

    const isMd = useBreakpointUp('md');

    const [filter, setFilter] = useState('');
    const [userSorting, setUserSorting] = useState('title');
    const stringArray: string[] = [];
    const [sortTag, setSortTag] = useState(stringArray);

    const {header = {} as GeneralLandingHeaderProps, themeConfig = {} as TemplateThemeProps} =
        pageData;

    const isDynamicHeader = isDynamicHeaderType(header);
    const {headerOptions} = isDynamicHeader ? (header as DynamicHeaderProps) : {headerOptions: []};

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const themeType = theme === 'light' ? ueLightTheme : ueDarkTheme;

    const OptionStyles = {
        control: (provided, state) => ({
            ...provided,
            background: themeType.palette.neutral.steel,
            color: 'white',
            borderRadius: 'none'
        }),
        menu: (provided, state) => ({
            ...provided,
            background: themeType.palette.neutral.steel
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? 'black' : 'white',
            background: state.isFocused
                ? themeType.palette.accent[accentColor]
                : themeType.palette.neutral.steel
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white'
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: 'white'
        }),
        input: (provided, state) => ({
            ...provided,
            color: 'white'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'white'
        })
    };

    const getParameterByName = (name, url) => {
        let retrievedName;
        if (name) {
            retrievedName = name.replace(/[[]]/g, '\\$&');
        }
        let retrieveURL;
        if (!url) {
            retrieveURL = root.location.href;
        } else {
            retrieveURL = url;
        }

        const regex = new RegExp(`[?&]${retrievedName}(=([^&#]*)|&|#|$)`),
            results = regex.exec(retrieveURL);
        if (!results) return '';
        if (!results[2]) return '';
        return decodeURIComponent(results[2].toLowerCase().replace(/\+/g, ' '));
    };

    useEffect(() => {
        const tagsInUrl: string = getParameterByName('tags', null);
        if (tagsInUrl) {
            setSortTag(tagsInUrl.split(','));
        }
    }, [setSortTag]);

    const onFilterChange = useCallback(
        (e) => {
            const target = e.currentTarget || {};
            setFilter(target.value || '');
        },
        [setFilter]
    );

    const constructUrl = useCallback(
        (tags) => {
            let url = '/onlinelearning-courses';
            if (tags && tags.length > 0) {
                const urlTags = flatten(tags);
                url += `?tags=${urlTags}`;
            }
            root.scrollTopSkip = pathname; //prevent ScrollToTop from resetting position for this page
            navigate(generateRoutePath(url));
        },
        [navigate, sortTag, cmsUrlPattern, pathname]
    );

    const onSortChange = useCallback(
        (option) => {
            setUserSorting(option && option.value ? option.value.toLowerCase() : '');
        },
        [setUserSorting]
    );

    const onTagSortChange = useCallback(
        (options) => {
            const selectedTags: string[] = [];
            (options || []).map((selectedTag) => {
                if (selectedTag) {
                    const tag = selectedTag.value;
                    if (tag) {
                        selectedTags.push(tag.toLowerCase());
                    }
                }
            });

            setSortTag(selectedTags);
            constructUrl(selectedTags);
        },
        [setSortTag]
    );

    const {mobile, tablet} = getBowserState();

    const sliderOptions = {
        infinite: true,
        speed: 500,
        draggable: tablet || mobile,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'pagination',
        arrows: true
    };

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    let filtered: ClassData[] = [];
    let sorted: ClassData[] = [];
    let tags = [];
    const tagGrouping: string[][] = [];

    if (children) {
        if (filter || sortTag) {
            children.forEach((page) => {
                const klass = page.class;
                if (klass) {
                    const {title = '', short = '', content = '', tag = ''} = klass;
                    let lowerFilter;
                    let findTagMatch: string[] = [];
                    if (filter) {
                        lowerFilter = filter.toLowerCase().trim();
                    }

                    if (sortTag && sortTag.length > 0) {
                        findTagMatch = sortTag.filter((e) => tag.toLowerCase().indexOf(e) !== -1);
                    }

                    // Check for match in content
                    if (
                        (lowerFilter && title.toLowerCase().indexOf(lowerFilter) > -1) ||
                        (lowerFilter && short.toLowerCase().indexOf(lowerFilter) > -1) ||
                        (lowerFilter && content.toLowerCase().indexOf(lowerFilter) > -1)
                    ) {
                        // if a tag has been selected, use if to filter even further
                        if (findTagMatch.length > 0) {
                            filtered.push(page);
                        } else if (findTagMatch.length === 0 && sortTag.length === 0) {
                            filtered.push(page);
                        }

                        // If no search input has been entered, filter just by tag
                    } else if (!lowerFilter && findTagMatch.length > 0) {
                        filtered.push(page);

                        // No filter used, just show everything
                    } else if (!lowerFilter && sortTag.length === 0) {
                        filtered.push(page);
                    }
                }
            });
        } else {
            filtered = children || [];
        }

        sorted =
            filtered.sort((a, b) => {
                const classA = a.class || {};
                const classB = b.class || {};
                let itemA, itemB;
                itemA = classA.title ? classA.title.toLowerCase() : '';
                itemB = classB.title ? classB.title.toLowerCase() : '';

                if (userSorting && userSorting === 'title') {
                    itemA = classA.title ? classA.title.toLowerCase() : '';
                    itemB = classB.title ? classB.title.toLowerCase() : '';
                } else if (userSorting && userSorting === 'length') {
                    itemA = classA.length ? classA.length : '';
                    itemB = classB.length ? classB.length : '';
                } else if (userSorting && userSorting === 'release') {
                    itemA = classA.release ? classA.release : '';
                    itemB = classB.release ? classB.release : '';
                }

                if (!userSorting || (userSorting && userSorting === 'title')) {
                    return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
                } else if (userSorting && userSorting === 'release') {
                    return itemB < itemA ? -1 : itemB > itemA ? 1 : 0;
                } else {
                    return itemB - itemA;
                }
            }) || [];
    }

    const {sortPlaceholder, tagsPlaceholder, placeholder, quickRegisterBtn} = pageData;
    const sorting: SortingProps = pageData.sorting || {};
    const sortOptions = sorting.sortOptions || defaultSortOptions;
    const showcases: (JSX.Element | undefined)[] = [];

    if (children) {
        children.forEach((page) => {
            const klass = page.class;
            if (klass) {
                const inShowcase = klass.inShowcase;
                if (inShowcase) {
                    const {title = '', badge = ''} = klass;
                    const image: FeedImagesProps = klass.image || {};
                    const urlPattern = page._urlPattern;
                    let img = image.desktopImage;
                    if (tablet && image.tabletImage) {
                        img = image.tabletImage;
                    } else if (mobile && image.mobileImage) {
                        img = image.mobileImage;
                    }

                    const showcaseImage = klass.showcaseImg;
                    if (showcaseImage) {
                        img = showcaseImage;
                    }

                    const href = generateRoutePath(urlPattern as string);

                    if (img && title) {
                        showcases.push(
                            <div key={`course-showcase-${title}`} className="course-showcase">
                                <div className="course-feature-img">
                                    <ShowcaseCardImg img={img} />
                                </div>
                                <div className="course-feature-content">
                                    <div className="course-feature-badge">
                                        {badge ? <Badge img={badge} /> : ''}
                                    </div>
                                    <a
                                        href={href}
                                        className={`course-title ${badge ? 'has-badge' : ''}`}>
                                        {title}
                                    </a>
                                </div>
                            </div>
                        );
                    }
                }

                const klassTag = klass.tag;
                if (klassTag) {
                    const splitTags: string[] = klassTag.split(',');
                    tagGrouping.push(splitTags);
                    if (tagGrouping.length > 0) {
                        tags = flatten(tagGrouping);
                    }
                }
            }
        });
    }

    const sortedSortOptions = sortOptions.sort();

    let uniqueTags = [];
    if (tags && tags.length > 0) {
        uniqueTags = uniq(tags);
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled className={theme}>
                {!hasLoaded && <UnrealLoading />}

                {getHelmetForMetaTags(pageData)}

                {isDynamicHeader ? (
                    <CmsComponentMapper
                        dynamicPageDataArray={headerOptions}
                        elementMapping={{
                            imageHero: <ImageHero accentColor={accentColor} />,
                            immersiveHeader: <ImmersiveHeaderSection accentColor={accentColor} />
                        }}
                    />
                ) : (
                    <ImageHero {...(header as ImageHeroProps)} />
                )}

                {isMd && (
                    <GridContainer>
                        {hasLoaded && (
                            <Slider accentColor={accentColor}>
                                {showcases && showcases.length && children ? (
                                    <div className="container courses-showcase">
                                        <SlickWrapper
                                            className="container block-talks"
                                            options={sliderOptions}>
                                            {showcases}
                                        </SlickWrapper>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Slider>
                        )}
                    </GridContainer>
                )}

                <div className="course-list container">
                    {hasLoaded && (
                        <Filters accentColor={accentColor}>
                            <GridContainer>
                                <div className="select-box-wrapper">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={3}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            {sortedSortOptions && sortedSortOptions.length ? (
                                                <div className="form-group select-box">
                                                    <SortLabel>
                                                        {sortPlaceholder || 'Sort by:'}
                                                    </SortLabel>
                                                    <Select
                                                        key="uol-filters"
                                                        className={`filter tag-filters`}
                                                        styles={OptionStyles}
                                                        options={sortedSortOptions.map(
                                                            (option) => option
                                                        )}
                                                        onChange={(option) => onSortChange(option)}
                                                        instanceId="sort-filtering"
                                                        defaultValue={sortedSortOptions[0]}
                                                        isClearable={false}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        {isMd && (
                                            <Grid item md={3}>
                                                {' '}
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={4} md={3}>
                                            <div className="form-group select-box filter-by-tags">
                                                <Select
                                                    key="uol-tags"
                                                    className={`filter tag-filters`}
                                                    styles={OptionStyles}
                                                    options={uniqueTags.map((value) => ({
                                                        value,
                                                        label: value
                                                    }))}
                                                    placeholder={tagsPlaceholder || 'Tags'}
                                                    onChange={(option) => onTagSortChange(option)}
                                                    isClearable
                                                    value={sortTag.map((value) => ({
                                                        value,
                                                        label: value
                                                    }))}
                                                    isMulti
                                                    instanceId="tag-filtering"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <div className="search-box">
                                                <i className="eg-header-icon-search icon"></i>
                                                <input
                                                    className="search-input-field"
                                                    type="text"
                                                    onChange={onFilterChange}
                                                    placeholder={placeholder || 'Search'}
                                                    value={filter}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </GridContainer>
                        </Filters>
                    )}

                    <GridContainer>
                        <LearningCards accentColor={accentColor}>
                            {cmsTreeLoading ? (
                                <UeThemeProvider
                                    theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                                    <Styled>
                                        <UnrealLoading />
                                    </Styled>
                                </UeThemeProvider>
                            ) : (
                                sorted.map((page) => {
                                    const klass = page.class;
                                    return (
                                        <CourseCard
                                            key={page._title}
                                            quickRegisterBtn={quickRegisterBtn}
                                            urlPattern={page._urlPattern || ''}
                                            {...klass}
                                        />
                                    );
                                })
                            )}

                            {!sorted || !sorted.length ? (
                                <h4>
                                    <Message code="epic.feed.no.results.header" />
                                </h4>
                            ) : (
                                ''
                            )}
                        </LearningCards>
                    </GridContainer>
                </div>
                <VerticalSpacer variant="feature" />
            </Styled>
        </UeThemeProvider>
    );
};

OnlineLearningStardustPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const cmsUrlPattern = getUrlPattern(match);

    const responses = await Promise.all([
        await cmsApi.getPageData(cmsUrlPattern),
        await cmsApi.getTreeData('/onlinelearning-courses')
    ]);

    return [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: responses[0] || {}
            }
        },
        {
            key: AppSharedKeys.CmsTreeStore,
            value: {
                [cmsUrlPattern]: responses[1] || []
            }
        }
    ];
};

export default OnlineLearningStardustPage;
