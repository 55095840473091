import {getPath} from '@epic-core/common';
import React from 'react';
import {ConnectedPage, ServerRoute} from './routes';
import ClassicRoute from '../classic/ClassicRoute';

import ErrorPage from '../pages/ErrorPage';
import EulaCheckPage from '../pages/EulaCheckPage';
import PluginsPage from '../pages/PluginsPage';
import BulletTrainPage from '../pages/BulletTrainPage';
import DatasmithPage from '../pages/DatasmithPage';
import BrandingPage from '../pages/BrandingPage';
import XrPage from '../pages/XrPage';
import TwinmotionResellersPage from '../pages/TwinmotionResellersPage';
import TrainingPage from '../pages/TrainingPage';
import TrainingDetailPage from '../pages/TrainingDetailPage';
import InstructorGuidesPage from '../pages/InstructorGuidesPage';
import GameJamPage from '../pages/GameJamPage';
import ParagonPage from '../pages/ParagonPage';
import SponsoredContentPage from '../pages/SponsoredContentPage';
import VPFieldGuidePage from '../pages/VPFieldGuidePage';
import ResourcesPage from '../pages/ResourcesPage';
import HighlightPage from '../pages/HighlightPage';
import ProgramPage from '../pages/ProgramPage';
import MaintenancePage from '../pages/MaintenancePage';
import EulaPromptPage from '../pages/EulaPromptPage';
import ReleasePage from '../pages/ReleasePage';
import AuthPage from '../pages/AuthPage';
import LearnAuthPage from '../pages/LearnAuthPage';
import FastTrackPage from '../pages/FastTrackPage';
import DigitalTwinsPage from '../pages/DigitalTwinsPage';
import MetaHumanCreatorApplyPage from '../pages/MetaHumanCreatorApplyPage';
import CmsPage from '../CmsPage';

const getEl = (el: ConnectedPage): JSX.Element => {
    return <ClassicRoute element={el} />;
};

const getRoute = (path: string, el: ConnectedPage): ServerRoute => {
    return {
        path,
        element: getEl(el),
        getInitialSharedValues: el?.getInitialSharedValues
    };
};

export const getClassicRoutes = (): ServerRoute[] => {
    const path = getPath();

    return [
        getRoute(`${path}:locale/xr`, XrPage),
        getRoute(`${path}:locale/events/:typeSlug/:title`, HighlightPage),
        getRoute(`${path}:locale/digital-twins`, DigitalTwinsPage),
        getRoute(`${path}:locale/educators/resources`, InstructorGuidesPage),
        getRoute(`${path}:locale/guides-and-white-papers`, ResourcesPage),
        getRoute(`${path}:locale/eulacheck/:eulaDesc`, EulaCheckPage),
        getRoute(`${path}:locale/eulaprompt/:eulaDesc`, EulaPromptPage),
        getRoute(`${path}:locale/fabfreecontent`, SponsoredContentPage),
        getRoute(`${path}:locale/globalgamejam`, GameJamPage),
        getRoute(`${path}:locale/programs/:typeSlug/:title`, HighlightPage),
        getRoute(`${path}:locale/programs/:title`, ProgramPage),
        getRoute(`${path}:locale/twinmotion/resellers`, TwinmotionResellersPage),
        getRoute(`${path}:locale/datasmith/plugins`, PluginsPage),
        getRoute(`${path}:locale/datasmith`, DatasmithPage),
        getRoute(`${path}:locale/paragon`, ParagonPage),
        getRoute(`${path}:locale/lesson-plans/:title`, TrainingDetailPage),
        getRoute(`${path}:locale/lesson-plans`, TrainingPage),
        getRoute(`${path}:locale/vpfieldguide`, VPFieldGuidePage),
        getRoute(`${path}:locale/classroom-training/:title`, TrainingDetailPage),
        getRoute(`${path}:locale/bullet-train`, BulletTrainPage),
        getRoute(`${path}:locale/branding`, BrandingPage),
        getRoute(`${path}:locale/release`, ReleasePage),
        getRoute(`${path}:locale/maintenance`, MaintenancePage),
        getRoute(`${path}:locale/auth`, AuthPage),
        getRoute(`${path}:locale/learn-auth`, LearnAuthPage),
        getRoute(`${path}:locale/fast-track`, FastTrackPage),
        getRoute(`${path}:locale/metahuman-creator-apply`, MetaHumanCreatorApplyPage),

        //error pages
        getRoute(`${path}:locale/page-not-found`, ErrorPage),
        getRoute(`${path}page-not-found`, ErrorPage),
        getRoute(`${path}:locale/error-404`, ErrorPage),
        getRoute(`${path}error-404`, ErrorPage),
        getRoute(`${path}unexpectedError`, ErrorPage),
        getRoute(`${path}:locale/unexpectedError`, ErrorPage),
        getRoute(`${path}:locale/error-500`, ErrorPage),
        getRoute(`${path}error-500`, ErrorPage),

        //known sub directories that render the CmsPage
        getRoute(`${path}:locale/eula-change-log/:cmsPageSlug`, CmsPage),
        getRoute(`${path}:locale/previous-versions/:cmsPageSlug`, CmsPage),
        getRoute(`${path}:locale/support/:cmsPageSlug`, CmsPage),
        getRoute(`${path}:locale/html5/:cmsPageSlug`, CmsPage),

        getRoute(`${path}:locale/qa-test/blank-template`, CmsPage),
        getRoute(`${path}:locale/qa-test/blank-template/:cmsPageSlug`, CmsPage),

        //fallback to cms page
        getRoute(`${path}:locale/:cmsPageSlug`, CmsPage),

        //last resort its a 404
        getRoute(`${path}:locale/*`, ErrorPage)
    ];
};
