import styled from 'styled-components';

export const VideoClassWrapper = styled.div`
    display: block;
    padding: 0;
    border: 1px solid #f0f0f0;
    * {
        color: #000;
    }
    .video-class-img:hover {
        img {
            opacity: 0.4;
        }
        span {
            display: block;
            transition: background-color 0.25ms ease-in-out;
        }
    }

    .video-class-img {
        display: block;
        margin-top: 40px;
        width: 100%;
        height: 220px;
        text-align: center;
        position: relative;
        overflow: hidden;
        background: #000;
        span {
            color: #fff;
            font-size: 1em;
            display: none;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            padding: 25% 0;
            background-color: #00000054;
            transition: background-color 0.25ms ease-in-out;
        }
        img {
            margin: 0 auto;
            height: 100%;
            ${(p) => p.theme.breakpoints.down('md')} {
                height: auto;
                width: 100%;
            }
        }
    }
    .video-class-content {
        background: #fff;
        padding: 20px;
        ${(p) => p.theme.breakpoints.up('md')} {
            height: 400px;
        }
        overflow: hidden;
        ${(p) => p.theme.breakpoints.up('md')} {
            &:after {
                content: '';
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 0255, 0) 0%,
                    rgba(255, 255, 255, 1) 100%
                );
                height: 35px;
                position: absolute;
                bottom: 60px;
                left: 20px;
                width: 90%;
                z-index: 0;
            }
        }
        .h2 {
            margin: 0 0 20px;
            font-size: 1.2em;
            color: black;
            font-family: ${(p) => p.theme.ue.fonts.inter.regular};
            display: block;
            transition: color 300ms ease-in-out;
            &:hover {
                color: #0aaff1;
                transition: color 150ms ease-in-out;
            }
        }
        .desc {
            font-size: 0.875em;
            line-height: 1.7em;
            color: rgba(0, 0, 0, 0.8);
            padding-bottom: 35px;
            ${(p) => p.theme.breakpoints.up('md')} {
                height: 250px;
            }
            overflow: hidden;
        }
        .video-class-details {
            margin: 20px 0 0 -20px;
            width: calc(100% + 40px);
            border-top: 1px solid #f0f0f0;
            position: absolute;
            left: 0;
            bottom: 0;
            overflow: hidden;
            font-size: 0.75em;
            height: 35px;
            padding: 0 40px;
            z-index: 2;
            * {
                color: #686868;
            }
            .video-author,
            .video-length {
                position: absolute;
                top: 8px;
                left: 50px;
            }
            .video-length {
                right: 55px;
                left: auto;
                .clock {
                    border-radius: 50px;
                    background: #ccc;
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 5px;
                    position: relative;
                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 5px;
                        top: 1px;
                        left: 50%;
                        transform: translateX(-50%);
                        background: #fff;
                    }
                    &:after {
                        transform: rotate(90deg) translateX(-61%);
                        top: 4px;
                        height: 4px;
                        left: 61%;
                    }
                }
                .video-category {
                    display: inline;
                    color: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
                    padding-left: 4px;
                }
            }
        }

        .download-link {
            position: relative;
            padding: 6px 12px;
            top: -10px;
            font-size: 10px;
            text-transform: uppercase;
            i {
                margin-right: 5px;
            }

            span,
            i {
                color: white;
            }
        }
    }
`;
