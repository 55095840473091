import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys} from './sharedKeys';
import {miscApi} from './misc.api';
import {useState, useEffect, useMemo} from 'react';
import {isError} from '@epic-mw/error-types';

export interface StardustPage {
    urlPattern?: string;
    template?: 'GENERAL_LANDING' | 'INDUSTRY_SOLUTIONS' | 'MEGAGRANTS';
}

export interface StardustRedirect {
    from?: string;
    to?: string;
    permanent?: boolean;
    url?: string;
    replace?: boolean;
}

interface MiscStoreProps {
    countryCode?: string;
    epicSid?: string;
    referrer?: string;
    industries?: IndustryProps[];
    epicStatusUrl?: string;
    epicStatusApi?: string;
    stardustPages?: StardustPage[];
    stardustRedirects?: StardustRedirect[];
}
interface MiscStoreRes {
    miscStore?: MiscStoreProps;
    setMiscStore: (a: MiscStoreProps) => void;
}

export interface IndustryProps {
    id: string;
    name: string;
    industry_v2?: string;
}

interface CountryProps {
    id: string;
    name: string;
}

export const useMiscStore = (): MiscStoreProps => {
    const [miscStore] = useSharedValue<MiscStoreProps>(AppSharedKeys.MiscStore);
    return miscStore || {};
};

export const useUpdateMiscStore = (): MiscStoreRes => {
    const [miscStore, setMiscStore] = useSharedValue<MiscStoreProps>(AppSharedKeys.MiscStore);
    return {
        miscStore,
        setMiscStore
    };
};

export const useIndustryList = (): IndustryProps[] => {
    const miscStore = useMiscStore();
    const industries = miscStore.industries || [];
    return industries;
};

export const useCountryCode = (): string => {
    const miscStore = useMiscStore();
    const countryCode = miscStore.countryCode || 'US';
    return countryCode;
};

export const useEpicSid = (): string => {
    const miscStore = useMiscStore();
    const epicSid = miscStore.epicSid || '';
    return epicSid;
};

export const countryListKey = createSharedValueKey<CountryProps[]>('countryList', []);

export const useCountryList = (): {
    loaded: boolean;
    loading: boolean;
    error?: string;
    countryList: CountryProps[];
} => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [countryList, setCountryList] = useSharedValue(countryListKey);

    useEffect(() => {
        if ((countryList && countryList.length && !loadingState.error) || loadingState.loading) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const countries = await miscApi.getCountries();
                setCountryList(countries);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch country data', ex);
                const error: string = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [countryList, loadingState]);

    return useMemo(() => {
        return {
            countryList,
            ...loadingState
        };
    }, [countryList.length, loadingState.loading, loadingState.loaded]);
};
