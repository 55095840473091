import {searchFetcher} from './search.fetch';
import useSWR from 'swr';
import {useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys} from 'epic-ue-shared/dist/shared/sharedKeys';
import parse from 'url-parse';
import root from 'window-or-global';
import {SearchRes, ImageSearchItem, SearchItem} from '../types';

export const createKey = (request?: Request): string => {
    if (!request) return '';
    return JSON.stringify(request);
};

interface Request {
    [key: string]: string | number | boolean | undefined;
}

interface SearchStore {
    searchData?: SearchRes;
    serverQuery?: {[key: string]: string};
    search?: SearchRes;
}

export interface SearchResultsRes {
    search?: SearchRes;
    isLoading: boolean;
    error?: string;
}

const defaultRequest = {skip: 0};

export const useSearchResults = (request?: Request): SearchResultsRes => {
    const [searchStore] = useSharedValue<SearchStore>(AppSharedKeys.SearchStore);
    const sharedValueSearch = searchStore.searchData;
    const sharedValueQuery = searchStore.serverQuery;

    let sameRequestAsServer = false;
    try {
        sameRequestAsServer = JSON.stringify(request) === JSON.stringify(sharedValueQuery);
    } catch (e) {
        //noop
    }

    const serverSearchValid =
        sharedValueSearch && sharedValueSearch.items && sharedValueSearch.items.length;
    const shouldFetch = !sameRequestAsServer || !serverSearchValid;

    const {data, error} = useSWR(
        shouldFetch ? createKey(request || defaultRequest) : null,
        searchFetcher.getSearchResults,
        {
            errorRetryCount: 1,
            revalidateOnFocus: false
        }
    );

    return {
        search: (!shouldFetch && sharedValueSearch) || data,
        isLoading: !data && !error,
        error
    };
};

export interface SearchPages {
    [key: number]: {
        plainItems: SearchItem[];
        imageItems: ImageSearchItem[];
    };
}

interface SearchItemsRes {
    pages: SearchPages;
    total?: number;
    isLoading: boolean;
}

const domainsAllowed = new RegExp('(unrealengine.com|epicgames.com|twinmotion.com)');
const filenamesToSkip = new RegExp('(placeholder|logo|UE_Feed_Migration_Image|social-media-image)');
const pageSize = 10;

export const useSearchItems = (locale: string): SearchItemsRes => {
    const parsedUrl = parse(root.location.href, true);
    const queryKeyword = String(parsedUrl.query.keyword || '');
    const filter = String(parsedUrl.query.filter || '');

    const {search, isLoading} = useSearchResults({
        keyword: queryKeyword,
        filter,
        locale
    });

    const items = search?.items || [];
    const total = search?.total || 0;
    const pages: SearchPages = {};
    let lastIndex = 0;
    let pageNum = 0;

    for (let i = 0; i < items.length; i = i + pageSize) {
        lastIndex = i;
        const plainItems: SearchItem[] = [];
        const imageItems: ImageSearchItem[] = [];
        pageNum = Math.floor(i / pageSize);

        for (let j = i; j < i + pageSize; j++) {
            const item = items[j];
            const document = item?.document || {};
            const derivedStructData: SearchItem = document?.derivedStructData || {};
            const pagemap = derivedStructData?.pagemap;
            const cse_images = pagemap?.cse_image || [];
            if (
                cse_images &&
                cse_images.length > 0 &&
                cse_images[0] &&
                cse_images[0].src &&
                domainsAllowed.test(cse_images[0].src) &&
                !filenamesToSkip.test(cse_images[0].src)
            ) {
                const image = cse_images[0].src;
                imageItems.push({
                    ...derivedStructData,
                    image
                });
            } else {
                plainItems.push(derivedStructData);
            }
        }
        pages[pageNum] = {
            plainItems,
            imageItems
        };
    }

    if (items.length && lastIndex < items.length) {
        const plainItems: SearchItem[] = [];
        const imageItems: ImageSearchItem[] = [];
        pageNum++;

        for (let i = lastIndex; i < lastIndex + pageSize; i++) {
            const item = items[i];
            const document = item?.document || {};
            const derivedStructData = document?.derivedStructData || {};
            const pagemap = derivedStructData?.pagemap;
            const cse_images = pagemap?.cse_image || [];
            if (
                cse_images &&
                cse_images.length > 0 &&
                cse_images[0] &&
                cse_images[0].src &&
                domainsAllowed.test(cse_images[0].src) &&
                !filenamesToSkip.test(cse_images[0].src)
            ) {
                const image = cse_images[0].src;
                imageItems.push({
                    ...derivedStructData,
                    image
                });
            } else {
                plainItems.push(derivedStructData);
            }
        }
        pages[pageNum] = {
            plainItems,
            imageItems
        };
    }

    return {pages, total, isLoading};
};

export const useSearchStore = (): {[key: string]: any} => {
    const [searchStore] = useSharedValue(AppSharedKeys.SearchStore);
    return searchStore;
};
