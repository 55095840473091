import {AltLocPageData, useCmsData, isBlogTemplate} from 'epic-ue-shared';
import {getLocale} from '@epic-core/common';
import {getNoTrailingSlash} from '@epic-mw/common-utils';

interface FeedStoreItemResponse {
    isBlogDetails: any;
    cmsLoading: boolean;
    cmsLoadedEmpty?: boolean;
    feedItem: any;
    source: string;
    urlPattern: any;
}

export const useFeedStoreItem = (cmsUrlPattern: string): FeedStoreItemResponse => {
    const validUrlPattern = !!cmsUrlPattern;
    const urlPattern = cmsUrlPattern.length > 1 ? getNoTrailingSlash(cmsUrlPattern) : cmsUrlPattern;
    let feedItem;
    let source = '';

    const {
        pageData,
        cmsLoading,
        cmsLoadedEmpty
    }: {
        pageData: AltLocPageData;
        cmsLoading: boolean;
        cmsLoadedEmpty?: boolean;
    } = useCmsData(urlPattern);
    if (
        (pageData && pageData._urlPattern === urlPattern) ||
        pageData._urlPattern === `/${getLocale()}${urlPattern}` ||
        pageData._hasAltLocPageData
    ) {
        feedItem = pageData;
        source = 'cms';
    }

    const isBlogDetails = isBlogTemplate(feedItem);

    return {
        feedItem,
        source,
        isBlogDetails,
        urlPattern,
        cmsLoading: validUrlPattern && cmsLoading,
        cmsLoadedEmpty: validUrlPattern && cmsLoadedEmpty
    };
};
