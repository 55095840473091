export const getDecimalSeparator = (): string => {
    let decSep = '.';

    try {
        // this works in FF, Chrome, IE, Safari and Opera
        const float = 3 / 2;
        const sep = parseFloat(float.toString()).toLocaleString().substring(1, 2);
        if (sep === '.' || sep === ',') {
            decSep = sep;
        }
    } catch (e) {
        console.warn('Failed to detect the decimal separator');
    }

    return decSep;
};

export const convertEnToEnUS = (locale: string): string => {
    if (locale === 'en') {
        return 'en-US';
    }
    return locale;
};
