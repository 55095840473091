import {getLocale} from '@epic-core/common';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {extractSlugLocale} from '@epic-mw/localization';
import {useEffect} from 'react';
import parse from 'url-parse';
import root from 'window-or-global';

interface NewsletterSlugProps {
    [key: string]: boolean;
}

export const newsletterSignupSlugKey = createSharedValueKey<NewsletterSlugProps | null>(
    'newsletterSignupSlug',
    null
);

const getCurrentSlug = (): string => {
    const parsedCurrentUrl = parse(root.location.href);
    const {pageSlug} = extractSlugLocale(parsedCurrentUrl.pathname, getLocale());
    return pageSlug;
};

export const useSectionNewsletterPresence = (): boolean => {
    const [newsletterSlugPresenceMap] = useSharedValue(newsletterSignupSlugKey);

    const pageSlug = getCurrentSlug();

    if (!newsletterSlugPresenceMap || !pageSlug || !newsletterSlugPresenceMap[pageSlug]) {
        return false;
    }
    return newsletterSlugPresenceMap[pageSlug];
};

export const useSetSectionNewsletterPresence = (): void => {
    const [newsletterSlugPresenseMap, setNewsletterSlugPresenseMap] =
        useSharedValue(newsletterSignupSlugKey);
    const pageSlug = getCurrentSlug();

    useEffect(() => {
        const map = Object.assign({}, newsletterSlugPresenseMap);
        map[pageSlug] = true;
        if (JSON.stringify(map) !== JSON.stringify(newsletterSlugPresenseMap)) {
            setNewsletterSlugPresenseMap(map);
        }
    }, [setNewsletterSlugPresenseMap, pageSlug]);
};
