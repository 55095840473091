import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import Raven from 'raven-js';
import Entry from './Entry';
import ScrollToTop from './routes/ScrollToTop';
import root from 'window-or-global';
import {setLocale} from '@epic-core/common';
import pako from 'pako';
import {clientInterceptors} from '@epic-core/axios-client';
import {LocalizationContext, mergeLocalizationDataAltStore} from '@epic-mw/localization';
import {LocalizationContext as CoreLocalizationContext} from '@epic-core/localization';
import axios from 'axios';
import {bootstrapSharedValues, sharedStore, configureServices} from './app.initializers';
import {SharedValueContext} from '@epic-core/hooks';
import {RoutingRules} from './routes/RoutingRules';
import 'epic-tracking'; //to ensure analytics is setup before render

clientInterceptors();
axios.defaults.timeout = 15000; //studio signup can sometimes take longer than the default of 5000ms

if (process.env.NODE_ENV === 'production') {
    Raven.config('https://5e4f77cca6234f2aa771268c2d945ca0@sentry.io/172392').install();
}

/**
 * The react app will look for a DOM element with this ID and insert itself there.
 */

const init = () => {
    const reactDomEle = root.document.getElementById('ueReactWrapper');
    const renderClient = function renderClient() {
        const i18nContext = mergeLocalizationDataAltStore(
            root.unrealEngine_dataPreload.LocalizationStore
        );
        ReactDOM.hydrate(
            <BrowserRouter>
                <SharedValueContext.Provider value={sharedStore}>
                    <LocalizationContext.Provider value={i18nContext}>
                        <CoreLocalizationContext.Provider value={i18nContext}>
                            <RoutingRules>
                                <ScrollToTop>
                                    <Entry rootCmsPage={root.cmsPage} />
                                </ScrollToTop>
                            </RoutingRules>
                        </CoreLocalizationContext.Provider>
                    </LocalizationContext.Provider>
                </SharedValueContext.Provider>
            </BrowserRouter>,
            reactDomEle
        );
    };

    // only proceed if the react ele exists
    if (reactDomEle) {
        const rawPreload = root.unrealEngine_dataPreload;
        let dataPreload;
        if (rawPreload && typeof rawPreload === 'string') {
            //decompress the model from a Uint8Array inside of a string
            const preload = pako.inflate(rawPreload.split(','), {to: 'string'});
            dataPreload = JSON.parse(preload);
            root.unrealEngine_dataPreload = dataPreload;
            setLocale(root.__locale);
        } else {
            throw new Error('client/index rawPreload not found, app will not render');
        }
        bootstrapSharedValues(dataPreload).then(() => {
            configureServices();
            renderClient();
        });
    } else {
        setTimeout(() => {
            init();
        }, 10);
    }
};

init();
