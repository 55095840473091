import axios, {AxiosResponse} from 'axios';
import {getLocale} from '@epic-core/common';
import {AltLocPageData} from '../types';
import {getOrigin} from '../utils';
import {getPageNoLeadingSlash} from '@epic-mw/common-utils';
import {convertEnToEnUS} from '../utils/localizationUtils';

export interface CmsUrls {
    CMS_PAGE_DATA: (locale, urlPattern) => string;
    CMS_ALT_LOCALES_PAGE_DATA: (locale, urlPattern) => string;
    CMS_PAGE_BULK: string;
    CMS_TREE_DATA: (locale, urlPattern) => string;
}

let customUrl: CmsUrls;

const url = () => {
    if (customUrl) return customUrl;

    const origin = getOrigin();

    const url: CmsUrls = {
        CMS_PAGE_DATA: (locale, urlPattern) =>
            `${origin}/api/v2/${locale}/cms/${getPageNoLeadingSlash(urlPattern)}`,
        CMS_ALT_LOCALES_PAGE_DATA: (locale, urlPattern) =>
            `${origin}/api/v2/cms-all-locales/${getPageNoLeadingSlash(urlPattern)}?skip=${locale}`,
        CMS_PAGE_BULK: `${origin}/api/v2/cms-bulk`,
        CMS_TREE_DATA: (locale, urlPattern) =>
            `${origin}/api/v2/${locale}/cms-tree/${getPageNoLeadingSlash(urlPattern)}`
    };
    return url;
};

interface Generic {
    [key: string]: any;
}

interface BulkPageDataRes {
    ids: Generic[];
    slugs: Generic[];
    locale: string;
}

class CmsApi {
    public setUrl(url: CmsUrls) {
        customUrl = url;
    }

    public async getPageData<T = any>(urlPattern: string, locale?: string): Promise<T> {
        const api = url().CMS_PAGE_DATA(locale || getLocale(), urlPattern);
        try {
            const response: AxiosResponse<T> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error(`getPageData() failed to load cms page ${urlPattern} at url ${api}`);
            throw e;
        }
    }

    public async getAltLocPageData(urlPattern: string, locale?: string): Promise<any> {
        const api = url().CMS_ALT_LOCALES_PAGE_DATA(locale || getLocale(), urlPattern);
        try {
            const response: AxiosResponse<AltLocPageData> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getAltLocPageData() failed to load cms page', urlPattern);
            throw e;
        }
    }

    public async getBulkPageData({
        slugs = [],
        ids = [],
        locale,
    }: {
        slugs?: string[];
        ids?: string[];
        locale?: string;
    }): Promise<BulkPageDataRes> {
        if (!slugs.length && !ids.length) {
            console.warn('getBulkPageData() nothing to lookup');
            return {
                ids: [],
                slugs: [],
                locale: ''
            };
        }

        const api = url().CMS_PAGE_BULK;
        const localeToFetch = locale || getLocale();
        try {
            const response: AxiosResponse<BulkPageDataRes> = await axios.post(api, {
                locale: convertEnToEnUS(localeToFetch),
                slugs,
                ids
            });
            return response.data;
        } catch (e) {
            console.error(
                'getBulkPageData() failed to load cms pages',
                slugs.join(','),
                ids.join(','),
                localeToFetch
            );
            throw e;
        }
    }

    public async getTreeData(urlPattern: string, locale?: string): Promise<any> {
        const api = url().CMS_TREE_DATA(locale || getLocale(), urlPattern);
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getTreeData() failed to load cms page', urlPattern);
            throw e;
        }
    }
}

export const cmsApi = new CmsApi();
