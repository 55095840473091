import React, {useRef, useEffect, useState} from 'react';
import {OverlappingMarkerSpiderfier} from 'epic-google-maps-tools';
import debounce from 'lodash.debounce';
import root from 'window-or-global';
import MarkerClusterer from '@google/markerclusterer';
import {GOOGLE_MAP_STYLE} from './mapTheme';
import {useScript} from '../shared/useScript';
import {getLocale} from '@epic-core/common';
import {TableFallback} from './TableFallback';
import {MapWrap, Map, GoogleMap} from './UEMap.styles';
import {UnrealLoading} from 'epic-ue-loading';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';

export interface MarkerProps {
    coordinates?: {
        lat: number;
        lng: number;
    };
    marker?: any;
    infoWindow?: any;
    _meta: any;
    url?: string;
    name?: string;
    email?: string;
    phone?: string;
    postcode?: string;
    street?: string;
    city?: string;
}
interface TooltipItemDisplayProps {
    property: string;
    url?: string;
    variant?: 'primary' | 'default';
}

export interface UEMapMarkerTooltipConfig {
    properties: TooltipItemDisplayProps[];
}

export interface UEMapProps {
    children: any;
    clusterIcon?: string;
    clusterMarkers?: boolean;
    clusterThreshold?: number;
    description?: string;
    id: string;
    initLat?: number;
    initLong?: number;
    items: MarkerProps[];
    markerIcon?: string;
    markerTooltipConfig: UEMapMarkerTooltipConfig;
    maxZoom?: number;
    minZoom?: number;
    onMapCenterChanged?(): void;
    title?: string;
    zoom?: number;
}

export const UEMapStateContext = React.createContext({} as {itemsInView: MarkerProps[]});
export const clickedItemInViewKey = createSharedValueKey<MarkerProps | null>(
    'clickedItemInView',
    null
);

const getTooltipHtmlContent = (item: MarkerProps, config: UEMapMarkerTooltipConfig) => {
    const {properties = []} = config;
    const {_meta = {}} = item;
    return properties.reduce((tooltipHtmlContent, {property, url, variant}) => {
        if (_meta[property]) {
            const style = variant === 'primary' ? 'color: #0aff1' : 'color: #000';
            if (url && _meta[url]) {
                return tooltipHtmlContent.concat(
                    `<a href="${_meta[url]}" target="_blank" rel="noopener noreferrer">${_meta[property]}</a>`
                );
            } else {
                return tooltipHtmlContent.concat(`<div style="${style}">${_meta[property]}</div>`);
            }
        }
        return tooltipHtmlContent;
    }, '');
};

export const UEMap = ({
    children,
    clusterIcon,
    clusterMarkers,
    clusterThreshold,
    description,
    id,
    initLat,
    initLong,
    items,
    markerIcon,
    markerTooltipConfig,
    maxZoom,
    minZoom,
    onMapCenterChanged,
    title,
    zoom
}: UEMapProps): JSX.Element => {
    const mapEle = useRef<HTMLDivElement>(null);
    const GOOGLE_MAP_API_KEY = 'AIzaSyCNRzlFEd__c05xcz4MJcqX1c0qvqFfO_w';
    const locale = getLocale();
    const src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&language=${locale}`;
    const [mapReady, setMapReady] = useState(false);
    const [itemsInView, setItemsInView] = useState<MarkerProps[]>([]);
    const [initializedMap, setInitializedMap] = useState<any>(null);
    const [clickedItemInView, setClickedItemInView] = useSharedValue(clickedItemInViewKey);
    const [loaded, error] = useScript(src);

    if (loaded && !mapReady) {
        setMapReady(true);
    }

    const defaultIcon = 'https://cdn2.unrealengine.com/ue-logo-white-30x30-756beeeda0ae.png';
    const google = root.google;

    useEffect(() => {
        if (root.__server_side_render) return;

        const styles = GOOGLE_MAP_STYLE;

        if (mapReady && !initializedMap) {
            let map: any = null;
            let infoOpen: any = null;
            const markers: any[] = [];
            const Maps = google.maps;
            const defaultLat = 20.000000000000004; // fix for float number precision
            const defaultLng = -20.000000000000007; // fix for float number precision
            const defaultZoom = 3;
            const zoomLevel = zoom || defaultZoom;
            const lat = initLat || defaultLat;
            const lng = initLong || defaultLng;
            let markerImg = markerIcon || defaultIcon;

            map = new Maps.Map(mapEle.current, {
                center: {lat, lng},
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                backgroundColor: 'none',
                restriction: {
                    latLngBounds: {
                        north: 85,
                        south: -85,
                        west: 0,
                        east: 365
                    },
                    strictBounds: false
                },
                zoom: zoomLevel,
                minZoom: minZoom || 2,
                styles,
                maxZoom: maxZoom || 12
            });

            setInitializedMap(map);

            const getItemsInView = () => {
                const mapView = map.getBounds();
                return (items || []).filter((item) => {
                    if (!item.coordinates || !item.coordinates.lat || !item.coordinates.lng) {
                        return false;
                    }
                    return mapView.contains(item.coordinates);
                });
            };

            const handleItemsInView = () => {
                const itemsInView = getItemsInView();
                setItemsInView(itemsInView);
            };

            const onMapCenterChangedHandler = debounce(
                () => {
                    handleItemsInView();
                    if (onMapCenterChanged && typeof onMapCenterChanged === 'function') {
                        onMapCenterChanged();
                    }
                },
                1000,
                {trailing: true}
            );

            const onMarkerClickHandler = (infoWindow, marker) => () => {
                if (infoOpen) {
                    infoOpen.close();
                }
                infoOpen = infoWindow;
                infoWindow.open(map, marker);
            };

            Maps.event.addListener(map, 'center_changed', onMapCenterChangedHandler);

            Maps.event.addListenerOnce(map, 'idle', () => {
                handleItemsInView();
            });

            if (items && items.length) {
                const blue = '#0aaff1';
                const oms = new OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,
                    markersWontHide: true,
                    basicFormatEvents: true,
                    legWeight: 1,
                    keepSpiderfied: true,
                    legColors: {
                        highlighted: {
                            [google.maps.MapTypeId.HYBRID]: blue,
                            [google.maps.MapTypeId.ROADMAP]: blue,
                            [google.maps.MapTypeId.SATELLITE]: blue,
                            [google.maps.MapTypeId.TERRAIN]: blue
                        },
                        usual: {
                            [google.maps.MapTypeId.HYBRID]: blue,
                            [google.maps.MapTypeId.ROADMAP]: blue,
                            [google.maps.MapTypeId.SATELLITE]: blue,
                            [google.maps.MapTypeId.TERRAIN]: blue
                        }
                    }
                });
                items.forEach((item) => {
                    const {coordinates} = item;

                    if (coordinates === null) {
                        return;
                    }

                    if (!coordinates?.lat || !coordinates?.lng) {
                        console.error('Invalid coordinates!', coordinates);
                        return;
                    }

                    const marker = new Maps.Marker({
                        icon: markerImg,
                        position: coordinates
                    });

                    const infoWindow = new Maps.InfoWindow({
                        content: getTooltipHtmlContent(item, markerTooltipConfig)
                    });

                    Maps.event.addListener(
                        marker,
                        'spider_click',
                        onMarkerClickHandler(infoWindow, marker)
                    );
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    oms.addMarker(marker, () => {});
                    item.marker = marker;
                    item.infoWindow = infoWindow;
                    markers.push(marker);
                });
            }

            if (clusterMarkers) {
                const defaultClusterIcon =
                    'https://cdn2.unrealengine.com/ue5-logo-with-circle-map-100x100-3250227b9091.png';
                markerImg = clusterIcon || defaultClusterIcon;
                const markerCluster = new MarkerClusterer(map, markers, {
                    maxZoom: 14,
                    styles: Array(clusterThreshold || 3).fill({
                        url: markerImg,
                        height: 100,
                        width: 100
                    })
                });
                console.log('markerCluster:', markerCluster);
            }
        }
    }, [mapReady, items, zoom, initLat, initLong, mapEle, google]);

    useEffect(() => {
        const smoothZoom = (max, cnt) => {
            if (cnt <= max) {
                const zoomEvent = google.maps.event.addListener(
                    initializedMap,
                    'zoom_changed',
                    () => {
                        google.maps.event.removeListener(zoomEvent);
                        smoothZoom(max, cnt + 1);
                    }
                );
                setTimeout(() => {
                    initializedMap?.panTo(clickedItemInView?.coordinates);
                    initializedMap?.setZoom(cnt);
                }, 100);
            }
        };
        if (initializedMap && clickedItemInView && clickedItemInView?.coordinates) {
            initializedMap?.panTo(clickedItemInView?.coordinates);
            smoothZoom(maxZoom || 12, initializedMap?.getZoom());
        }

        setClickedItemInView(null);
    }, [clickedItemInView]);

    return (
        <Map>
            {!loaded || !mapReady ? <UnrealLoading /> : ''}
            <TableFallback items={items} title={title} description={description} hidden={!error} />
            {mapReady && !error ? (
                <MapWrap>
                    <GoogleMap id={id} ref={mapEle} title={title} description={description} />
                    <UEMapStateContext.Provider value={{itemsInView}}>
                        {children}
                    </UEMapStateContext.Provider>
                </MapWrap>
            ) : (
                ''
            )}
        </Map>
    );
};
