import React from 'react';
import {Helmet} from 'react-helmet';
import {getLocale} from '@epic-core/common';
import EventPage from './EventPage';
import {EventSpotlightPage} from './EventSpotlightPage';
import BlogPage from './BlogPage';
import Event from '../components/events/Event';
import styled from 'styled-components';
import {
    getMetaTags,
    getHelmetForMetaTags,
    getUrlPattern,
    EVENT_TEMPLATE_NAME,
    PROGRAM_TEMPLATE_NAME,
    EVENTSPOTLIGHT_TEMPLATE_NAME,
    AppSharedKeys,
    InitSharedValueResponse,
    RouteMatchOrUrlParse,
    STARDUST_ARTICLE_TEMPLATE_NAME,
    STARDUST_EVENT_TEMPLATE_NAME,
    getMessageFnType,
    cventApi
} from 'epic-ue-shared';
import {useLocalization} from '@epic-mw/localization';
import {Params, useLocation, useMatch, useParams} from 'react-router-dom';
import {useFeedStoreItem, cmsApi, useFeedItem, feedApi} from '../shared';
import {UnrealLoading} from 'epic-ue-loading';
import ErrorPage from './ErrorPage';
import ArticleDetailStardust from '../components/article/ArticleDetailStardust';
import {ClassicRouteChildren} from '../classic/ClassicRoute';
import EventStardustPage from './stardust/EventStardustPage';
import {feedPreloadSharedValues} from './FeedDetailsPage';

const rootPageSlug = 'events';

const Container = styled.div`
    overflow-x: hidden;
`;

const getSlug = (params: Params) => {
    const slug = params.slug || params.title || params.typeSlug;
    const urlPattern = `/${rootPageSlug}/${slug}`;
    return {urlPattern, slug};
};

const EventPageContainer = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const params = useParams();
    const {urlPattern} = getSlug(params);
    const {item} = useFeedItem(urlPattern);

    const {cmsLoading, cmsLoadedEmpty, feedItem = {}, isBlogDetails} = useFeedStoreItem(urlPattern);

    const getMessage = useLocalization() as getMessageFnType;
    const locale = getLocale();

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const event = feedItem || {};

    if (isBlogDetails) {
        return <BlogPage rootPageSlug={rootPageSlug} blog={feedItem} match={match} />;
    }

    const eventHeader = event.header || {};
    const metaTagOverrides = event.metaTagOverrides || {};
    const opSec1 = event.opSec1 || {};
    const background = event.background || {};
    const cmsImages = event._images_ || [];
    const bgImage =
        background.content && background.content.length ? background.content[0] : cmsImages[0];
    const shareImage = event.shareImage || eventHeader.backgroundImageURL || bgImage;
    const eventsTitle = getMessage('epic.ue.events.social.meta.title');

    const metaTags = getMetaTags(
        getMessage,
        metaTagOverrides.title || eventHeader.title
            ? `${eventsTitle} | ${eventHeader.title}`
            : eventsTitle,
        metaTagOverrides.desc || eventHeader.body || opSec1.copy,
        metaTagOverrides.shareImage || shareImage
    );

    const isStardust = event && event._templateName === STARDUST_ARTICLE_TEMPLATE_NAME;
    if (event && isStardust) {
        return (
            <ArticleDetailStardust
                pageData={event}
                locale={getLocale()}
                feedItemTags={item?.tags || []}
            />
        );
    }

    let view;
    let ignoreClassicApp = false;

    switch (event._templateName) {
        case EVENT_TEMPLATE_NAME:
            view = (
                <div className="row">
                    <EventPage event={event} />
                </div>
            );
            break;
        case EVENTSPOTLIGHT_TEMPLATE_NAME:
            view = <EventSpotlightPage pageData={event} />;
            break;
        case STARDUST_EVENT_TEMPLATE_NAME:
            view = <EventStardustPage pageData={event} />;
            ignoreClassicApp = true;
            break;
        default:
            view = (
                <div className="row">
                    <Event locale={locale} event={event} getMessage={getMessage} />
                </div>
            );
    }

    return (
        <ClassicRouteChildren ignoreClassicApp={ignoreClassicApp}>
            <Container>
                <Helmet meta={metaTags} title={eventsTitle} />
                {getHelmetForMetaTags(event)}
                {view}
            </Container>
        </ClassicRouteChildren>
    );
};

EventPageContainer.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<InitSharedValueResponse[]> => {
    const urlPattern = getUrlPattern(match);
    const pageData = await cmsApi.getPageData(urlPattern);

    let responses = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [urlPattern]: pageData
            }
        }
    ];

    responses = await feedPreloadSharedValues(urlPattern, responses);

    if (pageData._templateName === PROGRAM_TEMPLATE_NAME) {
        const highlightPanel = pageData.highlightPanel || {};
        const highlightList = highlightPanel.highlights || [];
        const highlightRefs: string[] = [];
        highlightList.forEach((highlightWrap) => {
            if (highlightWrap && highlightWrap.highlight) {
                highlightRefs.push(highlightWrap.highlight);
            }
        });

        const bulkResponse = (await cmsApi.getBulkPageData({ids: highlightRefs})) || {};
        const ids = bulkResponse.ids || {};
        for (const id in ids) {
            if (Object.prototype.hasOwnProperty.call(ids, id)) {
                const pageRes = ids[id];
                responses.push({
                    key: AppSharedKeys.CmsStore,
                    value: {
                        [id]: pageRes
                    }
                });
            }
        }
    }

    if (pageData._templateName === STARDUST_EVENT_TEMPLATE_NAME) {
        const cventIds: string[] = [];
        if (pageData?.sections?.items?.length) {
            pageData.sections.items.forEach((section) => {
                if (section?.items?.length) {
                    section.items.forEach(async (sectionItem) => {
                        if (sectionItem?.cventAgenda?.eventId) {
                            cventIds.push(sectionItem.cventAgenda.eventId);
                        }
                    });
                }
            });
        }
        const promises = cventIds.map((eventId) => cventApi.getAgenda(eventId));
        const agendas = await Promise.all(promises);
        const data = {};
        cventIds.forEach((val, key) => {
            data[val] = agendas[key];
        });
        responses.push({
            key: AppSharedKeys.CventAgendaStore,
            value: data
        });
    }

    return responses;
};

export default EventPageContainer;
