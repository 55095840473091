import {useContext, useEffect} from 'react';
import {getPath} from '@epic-core/common';
import {useParams, useNavigate, useLocation} from 'react-router';
import {HttpContext} from '../../server/utils/httpContext';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';

export interface RedirectProps {
    to?: string; //a pathname without the locale e.g. /uses/games
    url?: string; //a full URL starting with http e.g. https://www.unrealengine.com/marketplace
    replace?: boolean;
    status?: number;
    omitSearch?: boolean;
}
export const Redirect = ({
    to,
    url,
    replace = false,
    status = 302,
    omitSearch = false
}: RedirectProps): null => {
    if (!to && !url) {
        throw new Error('Redirect component requires either to or url to be specified');
    }

    const path = getPath();
    const navigate = useNavigate();
    const params = useParams();
    const {search} = useLocation();
    const context = useContext(HttpContext);
    const locale = params.locale || getLocale();

    const newPath = `${path}${locale}${to}`;
    const isFullUrl = !!url;

    let redirectUrl = `${url || newPath}${!omitSearch ? search || '' : ''}`;

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const value = params[key] || '';
            redirectUrl = redirectUrl.replace(`:${key}`, value);
        }
    }

    useEffect(() => {
        if (isFullUrl) {
            if (replace) {
                root.location.replace(redirectUrl);
            } else {
                root.location.href = redirectUrl;
            }
        } else {
            navigate(redirectUrl, {replace});
        }
    }, [navigate, redirectUrl, replace]);

    if (context) {
        context.status = status;
        context.url = redirectUrl;
    }

    return null;
};
