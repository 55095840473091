import root from 'window-or-global';

import {GraphQlService} from '@epic-node-services/graphql-public-service';

const EULA_LATEST_QUERY = (eulaId, locale) => `
{
    Eula {
        getLatestAndConvert(id: "${eulaId}", locale: "${locale}"){
            key
            title
            body
            version
        }
    }
}`;

interface EulaLatestRes {
    body: string;
    title: string;
    key: string;
    version: number;
}

interface EulaLatestQueryRes {
    data: {
        Eula: {
            getLatestAndConvert: EulaLatestRes;
        };
    };
}

const EULA_ACCEPTED_QUERY = (eulaId, locale, accountId) => `
{
    Eula {
        hasAccountAccepted(id: "${eulaId}", locale: "${locale}", accountId: "${accountId}"){
            accepted
            key
            locale
            version
        }
    }
}`;

export interface EulaAcceptedRes {
    accepted: boolean;
    key: string;
    locale: string;
    version: number;
}

interface EulaAcceptedQueryRes {
    data: {
        Eula: {
            hasAccountAccepted: EulaAcceptedRes;
        };
    };
}

const EULA_ACCEPT_MUTATION = (
    eulaId: string,
    locale: string,
    accountId: string,
    version: number
) => `
mutation {
    Eula {
        acceptEula(id: "${eulaId}", locale: "${locale}", accountId: "${accountId}", version: ${
    version || 0
}){
            accepted
            version
        }
    }
}`;

interface EulaAcceptRes {
    accepted: boolean;
    version: number;
}

interface EulaAcceptMutationRes {
    data: {
        Eula: {
            acceptEula: EulaAcceptRes;
        };
    };
}

class EulaApi {
    public async getEulaData(key: string, locale?: string): Promise<any> {
        if (!key) {
            throw new Error('getEulaData requires key');
        }

        try {
            //default is en
            const finalLocale = !locale || locale === 'en-US' ? 'en' : locale;

            const response: EulaLatestQueryRes = await GraphQlService.query(
                EULA_LATEST_QUERY(key, finalLocale)
            );
            const data = response.data || {};
            const eula = data.Eula || {};
            const getLatestAndConvert = eula.getLatestAndConvert || {};
            return getLatestAndConvert;
        } catch (e) {
            console.error('getEulaData() failed to load', key);
            throw e;
        }
    }

    public async getEulaAccepted(key: string, accountId: string, locale?: string): Promise<any> {
        if (root.__server_side_render) return;

        if (!key || !accountId) {
            throw new Error('getEulaAccepted requires key, accountId');
        }

        try {
            //default is en
            const finalLocale = !locale || locale === 'en-US' ? 'en' : locale;

            const response: EulaAcceptedQueryRes = await GraphQlService.query(
                EULA_ACCEPTED_QUERY(key, finalLocale, accountId)
            );
            const data = response.data || {};
            const eula = data.Eula || {};
            const hasAccountAccepted = eula.hasAccountAccepted || {};
            return hasAccountAccepted;
        } catch (e) {
            console.error('getEulaAccepted() failed to load', key);
            throw e;
        }
    }

    public async acceptEula(
        key: string,
        accountId: string,
        version: number,
        locale?: string
    ): Promise<any> {
        if (!key || !accountId) {
            throw new Error('acceptEula requires key, acountId, and version');
        }

        try {
            const finalLocale = !locale || locale === 'en-US' ? 'en' : locale;

            const response: EulaAcceptMutationRes = await GraphQlService.query(
                EULA_ACCEPT_MUTATION(key, finalLocale, accountId, version)
            );
            const data = response.data || {};
            const eula = data.Eula || {};
            const acceptEula = eula.acceptEula || {};
            return acceptEula;
        } catch (e) {
            console.error('acceptEula() failed to load', key);
            throw e;
        }
    }
}

export const eulaApi = new EulaApi();
