import styled from 'styled-components';

export const MosaicWrapper = styled.div`
    @media (min-width: 992px) {
        .grid-news-item:not(.featured) {
            margin-bottom: 0;
        }

        .grid-news-item:not(.featured):hover .grid-news-title,
        .grid-news-item:not(.featured):hover .grid-news-date,
        .grid-news-item:not(.featured):focus .grid-news-title,
        .grid-news-item:not(.featured):focus .grid-news-date,
        .grid-news-item:not(.featured):visited .grid-news-title,
        .grid-news-item:not(.featured):visited .grid-news-date,
        .grid-news-item:not(.featured):active .grid-news-title,
        .grid-news-item:not(.featured):active .grid-news-date {
            color: ${(p) => p.theme.palette.text.secondary};
        }

        .grid-news-row {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        .grid-news-row.short-row {
            justify-content: flex-start;
            min-height: 364px;
        }

        .grid-news-cell {
            flex-basis: 33.3334%;
        }

        .grid-news-cell.w50 {
            flex-basis: 50%;
        }

        .grid-news-cell.w66 {
            flex-basis: 66.6664%;
        }

        .grid-news-cell.w66 .horizontal:first-child:not(:only-child) {
            margin-bottom: 26px;
        }

        .grid-news-cell.w100 {
            flex-basis: 100%;
        }

        .grid-news-cell-inner {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }
    }

    .grid-news-row {
        margin-bottom: 25px;
    }

    .grid-news-cell-column .grid-news-item {
        height: auto;
    }

    .grid-news-cell-inner .grid-news-item {
        height: 100%;
    }

    .grid-news-item + .grid-news-item,
    .grid-news-item + .grid-news-cell-inner {
        margin-top: 20px;
    }

    .add-hover div.img-responsive:hover,
    .add-hover div.img-responsive:focus,
    .add-hover div.img-responsive:active {
        cursor: pointer;
        opacity: 0.7;
    }

    .featured.add-hover:hover .grid-news-title,
    .featured.add-hover:hover .grid-news-date,
    .featured.add-hover:hover .grid-news-text,
    .featured.add-hover:focus .grid-news-title,
    .featured.add-hover:focus .grid-news-date,
    .featured.add-hover:focus .grid-news-text,
    .featured.add-hover:active .grid-news-title,
    .featured.add-hover:active .grid-news-date,
    .featured.add-hover:active .grid-news-text {
        color: ${(p) => p.theme.palette.text.primary};
    }

    .featured.add-hover:hover .grid-news-title h2,
    .featured.add-hover:hover .grid-news-date h2,
    .featured.add-hover:hover .grid-news-text h2,
    .featured.add-hover:focus .grid-news-title h2,
    .featured.add-hover:focus .grid-news-date h2,
    .featured.add-hover:focus .grid-news-text h2,
    .featured.add-hover:active .grid-news-title h2,
    .featured.add-hover:active .grid-news-date h2,
    .featured.add-hover:active .grid-news-text h2 {
        color: ${(p) => p.theme.palette.text.primary};
    }

    .grid-news-inner {
        cursor: default;
        height: 100%;
    }

    .grid-news-cell.featured .grid-news-inner {
        cursor: pointer;
        height: auto;
    }

    .grid-news-item {
        color: ${(p) => p.theme.palette.text.secondary};
        text-decoration: none;
        font-size: 20px;
        margin: 0 13px 0;
        overflow: hidden;
        display: block;
        height: 100%;
        flex-basis: 33.3334%;
        background: ${(p) => p.theme.palette.background.default};
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 2px;
        border: 1px solid ${(p) => p.theme.palette.background.paper};
        cursor: default;
    }

    @media (max-width: 991px) {
        .grid-news-item {
            margin: 0 13px 30px;
        }
    }

    @media (max-width: 767px) {
        .grid-news-item {
            height: auto !important;
            padding-bottom: 20px;
        }
    }

    .grid-news-item.featured {
        flex-basis: 66.6668%;
        position: relative;
    }

    .grid-news-item.featured .grid-news-inner {
        width: 100%;
        padding: 35px;
    }

    @media (min-width: 768px) {
        .grid-news-item.featured:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 25%;
            z-index: 2;
            background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
        }
    }

    @media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
        .grid-news-item.featured:before {
            left: 0;
            right: 0;
        }
    }

    @media (min-width: 768px) {
        .grid-news-item.featured .grid-news-category {
            color: ${(p) => p.theme.palette.text.primary};
        }
    }

    @media (min-width: 768px) {
        .grid-news-item.featured .grid-news-inner {
            background: transparent;
            color: ${(p) => p.theme.palette.text.primary};
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 35px;
            z-index: 3;
        }
    }

    .grid-news-item.featured .grid-news-text {
        text-decoration: none;
    }

    .grid-news-item.featured .grid-news-image div.img-responsive {
        display: block;
    }

    @media (max-width: 767px) {
        .grid-news-item.featured {
            height: auto !important;
        }

        .grid-news-item.featured .grid-news-item {
            height: auto;
        }

        .grid-news-item.featured .grid-news-image {
            height: 300px;
        }

        .grid-news-item.featured .grid-news-title *,
        .grid-news-item.featured .grid-news-date *,
        .grid-news-item.featured .grid-news-text *,
        .grid-news-item.featured .grid-news-category,
        .grid-news-item.featured .grid-news-category span {
            border-color: ${(p) => p.theme.palette.background.paper} !important;
        }
    }

    .grid-news-item.vertical .grid-news-category {
        margin-top: -2px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .grid-news-item.vertical .grid-news-image {
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
        }
    }

    .grid-news-item.horizontal {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        .grid-news-image {
            flex-basis: 55%;
            flex-grow: 1;
        }
        .grid-news-inner {
            flex-basis: 45%;
            flex-grow: 1;
            min-width: 210px;
        }
    }

    .grid-news-item.horizontal .grid-news-inner {
        padding: 35px;
    }

    .grid-news-item.horizontal .grid-news-image div.img-responsive {
        object-fit: cover;
    }

    @media (max-width: 767px) {
        .grid-news-item.horizontal {
            height: auto !important;
        }
    }

    @media (max-width: 767px) {
        .grid-news-item.horizontal .grid-news-image {
            height: 300px;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
        }
    }

    .grid-news-item.vertical.block .grid-news-image,
    .grid-news-item.vertical.block .grid-news-inner {
        flex-basis: 100%;
    }

    .grid-news-item.featured .grid-news-title * {
        color: ${(p) => p.theme.palette.text.primary};
    }

    .grid-news-item.featured .grid-news-inner {
        width: 80%;
    }

    @media (max-width: 767px) {
        .grid-news-item.featured .grid-news-inner {
            width: 100%;
        }
    }

    .grid-news-item.featured .grid-news-text {
        color: ${(p) => p.theme.palette.text.primary};
    }

    .grid-news-item.featured .grid-news-category {
        color: ${(p) => p.theme.palette.text.primary};
    }

    .grid-news-item.featured .grid-news-category span {
        color: ${(p) => p.theme.palette.text.primary};
        padding: 2px 8px;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
        margin-top: -15px;
        margin-right: 5px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .grid-news-item {
            background: ${(p) => p.theme.palette.background.default};
            padding-left: 0;
            padding-right: 0;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media (min-width: 992px) {
        .grid-news-item {
            flex-basis: 33.3334%;
        }

        .grid-news-item.flex {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
        }

        .grid-news-item.featured,
        .grid-news-item.horizontal {
            flex-basis: 66.6664%;
        }
    }

    .grid-news-image {
        overflow: hidden;
        background-color: #000;
        height: 100%;
    }

    .grid-news-image .ue-placeholder {
        opacity: 0.9;
        background-color: black;
        display: block;
        width: 100%;
        height: 100% !important;
        padding: 18%;
    }

    .grid-news-image div.img-responsive {
        transition: opacity 0.2s ease-out;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .grid-news-image div.img-responsive.ue-placeholder {
        background-size: 100%;
    }

    .vertical .grid-news-inner,
    .block .grid-news-inner,
    .small-block .grid-news-inner {
        padding: 35px;
    }

    @media (max-width: 991px) {
        .vertical .grid-news-inner,
        .block .grid-news-inner,
        .small-block .grid-news-inner {
            padding: 20px;
        }
    }

    @media (max-width: 767px) {
        .vertical .grid-news-inner,
        .block .grid-news-inner,
        .small-block .grid-news-inner {
            padding: 18px 26px;
        }
    }

    @media only screen and (max-width: 1199px) {
        .block .grid-news-inner,
        .small-block .grid-news-inner,
        .vertical .grid-news-inner {
            padding: 20px;
        }
    }

    .vertical .grid-news-image,
    .block .grid-news-image,
    .small-block .grid-news-image {
        height: 52.3%;
    }

    .vertical .grid-news-image div.img-responsive,
    .block .grid-news-image div.img-responsive,
    .small-block .grid-news-image div.img-responsive {
        height: 100%;
        object-fit: cover;
    }

    .grid-news-cell-inner.block.nogutter,
    .grid-news-cell-inner.small-block.nogutter {
        margin-bottom: 0;
    }

    .grid-news-cell-inner.block,
    .grid-news-cell-inner.small-block {
        margin-bottom: 25px;
    }

    .grid-news-cell-inner.block .card-sm.block .grid-news-item,
    .grid-news-cell-inner.small-block .card-sm.block .grid-news-item {
        max-height: 220px;
    }

    .grid-news-cell.card-md .grid-news-item {
        min-height: 277.5px;
    }

    .grid-news-row .w33.card-sm.small-block .grid-news-item {
        max-height: 220px;
    }

    @media (max-width: 767px) {
        .grid-news-row .w33.card-sm.small-block .grid-news-item {
            max-height: initial;
        }
    }

    .grid-news-row .w33.card-md.small-block .grid-news-item {
        min-height: 277.5px;
    }

    .grid-news-row .w33.card-lg.small-block .grid-news-item {
        min-height: 344.5px;
    }

    .grid-news-row.type-5 .w33.card-lg.small-block .grid-news-item {
        min-height: 364px;
    }

    .grid-news-row.type-3b .grid-news-cell.w66.card-lg.horizontal .grid-news-item {
        min-height: 364px;
    }

    .grid-news-row.type-3b .grid-news-cell.w33.card-lg .grid-news-item.vertical {
        min-height: 754px;
    }

    .grid-news-row.type-4a .w33.card-lg.small-block .grid-news-item {
        min-height: 364px;
    }

    .grid-news-row.type-4a .grid-news-cell.w33.card-lg.small-block {
        max-width: 403px;
    }

    .grid-news-row.type-2 .w33.card-lg.small-block .grid-news-item {
        min-height: 100%;
    }

    .grid-news-date {
        font-size: 13px;
        font-weight: 500;
        min-height: 12px;
        margin-top: 10px;
    }

    @media (max-width: 767px) {
        .grid-news-date {
            margin: 15px 0;
        }
    }

    .grid-news-date.featured {
        color: #9a9a9a;
        margin: 0;
    }

    .grid-news-date:empty {
        display: none !important;
    }

    .grid-news-title {
        font-size: 20px;
        margin-bottom: 3px;
        transition: color 0.3s ease-in-out;
    }

    .grid-news-title h2 {
        margin: 0;
    }

    @media (max-width: 767px) {
        .grid-news-title h2 {
            margin: 4px 0;
        }
    }

    .grid-news-category {
        height: 25px;
        display: block;
        width: 100%;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        .grid-news-category {
            display: none;
        }
    }

    .grid-news-category span {
        font-size: 13px;
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
        line-height: 25px;
        background: transparent;
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
        margin-right: 10px;
    }

    .grid-news-text {
        margin-top: 10px;
        line-height: 1.5;
        font-size: 14px;
        color: #777;
    }
`;
