import root from 'window-or-global';
import {RoutingRulesProps} from './route.types';
import {useContext} from 'react';
import {LocalizationContext} from '@epic-mw/localization';
import {getLocale} from '@epic-core/common';
import {RoutingRulesContext} from './RoutingRules';

export const getRoutingRules = async (): Promise<RoutingRulesProps[]> => {
    const clientConfig = root.clientEnvConfig || {};
    const rules: Response = await fetch(clientConfig.EPIC_ROUTING_RULES_URL, {
        // Includes cookies for CloudFront authorization
        credentials: 'include'
    });
    const data = (await rules.json()) as Promise<RoutingRulesProps[]>;
    return data;
};

const getFirstMatch = (
    routingRules: RoutingRulesProps[],
    pathname: string
): RoutingRulesProps | undefined => {
    for (let i = 0; i < routingRules.length; i++) {
        const item = routingRules[i];
        if (item.rule) {
            const re = new RegExp(item.rule);
            if (re.test(pathname)) {
                return item;
            }
        }
    }
    return undefined;
};

export const isRouteMatchOtherApp = (
    routingRules: RoutingRulesProps[],
    pathname: string
): boolean => {
    const match = getFirstMatch(routingRules, pathname);
    const clientConfig = root.clientEnvConfig || {};
    return Boolean(match?.origin && match.origin !== clientConfig.EPIC_ROUTING_ORIGIN);
};

export const useRoutingRules = (pathname: string) => {
    const routingRules = useContext(RoutingRulesContext);
    const {allLocales} = useContext(LocalizationContext);
    const locale = getLocale();

    let isLegacy = true;

    const clientConfig = root.clientEnvConfig || {};
    if (
        clientConfig.EPIC_ROUTING_OVERRIDE === true ||
        clientConfig.EPIC_ROUTING_OVERRIDE === 'true'
    ) {
        return true;
    }

    const pathnameHasLocale = allLocales.some(
        (locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`
    );

    let localePathname = pathname;
    if (!pathnameHasLocale) {
        localePathname = `/${locale}${pathname}`;
    }

    // only check relative paths
    if (pathname.startsWith('/')) {
        // check the routing rules to see if the path is this app
        if (isRouteMatchOtherApp(routingRules, localePathname)) {
            isLegacy = false;
        }
    }

    return isLegacy;
};
