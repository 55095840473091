import {isError, isAxiosError} from '@epic-mw/error-types';
import {AxiosContainer} from '@epic-node-services/public-axios';
import root from 'window-or-global';

const axios = AxiosContainer.getAxiosInstance();

export interface ErrorReportRes {
    success: boolean
}

export interface ErrorReportRequest {
    errorToReport: string;
    errorInfo: React.ErrorInfo;
    url?: string;
}

export const createAppKey = (request: ErrorReportRequest): string => {
    return JSON.stringify(request);
};

class ErrorFetch {
    async sendReportStr(errorRequestStr: string): Promise<ErrorReportRes> {
        let errorRequest: ErrorReportRequest;
        try {
            errorRequest = JSON.parse(errorRequestStr);
        } catch (e) {
            console.log('sendReportStr() invalid request');
            throw e;
        }

        errorRequest.url = root.window.location.href;

        try {
            const response = await axios.post(`/api/error-report`, errorRequest);
            return response.data;
        } catch (e) {
            console.error('sendReportStr() failed to load', isError(e) && e.message);
            if (isAxiosError(e)) {
                console.log(e.response);
            }
            throw e;
        }
    }

    async sendReport(errorRequest: ErrorReportRequest): Promise<ErrorReportRes> {
        try {
            errorRequest.url = root.window.location.href;
            const response = await axios.post(`/api/error-report`, errorRequest);
            return response.data;
        } catch (e) {
            console.error('sendReport() failed to load', isError(e) && e.message);
            if (isAxiosError(e)) {
                console.log(e.response);
            }
            throw e;
        }
    }

    async send404(path: string): Promise<ErrorReportRes> {
        try {
            const response = await axios.post(`/api/error/404`, {path});
            return response.data;
        } catch (e) {
            console.error('send404() failed to load', isError(e) && e.message);
            if (isAxiosError(e)) {
                console.log(e.response);
            }
            throw e;
        }
    }
}

export const errorFetcher = new ErrorFetch();
