import axios, {AxiosResponse} from 'axios';
import {isError} from '@epic-mw/error-types';
import {getOrigin} from '../utils';

const url = (id: string) => {
    const origin = getOrigin();

    return {
        CVENT_GET_AGENDA: `${origin}/api/cvent/sessions/${id}`,
        CVENT_SING_UP: `${origin}/api/cvent/${id}`
    };
};

class CventApi {
    public async getAgenda(eventId: string): Promise<any> {
        const api = url(eventId).CVENT_GET_AGENDA;

        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getAgenda() failed to load', isError(e) && e.message);
            throw e;
        }
    }

    public async signUp(eventId: string, target: string | null): Promise<any> {
        const api = url(eventId).CVENT_SING_UP;
        try {
            const response: AxiosResponse<any> = await axios.post(api, {target});
            return response.data;
        } catch (e) {
            console.error('signUp() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const cventApi = new CventApi();
