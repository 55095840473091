import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import {getBowserState, generateRoutePath} from '@epic-core/common';
import VisibilitySensor from 'react-visibility-sensor';
import {getOptImage} from 'epic-ue-shared';
import {Button} from 'epic-ue-components';

const RegisterPanel = styled.div`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0.5em;
    margin: 0;
    text-align: right;
    margin-top: 1em;
    transition: opacity 500ms ease-in-out;

    &&& .btn.btn-primary {
        padding: 6px 12px;
        min-width: initial;
        border-radius: 0;
        font-size: 0.9em;
        margin: 0;
        box-shadow: #1111111f 0px 0px 2px 2px;
    }
`;

const Card = styled.div`
    &&& {
        padding: 0;
    }
    position: relative;
    height: 500px;
    margin-top: 1em;
    transform: translateY(60px);
    opacity: 0;
    transition: opacity ease 0.5s 0.2s, transform ease 0.5s 0.3s;

    ${(p) => p.theme.breakpoints.down('md')} {
        height: 420px;
    }

    ${(p) => p.theme.breakpoints.down('md')} {
        height: 390px;
    }

    &.inView {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ImgWrap = styled.a`
    display: block;
    position: relative;
    height: 50%;
    width: 100%;
`;

const Title = styled.h3`
    &&& {
        text-align: left;
        font-size: 18px;
        margin: 0.25em 0 0.5em;
        font-weight: 500;
        line-height: 1.1;
        text-transform: uppercase;

        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 16px;
        }
    }
`;

const TitleLink = styled.a`
    color: white !important;
    &:hover {
        color: inherit;
    }
`;

const InnerCard = styled.div`
    height: 100%;
    margin: 0.5em;
    background: #202024;
    display: block;
    transition: opacity 350ms ease-in-out, color 350ms ease-in-out, background 350ms ease-in-out;
    opacity: 0.94;
    &&&,
    ${Title} {
        color: #e2e2e2;
        &:visited,
        &:active,
        &:focus,
        &:hover {
            color: white;
            ${Title} {
                color: white;
            }
        }
    }
    &:hover {
        opacity: 1;
        background: #191919;
        ${RegisterPanel} {
            opacity: 1;
        }
    }
`;

const CardImg = styled.div<{img: string}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => getOptImage({url: p.img, width: 500, quality: 'high'})});
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
`;

const ContentWrap = styled.div`
    padding: 1.5em 1em 1em;
`;

const P = styled.p`
    font-size: 14px;
    line-height: 1.6;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 13px;
        line-height: 1.5;
    }
`;

export const Badge = styled.div<{img: string}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => getOptImage({url: p.img, width: 200})});
    background-position: 50% 50%;
    height: 76px;
    width: 76px;
    position: absolute;
    left: 50%;
    bottom: -13px;
    transform: translateX(-50%);
    opacity: 0.94;
`;

interface Image {
    desktopImage: string;
    mobileImage: string;
    tabletImage: string;
}

interface Properties {
    title: string;
    short: string;
    image: Image;
    urlPattern: string;
    badge: string;
    url: string;
    quickRegisterBtn: string;
}

const CourseCard = ({
    title,
    short,
    image = {} as any,
    urlPattern,
    badge,
    url,
    quickRegisterBtn
}: Properties): JSX.Element | null => {
    const [inView, setInView] = useState<boolean>();
    const registerOnClick = useCallback((e) => {
        //skip the InnerCard click handling
        e.stopPropagation();
    }, []);

    const {mobile, tablet} = getBowserState();
    let img = image.desktopImage;
    if (tablet && image.tabletImage) {
        img = image.tabletImage;
    } else if (mobile && image.mobileImage) {
        img = image.mobileImage;
    }

    if (!img) return null;

    const href = generateRoutePath(urlPattern);

    return (
        <VisibilitySensor
            onChange={(isVisible) => {
                setInView(isVisible);
            }}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <Card className={`course-card col-sm-12 col-md-6 col-lg-4 ${inView ? 'inView' : ''}`}>
                <InnerCard>
                    <ImgWrap href={href} title={title}>
                        <CardImg img={img} />
                        {badge ? <Badge img={badge} /> : ''}
                    </ImgWrap>
                    <ContentWrap>
                        <Title>
                            <TitleLink href={href} title={title}>
                                {title}
                            </TitleLink>
                        </Title>
                        <P>{short}</P>
                    </ContentWrap>
                    {quickRegisterBtn && url ? (
                        <RegisterPanel>
                            <Button
                                id="course-sm-register-btn"
                                href={url}
                                onClick={registerOnClick}>
                                {quickRegisterBtn}
                            </Button>
                        </RegisterPanel>
                    ) : (
                        ''
                    )}
                </InnerCard>
            </Card>
        </VisibilitySensor>
    );
};

export default CourseCard;
