import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import root from 'window-or-global';
import {accountApi} from './account.api';
import {useEffect} from 'react';
import {getLocale} from '@epic-core/common';
import {isError} from '@epic-mw/error-types';
import {AccountRes} from '../types/account.types';

export const accountStoreKey = createSharedValueKey<AccountRes>(
    'accountStoreKey',
    {} as AccountRes
);

export const accountStoreInitKey = createSharedValueKey<{
    loading: boolean;
    loaded?: boolean;
    error?: string;
}>('accountStoreInitKey', {loading: false, loaded: false, error: ''});

let accountLoading = false; // prevent multiple hooks from calling this simulatenously

export const useAccountStore = (): {
    loaded?: boolean;
    loading: boolean;
    error?: string;
    account: AccountRes;
} => {
    const [loadingState, setLoadingState] = useSharedValue(accountStoreInitKey);
    const [accountStore, setAccountStore] = useSharedValue(accountStoreKey);

    useEffect(() => {
        if ((accountStore.id && !loadingState.error) || accountLoading || loadingState.loaded) {
            return;
        }
        async function fetchData() {
            try {
                accountLoading = true;
                setLoadingState({loading: true, loaded: false});
                const data = await accountApi.getAccount();
                setAccountStore(data);
                setLoadingState({loading: false, loaded: true});
                accountLoading = false;
            } catch (ex) {
                console.error('Failed to get account', ex);
                const error: string = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
                accountLoading = false;
            }
        }
        fetchData();
    }, [accountStore, accountLoading, loadingState]);

    return {
        account: accountStore,
        ...loadingState
    };
};

export const useIsLoggedIn = (): boolean => {
    const {account} = useAccountStore();
    return account.isLoggedIn || false;
};

export const useAccountEmail = (): string => {
    const {account} = useAccountStore();
    return account.email || '';
};

export const useAccountId = (): string => {
    const {account} = useAccountStore();
    return account.id || '';
};

export const useAccountCountry = (): string => {
    const {account} = useAccountStore();
    return account.country || '';
};

export const useAccountLoginRequired = (): {accountLoading: boolean; isLoggedIn: boolean} => {
    const {account, loading, loaded} = useAccountStore();
    const isLoggedIn = account?.isLoggedIn || false;
    if (loaded && !loading && !isLoggedIn) {
        root.location.href = `/${getLocale()}/auth?state=${root.location.href}`;
    }
    return {accountLoading: !loaded || loading, isLoggedIn};
};

export const useCabinedMode = (): boolean => {
    const {account} = useAccountStore();
    return account?.cabinedMode || false;
};
