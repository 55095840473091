import React from 'react';
import {
    AppSharedKeys,
    cmsApi,
    getHelmetForMetaTags,
    InitSharedValueResponse,
    PageData,
    RouteMatchOrUrlParse
} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import {useCmsData} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {UeThemeProvider, ueDarkTheme, ueLightTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {useParams} from 'react-router-dom';
import {
    TemplateThemeProps,
    SubnavSectionConfigProps,
    VerticalSpacer,
    SubnavSection,
    DownloadSection,
    NewsletterSection,
    AnimateOnViewWrap,
    HeroBasicProps,
    ImageHero
} from 'epic-ue-ui';
import {DownloadWithNewsletterSectionProps} from './ExplainersStardustPage';

import {
    ExplainersSectionSelection,
    ExplainersSectionSelectionProps
} from '../../components/explainers/ExplainersSectionSelection';
import {useLocalization} from '@epic-mw/localization';
import styled from 'styled-components';

const keysNoSpacer = ['newsletter'];

const getCmsUrlPattern = (pathname: string): string => {
    const pathArray = pathname.split('/');
    const topic = pathArray[pathArray.length - 2];
    const slug = pathArray[pathArray.length - 1];
    return `/explainers/${topic}/${slug}`;
};

const contentNeedsBottomSpacer = (contentSelection?: ExplainersSectionSelectionProps): boolean => {
    if (!contentSelection) return true;
    const items = contentSelection.items || [];
    if (items.length) {
        const lastItem = items[items.length - 1] || {};
        if (lastItem && Object.keys(lastItem).length) {
            for (const key in lastItem) {
                if (keysNoSpacer.indexOf(key) >= 0) {
                    return false;
                }
            }
        }
    }

    return true;
};

const Styled = styled.div`
    .with-caption span {
        font-size: 12px;
    }
`;

interface ExplainerDetailLandingCardProps {
    heading?: string;
    eyebrow?: string;
    description?: string;
    image?: string;
    imagelabel?: string;
}

interface ExplainersDetailPageData extends PageData {
    header?: HeroBasicProps;
    landing?: ExplainerDetailLandingCardProps;
    content?: ExplainersSectionSelectionProps;
    themeConfig?: TemplateThemeProps;
    download: DownloadWithNewsletterSectionProps;
}

const ExplainersDetailPage = (): JSX.Element => {
    const params = useParams();
    const topic = params.topic;
    const slug = params.slug;
    const cmsUrlPattern = `/explainers/${topic}/${slug}`;
    const {cmsLoading, cmsLoadedEmpty, pageData} =
        useCmsData<ExplainersDetailPageData>(cmsUrlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        download = {} as DownloadWithNewsletterSectionProps
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);
    const getMessage = useLocalization();

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const needBottomVerticalSpacer = contentNeedsBottomSpacer(pageData.content);
    const header = pageData?.header || {};

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <Styled>
                <ImageHero
                    smallDescription={header?.dateStr}
                    showScrollIndicator
                    showSocialIcons
                    thin
                    image={header?.image?.src}
                    eyebrow={header?.eyebrow}
                    title={header?.title}
                    description={header?.description}
                    caption={header?.caption?.text}
                    buttonText={header?.button1?.label}
                    href={header?.button1?.href}
                    secondaryButtonText={header?.button2?.label}
                    secondaryHref={header?.button2?.href}
                    shrinkGradientHeight={header?.shrinkGradientHeight}
                    imgTopAlignment={header?.imgTopAlignment || '40%'}
                    alignment="centerXY"
                />
                <VerticalSpacer variant={pageData?.header?.image?.src ? 'heading' : 'section'} />
                <AnimateOnViewWrap delay={0.6} translateY={10}>
                    <div className="article-section">
                        <ExplainersSectionSelection {...pageData.content} />
                    </div>
                </AnimateOnViewWrap>

                {needBottomVerticalSpacer && <VerticalSpacer variant="section" />}

                <VerticalSpacer variant="feature" />

                <SubnavSection
                    variant="gradient"
                    hideBottomSectionPadding
                    sectionConfig={downloadSectionConfig}>
                    <DownloadSection
                        {...downloadBlockProps}
                        accentColor={accentColor}
                        variant="standard"
                    />
                    <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                    <NewsletterSection
                        variant={download.newsletter?.variant || 'split'}
                        {...download?.newsletter}
                        privacyPolicyLinkName={
                            download?.newsletter?.privacyPolicyLinkName ||
                            getMessage('epic.privacy.policy')
                        }
                        title={
                            download?.newsletter?.title || getMessage('epic.ue.newsletter.title')
                        }
                        industryPlaceholder={
                            download?.newsletter?.industryPlaceholder ||
                            getMessage('epic.ue.newsletter.select.label')
                        }
                        accentColor={accentColor}
                    />
                </SubnavSection>
            </Styled>
        </UeThemeProvider>
    );
};

ExplainersDetailPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const cmsUrlPattern = getCmsUrlPattern(match?.pathname as string);

    const pageData = await cmsApi.getPageData<ExplainersDetailPageData>(cmsUrlPattern);

    return [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: pageData || {}
            }
        }
    ];
};

export default ExplainersDetailPage;
