import React, {useState, useEffect, useCallback} from 'react';
import {UnrealEngineNavigation, MetahumanNavigation} from '@wf-components/navigation-react';
import {
    NavSearchEvent,
    NavLinkClickEvent,
    NavCtaClickEvent,
    NavPropertyLogoClickEvent
} from '@wf-components/navigation-consumer';
import {generateRoutePath, getLocale} from '@epic-core/common';
import {useAccountStore} from 'epic-ue-shared';
import {useLocation, useNavigate} from 'react-router-dom';
import root from 'window-or-global';
import {isPathDisabled} from '../shared/navUtils';
import {createGlobalStyle} from 'styled-components';

interface Props {
    disableCtaOnMobilePaths?: string[];
    disablePaths?: string[];
}

export const NoNavigation = createGlobalStyle`
    div.subnav.fixed header {
        top: 0;
    }
`;

export const GlobalNavWrapper = ({
    disableCtaOnMobilePaths,
    disablePaths
}: Props): JSX.Element | null => {
    const {account} = useAccountStore();
    const location = useLocation();
    const pathname = location.pathname;
    const isMetahuman = pathname.includes('/metahuman');
    const locale = getLocale();
    const navigate = useNavigate();
    const clientConfig = root.clientEnvConfig || {};
    const serverUrl = clientConfig.EPIC_SERVER_URL;

    const [accountState, setAccountState] = useState('');

    useEffect(() => {
        if (window.location.href) {
            try {
                const url = new URL(window.location.href);
                if (url) {
                    url.searchParams.append('state', pathname);
                    url.searchParams.append('lang', locale.replace(/-/, '_'));
                    setAccountState(url.searchParams.toString());
                }
            } catch (e) {
                console.log('Failed to parse href', e);
            }
        }
    }, [pathname]);

    const navLinkOnClick = useCallback(
        (e: CustomEvent<NavLinkClickEvent | NavPropertyLogoClickEvent>) => {
            const link = e.detail?.link;
            if (link && link?.owner === 'mw-ue' && link.relativePath) {
                e.detail.originalEvent.preventDefault();
                navigate(generateRoutePath(link.relativePath));
            }
        },
        [navigate, generateRoutePath]
    );

    const navOnSearch = useCallback(
        (e: CustomEvent<NavSearchEvent>) => {
            if (e.detail.value) {
                navigate(generateRoutePath(`/search?keyword=${e.detail.value}`));
            }
        },
        [navigate, generateRoutePath]
    );

    const ctaOnClick = useCallback(
        (e: CustomEvent<NavCtaClickEvent>) => {
            if (e.detail && !isMetahuman) {
                e.detail.originalEvent?.preventDefault();
                const link = e.detail?.link;
                navigate(generateRoutePath(`${link?.relativePath || '/download'}?start=true`));
            }
        },
        [navigate, generateRoutePath, isMetahuman]
    );

    const disable = isPathDisabled(pathname, disablePaths);
    if (disable) return <NoNavigation />;

    const disableCtaOnMobile = isPathDisabled(pathname, disableCtaOnMobilePaths);

    const navProps = {
        domain: serverUrl,
        locale,
        isLoggedIn: account.isLoggedIn,
        displayName: account.displayName,
        propLogoOnClick: navLinkOnClick,
        navLinkOnClick,
        navOnSearch,
        ctaOnClick,
        signInLinkHref: `/login${accountState ? `?${accountState}` : ''}`,
        signOutLinkHref: `/logout${accountState ? `?${accountState}` : ''}`,
        disableCtaButtonOnMobile: disableCtaOnMobile,
        skipNavContentId: 'ue-app',
        sticky: 'true'
    };

    return (
        <>
            {isMetahuman ? (
                <MetahumanNavigation {...navProps} />
            ) : (
                <UnrealEngineNavigation {...navProps} />
            )}
        </>
    );
};
