import React from 'react';
import ArticleView from 'epic-ue-blog/app/scripts/views/ArticleView';
import {
    getBasicMetaTags,
    RouteMatchOrUrlParse,
    STARDUST_ARTICLE_TEMPLATE_NAME
} from 'epic-ue-shared';
import {feedApi, getAltLocPageDataCards, useFeedItem, useFeedStoreItem} from 'epic-ue-feed';
import {
    AppSharedKeys,
    InitSharedValueResponse,
    useCmsData,
    cmsApi,
    SPOTLIGHT_TEMPLATE_NAME
} from 'epic-ue-shared';
import ErrorLocPage from '../pages/ErrorLocPage';
import {PathMatch, useLocation, useParams, Params} from 'react-router-dom';
import {useLocalization, extractSlugLocale} from '@epic-mw/localization';
import {UnrealLoading} from 'epic-ue-loading';
import {getLocale} from '@epic-core/common';
import {getNoTrailingSlash} from '@epic-mw/common-utils';
import {SpotlightPage} from 'epic-ue-spotlight';
import {feedPreloadSharedValues} from './FeedDetailsPage';
import {getMessageFnType} from 'epic-ue-shared';
import ArticleDetailStardust from '../components/article/ArticleDetailStardust';
import {ClassicRouteChildren} from '../classic/ClassicRoute';
import ErrorPage from './ErrorPage';

const getSlug = (pathname: string, params: Params, props = {} as Properties) => {
    const blog = props.blog || {};
    const slug = blog._slug || params.title || params.typeSlug; // foo
    let pageSlug;
    let rootPageSlug;

    if (pathname) {
        const extracted = extractSlugLocale(pathname, getLocale()); // blog/foo
        pageSlug = getNoTrailingSlash(extracted.pageSlug);
        rootPageSlug = pageSlug.replace(`/${slug}`, ''); // blog
        return {slug, pageSlug, rootPageSlug};
    } else {
        rootPageSlug = props.rootPageSlug; // blog
        pageSlug = getNoTrailingSlash(`${rootPageSlug}/${slug}`); // blog/foo
    }

    return {slug, pageSlug, rootPageSlug};
};

interface Properties {
    rootPageSlug?: string;
    blog?: any;
    linkNavigatorRootPageSlug?: boolean;
    match?: PathMatch | null;
}

const BlogPage = (props: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const params = useParams();
    const getMessage = useLocalization();
    const blog = props && props.blog;
    const {slug, pageSlug, rootPageSlug} = getSlug(pathname, params, props);

    const {
        feedItem,
        cmsLoading: feedItemLoading,
        cmsLoadedEmpty: feedItemLoadedEmpty
    } = useFeedStoreItem(!blog ? pageSlug : '');

    const pageData = blog || feedItem;

    const isSpotlight = pageData && pageData._templateName === SPOTLIGHT_TEMPLATE_NAME;
    const isStardust = pageData && pageData._templateName === STARDUST_ARTICLE_TEMPLATE_NAME;
    const {pageData: rootPageData} = useCmsData<any>(isSpotlight ? '' : rootPageSlug);

    const {item} = useFeedItem(pageSlug);
    const tags = item?.tags || [];

    // if alternate locale page data exists, render alternate 404 page
    if (pageData?._hasAltLocPageData) {
        const altLocPageCards = getAltLocPageDataCards(
            pageData,
            pageSlug,
            getMessage as getMessageFnType
        );
        return <ErrorLocPage altLocPageCards={altLocPageCards} />;
    }

    if (feedItemLoading) {
        return <UnrealLoading />;
    }

    if (feedItemLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (pageData && isStardust) {
        return (
            <ArticleDetailStardust pageData={pageData} locale={getLocale()} feedItemTags={tags} />
        );
    }

    if (pageData && isSpotlight) {
        return (
            <ClassicRouteChildren>
                <SpotlightPage
                    pageData={pageData}
                    parentOrigin={'feed'}
                    cmsLoading={feedItemLoading}
                    urlPattern={pageSlug}
                    feedItemTags={tags}
                />
            </ClassicRouteChildren>
        );
    }

    const safeRootPageData = rootPageData || {};
    const config = safeRootPageData.config || {};
    const metaTags = getBasicMetaTags(getMessage as getMessageFnType);

    return (
        <ClassicRouteChildren>
            <div className="blog-wrapper">
                <ArticleView
                    {...props}
                    blog={pageData}
                    slug={slug}
                    overrideMetaTags={metaTags}
                    config={config}
                    cmsLoading={feedItemLoading}
                    feedItemTags={tags}
                />
            </div>
        </ClassicRouteChildren>
    );
};

BlogPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const pathMatch = match as PathMatch;
    const {pageSlug, rootPageSlug} = getSlug(pathMatch.pathname, pathMatch.params);

    const responses = await Promise.all([
        cmsApi.getPageData(rootPageSlug),
        cmsApi.getPageData(pageSlug)
    ]);
    delete responses[1].pageSlugParam;

    const valid = !!(pageSlug && responses[1] && Object.keys(responses[1]).length !== 0);
    let altLocPageData;
    if (!valid) {
        altLocPageData = await cmsApi.getAltLocPageData(pageSlug);
    }

    const preloadArray = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [rootPageSlug]: responses[0] || {}
            }
        },
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [pageSlug]: altLocPageData || responses[1] || {}
            }
        }
    ];

    return feedPreloadSharedValues(pageSlug, preloadArray);
};

export default BlogPage;
