import root from 'window-or-global';
import {PathMatch} from 'react-router-dom';
import {getLocale} from '@epic-core/common';
import {getNoTrailingSlash} from '@epic-mw/common-utils';
import {extractSlugLocale} from '@epic-mw/localization';
import parse from 'url-parse';
import {RouteMatchOrUrlParse} from '../types/route.types';

export const getOrigin = (): string => {
    if (process.env.NODE_SERVER_PORT && process.env.APP_CONTEXT !== undefined) {
        return `http://localhost:${process.env.NODE_SERVER_PORT}${process.env.APP_CONTEXT}`;
    }

    if (!root || !root.location) return '';

    let appContext = '';
    if (root.appContext && root.appContext !== '/') {
        appContext = root.appContext;
    }

    const location = root.location || {};

    if (!location.origin) {
        const port = location.port ? `:${location.port}` : '';
        root.location.origin = `${location.protocol}//${location.hostname}${port}`;
    }
    const origin = root.location.origin + appContext;
    return origin;
};

export const getPathnameFromRouteMatch = (match: RouteMatchOrUrlParse): string => {
    const rrMatch = match as PathMatch;
    const url = match as parse<unknown>;
    const pathname = rrMatch.pathname || url.pathname;
    return pathname;
};

export const getUrlPattern = (match: RouteMatchOrUrlParse, cmsUrlPattern?: string): string => {
    const pathname = getPathnameFromRouteMatch(match);
    const {pageSlug} = extractSlugLocale(pathname, getLocale());
    const urlPattern = cmsUrlPattern || pageSlug;
    const urlPatternNoTrail = urlPattern.length > 1 ? getNoTrailingSlash(urlPattern) : urlPattern;

    return urlPatternNoTrail;
};

const epicGamesOriginRegex =
    /^(www|www2|localhost|rossi|)?\.?(unrealengine|epicgames|twinmotion)(\.com|-(ci|gamedev)\.ol\.epicgames\.net)\/?/g;

export function determineExternal(href = ''): {
    isExternal: boolean;
    sameOrigin: boolean;
    isMailTo?: boolean;
    isLauncher?: boolean;
} {
    if (!href) return {isExternal: false, sameOrigin: true};
    const parsedUrl = parse(href);
    const matches = parsedUrl.hostname.match(epicGamesOriginRegex);
    const sameOrigin = (matches && matches.length && matches.length > 0) || false;
    const isExternal = href.indexOf('http') === 0;
    const isMailTo = href.indexOf('mailto:') === 0;
    const isLauncher = href.indexOf('com.epicgames.launcher:') === 0;
    return {isExternal, sameOrigin, isMailTo, isLauncher};
}
