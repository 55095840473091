import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import ParagonCard from './ParagonCard';
import {ParagonCardsWrapper} from './ParagonCards.styles';

export default class ParagonCards extends React.PureComponent {
    static propTypes = {
        image: PropTypes.string,
        title: PropTypes.string,
        desc: PropTypes.string,
        tweetDesc: PropTypes.string,
        cards: PropTypes.array,
        sectionName: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            inViewport: false
        };
    }

    cards = [];

    onIsVisible = (isVisible) => {
        this.setState({
            inViewport: true,
            animFired: true
        });
    };

    render() {
        const {image, title, desc, cards, sectionName, tweetDesc} = this.props;

        if (!image && !title && !desc && !cards) return null;

        const style = {};
        if (image) {
            style.backgroundImage = `url(${image})`;
        }

        const inVp = this.state.inViewport;

        return (
            <VisibilitySensor
                onChange={this.onIsVisible}
                partialVisibility
                offset={{top: 200}}
                scrollCheck={true}
                intervalDelay={250}
                scrollThrottle={10}>
                <ParagonCardsWrapper
                    className={`paragon-section ${inVp ? 'inViewport' : ''} section-${sectionName}`}
                    style={style}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="content">
                                    <div className="h2">{title}</div>
                                    <div
                                        className="desc"
                                        dangerouslySetInnerHTML={{__html: desc}}
                                    />
                                    <div className="paragon-card-item-container">
                                        {cards !== undefined && cards.length > 0
                                            ? cards.map((item, index) => {
                                                  const i = index;
                                                  const cardImage = item.image;
                                                  const imageArray = [cardImage];
                                                  return (
                                                      <div
                                                          className="paragon-card-item"
                                                          key={`item${i}`}
                                                          ref={(c) => {
                                                              this.cards[i] = c;
                                                          }}>
                                                          <ParagonCard
                                                              layers={imageArray}
                                                              className="paragon-card"
                                                              {...item}
                                                          />
                                                      </div>
                                                  );
                                              })
                                            : ''}
                                    </div>
                                    <div
                                        className="desc"
                                        dangerouslySetInnerHTML={{__html: tweetDesc}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ParagonCardsWrapper>
            </VisibilitySensor>
        );
    }
}
